import React from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import { AccordionDetails, AccordionSummary, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Strings } from "../../resources/i18n/i18n";
import addSubType from "../../resources/images/ic_add_subtype_bold.svg";
import { NumericFormat } from "react-number-format";
const renderNumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type='text'
            decimalScale={"0"}
            isNumericString
            allowNegative={false}
            isAllowed={props.isAllowed}
            disabled={props.disabled}
        />
    );
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        borderRight: "1px solid #bbb",  
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderRight: "1px solid #bbb",  
    },
    width: "auto", 
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    width: "auto",
}));
const LoanStatusAndGradeConfig = (props) => {
    const levelConfig = props.configurationData.configurationObject.configuration;
    const emptyLevelConfigurationData = props.emptyLevelConfigurationData;
    const noAscendingOrderDays = props.noAscendingOrderDays;
    const repeatedTerms = props.repeatedTerms;
    const repeatedDaysPastDueErrors = props.repeatedDaysPastDueErrors;
    const isRowWithRepeatedDaysPastDue = (frequencyIndex, termIndex, gradeIndex) => {
        let repeatedDaysPastDue = repeatedDaysPastDueErrors;
        for (let error of repeatedDaysPastDue) {
            if (error.frequencyIndex === frequencyIndex) {
                if (Array.isArray(error.repeatedDaysPastDueIndices)) {
                    for (let indicesArray of error.repeatedDaysPastDueIndices) {
                        if (error.termIndex === termIndex && indicesArray.includes(gradeIndex)) {
                            return "error-data";
                        }
                    }
                }
            }
        }
        return "";
    };

    const isRowWithNoAscendingOrderGrades = (frequencyIndex, termIndex) => {
        let errorRow = noAscendingOrderDays;
        for (let element of errorRow) {
            if (element.frequencyIndex === frequencyIndex && element.termIndex === termIndex) {
                return "error-data ";
            }
        }
        return "";
    };


    const isRowWithRepeatedTerms = (frequencyIndex, termIndex,) => {
        const frequencyData = repeatedTerms.find(item => item.frequencyIndex === frequencyIndex);
        if (frequencyData && frequencyData.repeatedTermIndices) {
            for (let repeatedTermArray of frequencyData.repeatedTermIndices) {
                if (repeatedTermArray.includes(termIndex)) {
                    return "error-data";
                }
            }
        }
        return undefined;
    };

    const isFieldWithError = (frequencyIndex, termIndex, gradeIndex, fieldType) => {
        let className = "form-control w-100 textFieldHeight";
        let errorFieldList = emptyLevelConfigurationData;
        for (let element of errorFieldList) {
            if (fieldType === "term") {
                if (
                    element.frequencyIndex === frequencyIndex &&
                    element.termIndex === termIndex &&
                    !element.gradeIndex &&
                    element.field === fieldType
                ) {
                    className += " error-data";
                }
            } else if (fieldType === "daysPastDue") {
                if (
                    element.frequencyIndex === frequencyIndex &&
                    element.termIndex === termIndex &&
                    element.gradeIndex === gradeIndex &&
                    element.field === fieldType
                ) {
                    className += " error-data";
                }
            }
        }

        return className;
    };


    const onPastDueChange = (frequencyIndex, termIndex, grade, event) => {
        let frequencyConfig = levelConfig[frequencyIndex];
        let termConfig = frequencyConfig.termsConfig[termIndex];
        if (event.target.name === "daysPastDue") {
            let gradeConfig = termConfig.gradeConfig.find(g => g.grade === grade);
            gradeConfig.daysPastDue = event.target.value;
        } else {
            termConfig.term = event.target.value;
        }
        props.hanldeLoanStatusAndGradeConfigChange(levelConfig);
        props.isAllLevelConfigurationDataFilled({ levelConfig }, "loanStatusAndGradeConfig")
        props.areDaysPastDueInAscendingOrder(levelConfig)
        props.areTermsUniqueForFrequency(levelConfig);
        props.checkRepeatedDaysPastDue(levelConfig);
    };

    const deleteRow = (frequencyIndex, termIndex) => {
        const updatedLevelConfig = [...levelConfig];
        const updatedFrequencyData = updatedLevelConfig.map((frequency, idx) => {
            if (idx === frequencyIndex) {
                const updatedTermsConfig = [...frequency.termsConfig];
                updatedTermsConfig.splice(termIndex, 1);
                return {
                    ...frequency,
                    termsConfig: updatedTermsConfig
                };
            }
            return frequency;
        });
        props.hanldeLoanStatusAndGradeConfigChange(updatedFrequencyData);
    };
    const handleAddRowForLoanStatusConfiguration = (frequencyToAddRow) => {
        const updatedLevelConfig = [...levelConfig];
        const updatedFrequencyData = updatedLevelConfig.map((frequency) => {
            if (frequency.frequency === frequencyToAddRow) {
                const newTerm = {
                    term: "",
                    gradeConfig: [
                        { grade: "A", daysPastDue: "" },
                        { grade: "B", daysPastDue: "" },
                        { grade: "C", daysPastDue: "" },
                        { grade: "D", daysPastDue: "" }
                    ]
                };
                const updatedTermsConfig = [...frequency.termsConfig];
                updatedTermsConfig.splice(updatedTermsConfig.length - 1, 0, newTerm);
                return {
                    ...frequency,
                    termsConfig: updatedTermsConfig
                };
            }
            return frequency;
        });
        props.hanldeLoanStatusAndGradeConfigChange(updatedFrequencyData);
        props.isAllLevelConfigurationDataFilled({ levelConfig }, "loanStatusAndGradeConfig")

    };

    return (
        <div className="emiConfiguration ps-3 pe-3">
            {levelConfig.map((frequency, frequencyIndex) => (
                <Row key={frequency.frequency}>
                    <Col md={12} className="pb-3">
                        <Accordion defaultExpanded={frequencyIndex === 0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="emiAccordianSummary"
                            >
                                <Typography component={"span"}>{Strings(frequency.frequency)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Row className="noPadding" key={frequencyIndex}>
                                        <Col md={12} className="noPadding d-flex justify-content-center">
                                            <div style={{ maxWidth: '50%', width: '100%' }}> {/* Limit width */}
                                                <TableContainer component={Paper} className="tableWidth"
                                                sx={{border: "1px solid #ccc"}}
                                                >
                                                    <Table sx={{ width: '100%', tableLayout: 'fixed' }} aria-label="customized table">
                                                        <TableHead >
                                                            <TableRow>
                                                                <StyledTableCell
                                                                    className="text-center"

                                                                >
                                                                </StyledTableCell>
                                                                {frequency.statusAndGradeMapping.map((col, idx) => {
                                                                    const colSpan = col.grades.length > 1 ? 2 : 1;
                                                                    return (
                                                                        <StyledTableCell
                                                                            className="text-center"
                                                                            colSpan={colSpan}
                                                                            key={idx}
                                                                        >
                                                                            {col.status === "ACTIVE" ? Strings("Active") : Strings(col.status)}
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                       {!props.readOnly&&
                                                                <StyledTableCell
                                                                    className="text-center"
                                                                   
                                                                >
                                                                </StyledTableCell>}
                                                            </TableRow>
                                                            <TableRow>
                                                                <StyledTableCell
                                                                    className="text-center"
                                                                    sx={{ borderBottom: "1px solid #ccc" }}
                                                                >
                                                                    {Strings("term")}
                                                                </StyledTableCell>
                                                                {frequency.statusAndGradeMapping.map((col, idx) => (
                                                                    <React.Fragment key={idx}>
                                                                        {col.grades.length > 1 ? (
                                                                            col.grades.map((grade, gradeIdx) => (
                                                                                <StyledTableCell

                                                                                    className="text-center"
                                                                                    sx={{ borderBottom: "1px solid #ccc" }}
                                                                                    key={gradeIdx}
                                                                                >
                                                                                    {grade}
                                                                                </StyledTableCell>
                                                                            ))
                                                                        ) : (
                                                                            <StyledTableCell
                                                                                className="text-center"
                                                                                sx={{ borderBottom: "1px solid #ccc" }}
                                                                            >
                                                                                {col.grades[0]}
                                                                            </StyledTableCell>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                                
                                                                {!props.readOnly&&
                                                                <StyledTableCell
                                                                    className="text-center"
                                                                >
                                                                    {Strings("Action")}
                                                                </StyledTableCell>}
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {frequency.termsConfig.map((termConfig, termsIndex) => (
                                                                <StyledTableRow key={termsIndex}
                                                                    className={isRowWithNoAscendingOrderGrades(frequencyIndex, termsIndex)}>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="text-center"
                                                                        sx={{ borderBottom: "1px solid #ccc" }}
                                                                    >
                                                                        <div
                                                                            className="textBox input"
                                                                        >
                                                                            {
                                                                                termConfig.term === -1 ? (
                                                                                    Strings("Other")
                                                                                ) : props.readOnly ? (
                                                                                    termConfig.term
                                                                                ) : (
                                                                                    <TextField
                                                                                        value={termConfig.term}
                                                                                        className={`${isRowWithRepeatedTerms(frequencyIndex, termsIndex) || ''} ${isFieldWithError(frequencyIndex, termsIndex, null, "term") || ''}`}
                                                                                        onChange={(event) => onPastDueChange(frequencyIndex, termsIndex, null, event)}
                                                                                        thousandSeparator={true}
                                                                                        isNumericString
                                                                                        name="term"
                                                                                        id="formatted-numberformat-input"
                                                                                        InputProps={{
                                                                                            inputComponent: renderNumberFormatCustom,
                                                                                            style: { textAlign: "center" },
                                                                                        }}
                                                                                        variant="standard"
                                                                                    />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </StyledTableCell>

                                                                    {frequency.statusAndGradeMapping.map((col, colIdx) => {
                                                                        return col.grades.map((grade, gradeIdx) => {
                                                                            const gradeConfig = termConfig.gradeConfig.find((g) => g.grade === grade);
                                                                            const gradeIndex = termConfig.gradeConfig.findIndex((g) => g.grade === grade);
                                                                            if (!gradeConfig) {
                                                                                return <StyledTableCell key={gradeIdx} className="text-center">Error: Grade not found</StyledTableCell>;
                                                                            }
                                                                            return (
                                                                                <StyledTableCell
                                                                                    key={gradeIdx}
                                                                                    className="textBox input text-center"
                                                                                    sx={{ borderBottom: "1px solid #ccc" }}
                                                                                >
                                                                                    {props.readOnly ? (
                                                                                        gradeConfig?.daysPastDue
                                                                                    ) : (
                                                                                        <div className="d-flex">
                                                                                            <TextField
                                                                                                className={`${isFieldWithError(frequencyIndex, termsIndex, gradeIndex, "daysPastDue") || ''} ${isRowWithRepeatedDaysPastDue(frequencyIndex, termsIndex, gradeIndex) || ''}`}
                                                                                                value={gradeConfig?.daysPastDue}
                                                                                                onChange={(event) => onPastDueChange(frequencyIndex, termsIndex, grade, event)}
                                                                                                thousandSeparator={true}
                                                                                                isNumericString
                                                                                                name="daysPastDue"
                                                                                                id="formatted-numberformat-input"
                                                                                                InputProps={{
                                                                                                    inputComponent: renderNumberFormatCustom,
                                                                                                    className: "text-center"
                                                                                                }}
                                                                                                variant="standard"
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </StyledTableCell>
                                                                            );
                                                                        });
                                                                    })}
                                                                    {!props.readOnly&&
                                                                    <StyledTableCell
                                                                        className="text-center "
                                                                        isLastColumn={true}
                                                                    >
                                                                        {!props.readOnly && termConfig.term !== -1 && (
                                                                            <div
                                                                           className="cursor-pointer d-flex align-items-center justify-center"
                                                                                onClick={() => { deleteRow(frequencyIndex, termsIndex) }}
                                                                            >
                                                                                <i
                                                                                    className="fa fa-trash"
                                                                                    aria-hidden="true"
                                                                                    title="Eliminar"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </StyledTableCell>}
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {!props.readOnly && (
                                                    <Row>
                                                        <Col md={12} className='actionButtonPlaceHolder mt-2 ps-0 '>
                                                            <span
                                                                className="cursorPointer"
                                                                onClick={() => { handleAddRowForLoanStatusConfiguration(frequency.frequency) }}
                                                            >
                                                                <i>
                                                                    <img
                                                                        className='actionIcon'
                                                                        src={addSubType}
                                                                        alt='add record'
                                                                    />
                                                                </i>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                </Row>
            ))}
        </div>
    );
}
export default LoanStatusAndGradeConfig;