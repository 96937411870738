import { OPERATION_STATUS } from "../../config/axios.init";
import { callAPI } from "../../config/AxiosTemplate"
import { API } from "../../constants/APIConstants"
export const getNoteTypesAction = (requestData,callback) => {
    
    callAPI(
        "GetNoteTypes",
        API.getNoteTypes.concat(requestData),
        "GET",
        {},
        (apiResponse) => {
            if(apiResponse.status === OPERATION_STATUS.SUCCESS) {
                callback(apiResponse);
            }
        }
    );
};
export const getUserRolesAction =
  (isAddUser, callback) => {
    let requestString = "/roles";
    if (isAddUser !== undefined && isAddUser === true) {
      requestString = requestString + "?type=1";
    }
    callAPI(
      "GetUserRoles",
      API.getUserRoles.replace("/roles", requestString),
      "GET",
      {},
      (apiResponse) => {
        if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
          callback(apiResponse);
        }
      }
    );
  };
export const getNotesAction = (requestData,callback) => {
    callAPI(
        "GetNotes",
        API.getNotesContext,
        "POST",
        requestData,
        (apiResponse) => {
            if(apiResponse.status === OPERATION_STATUS.SUCCESS) {
                callback(apiResponse);
            }
        }
    )
}