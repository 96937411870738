import { callAPI } from "../../config/AxiosTemplate";
import { API } from "../../constants/APIConstants";

export const getLoanApplicationDetailsByIdAction = (
  requestData,
  callback
) => {
  callAPI(
    "getLoanApplicationDetails",
    API.getLoanApplicationDetails.concat(requestData),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
export const getNotes = (
  request,
  callback
) => {
  callAPI(
    "getNotes",
    API.getNotes,
    "POST",
    request,
    (apiResponse) => {
      callback(apiResponse);
    }
  );
}
export const viewDocumentAction = (requestData, callback) => {
  let apiStr =
    requestData.documentId +
    "&temporaryLoan=" +
    requestData.temporaryLoan;
  callAPI(
    "viewDocumentAction",
    API.viewDocument.concat(apiStr),
    "GET",
    {},
    (apiResponse) => {
      callback(apiResponse);
    }
  );
};
