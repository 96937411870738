import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import "moment/locale/es"; // without this line locale didn't work
import { withRouter } from "react-router-dom";

// Components
import ConfigurationList from "./ConfigurationList";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";

// Actions
import { getConfigurationBySearchFilterAction } from "./ConfigurationListAction";
import { setConfigurationDataForEdit } from "../CustomConfiguration/CustomConfigurationAction";
import { setSearchFilterAction } from "../../constants/Action";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import { GET_CONFIGURATION_TABLE_COLUMNS } from "./ConfigurationListConstants";
import {
  SEARCH_FILTER_PAGES,
  CONFIGURATION_FILTER,
  INVOICE_DATE_FORMAT,
  USER_ROLES,
  CONFIGURATION_TYPE,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./ConfigurationList.scss";

moment.locale("es");

class ConfigurationListContainer extends React.Component {
  /* #region  LifeCycle Methods */
  constructor(props) {
    super(props);
    //Columns are created with specific names and key
    this._columns = GET_CONFIGURATION_TABLE_COLUMNS();
    //Initially no rows are presents
    this.state = {
      rows: [],
      isObsolete:"",
      numberOfPages: 0,
      errorMessage: "",
      type: props.type,
      version: "",
      state: "",
      showLoadingModal: false,
      limit: 0,
      offset: 0,
      searchFilters: {
        feature: "",
        version: "",
        status: "",
      },
    };
    this.isRowClick = false;
  }
  componentDidMount() {
    if (
      this.props.loggedInUserInfo.role !== USER_ROLES.configurator &&
      this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
    ) {
      this.props.setConfigurationDataForEdit({
        type: this.state.type,
      });
      this.props.history.push(ROUTES.CONFIGURATION_DETAILS);
    }
  }

  componentWillUnmount() {
    if (!this.isRowClick) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CONFIGURATION,
        {
          ...CONFIGURATION_FILTER,
        }
      );
    }
  }
  /* #endregion */

  /* #region  Events */
  handleOnClickSearchFilter = (searchFilterObject) => {
    this.setState({
      showLoadingModal: true,
      searchFilters: {
        feature: searchFilterObject.feature,
        version: searchFilterObject.version,
        status: searchFilterObject.status,
        limit: searchFilterObject.limit,
        offset: searchFilterObject.offset,
      },
    });
    let request = {
      type: this.state.type,
      version: searchFilterObject.version
        ? searchFilterObject.version
        : undefined,
      status: searchFilterObject.status
        ? searchFilterObject.status
        : undefined,
      limit: searchFilterObject.limit,
      offset: searchFilterObject.offset,
    };
    getConfigurationBySearchFilterAction(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            errorMessage: "",
            showLoadingModal: false,
            numberOfPages: response.data.data.numberOfPages,
            isObsolete: response.data.data.isObsolete,
          },
          () => {
            this.createRows(response.data);
          }
        );
      } else {
        this.setState({
          showLoadingModal: false,
          errorMessage: response.error.message,
          rows: [],
        });
      }
    });
  };

  handleOnChangeVersion = (version) => {
    this.setState({
      version: version,
    });
  };

  handleOnChangeFilterState = (state) => {
    this.setState({
      state: state,
    });
  };
  /* #endregion */

  /* #region  Methods */
  initErrorStates = () => {
    this.setState({ errorMessage: "" });
  };

  createRows = (responseData) => {
    let rows = [],
      configurationData = responseData.data.configurations;
    _.forEach(configurationData, (configurationDetails) => {
      rows.push({
        version: configurationDetails.version,
        description: configurationDetails.description,
        state: Strings(configurationDetails.state),
        user: configurationDetails.user,
        lastModified: moment(
          configurationDetails.lastModified
        ).format(INVOICE_DATE_FORMAT),
        configurationPreserv: configurationDetails,
      });
    });
    this.setState({ rows: rows });
  };

  callbackOnRowClick = (rowData) => {
    if (rowData) {
      this.isRowClick = true;
      let editConfigurationData = {
        configuration: rowData.configurationPreserv,
        type: this.state.type,
      };
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.CONFIGURATION,
        this.state.searchFilters,
        SEARCH_FILTER_PAGES.CONFIGURATION
      );
      this.props.setConfigurationDataForEdit(editConfigurationData);
      this.props.history.push(ROUTES.CONFIGURATION_DETAILS);
    }
  };
  /* #endregion */

  render() {
    return (
      <div className='ConfigurationList'>
        <LoadingModal
          showLoadingModal={this.state.showLoadingModal}
        />
        <ConfigurationList
          columns={this._columns}
          rows={this.state.rows}
          rowsCount={this.state.rows.length}
          onRowClick={this.callbackOnRowClick}
          rowHeight={60}
          headerRowHeight={40}
          handleOnChangeVersion={this.handleOnChangeVersion}
          handleOnChangeFilterState={this.handleOnChangeFilterState}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          numberOfPages={this.state.numberOfPages}
          errorMessage={this.state.errorMessage}
          type={this.state.type}
          searchFilters={this.props.searchFilters}
          loggedInUserInfo={this.props.loggedInUserInfo}
          isObsolete={this.state.isObsolete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchFilters: state.searchFilterReducer.configurationSearchFilter,
  selectedFeature: state.sidebarReducer.selectedFeature,
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setConfigurationDataForEdit, setSearchFilterAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfigurationListContainer));
