import React from "react";
import moment from "moment";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";

import { APP_LANGUAGES } from "../../../constants/appConstants";

import "./Calender.scss";

class Calender extends React.Component {
  state = {
    open: false,
    today: new moment(),
    yesterday: new moment().subtract(1, "day"),
  };
  renderInput = (calenderProps, openCalendar, closeCalendar) => {
    let iconClick = () => {
      this.setState({ open: !this.refc.state.open }, () =>
        this.state.open ? openCalendar() : closeCalendar()
      );
    };
    return (
      <TextFieldComponent
        {...calenderProps}
        placeholder={this.props.placeholder}
        placeholderTop={this.props.placeholderTop}
        placeholderTopClass={this.props.placeholderTopClass}
        onIconClick={
          !this.props.disabled
            ? iconClick
            : () => {
              //This is intensional
            }
        }
        disabled={this.props.disabled}
        readOnly={this.props.dateFormat === false ? false : true}
        showIcon
        iconClassName={
          this.props.dateFormat
            ? "fa fa-calendar fa-lg" + this.props.iconClass
            : "fa fa-clock-o fa-lg"
        }
        required={this.props.required}
        errorClass={this.props.errorClass}
      />
    );
  };

  //Disable future date or past date
  valid = (selectedDate) => {
    selectedDate = new moment(selectedDate);
    if (selectedDate) {
      if (this.props.isBefore) {
        if (this.props.disableTodaysDate) {
          return !selectedDate.isBefore(this.state.today);
        } else {
          return !selectedDate.isBefore(this.state.yesterday);
        }

      }else if (this.props.isValidBirthDate) {
        const today = new moment(); 
        const minDate = today.clone().subtract(65, 'years'); 
        const maxDate = today.clone().subtract(21, 'years'); 
        // Check if selected date is between minDate and maxDate
        if (selectedDate.isBefore(minDate) || selectedDate.isAfter(maxDate)) {
          return false;
        }
        return true;
      }
      else return !selectedDate.isAfter(this.state.today);
    } else {
      return false
    };
  };

  render() {
    return (
      <div className='calender'>
        <Datetime
          ref={(ref) => (this.refc = ref)}
          renderInput={this.renderInput}
          dateFormat={this.props.dateFormat || false}
          timeFormat={this.props.timeFormat || false}
          value={this.props.value}
          closeOnSelect={true}
          locale={APP_LANGUAGES.SPANISH}
          onChange={this.props.handleChange}
          isValidDate={this.valid}
          viewMode={this.props.dateFormat === false ? "time" : "days"}
          input={true}
          className={this.props.className}
        />
      </div>
    );
  }
}

export default Calender;
