import React from "react";
import { Strings } from "../../resources/i18n/i18n";
import moment from "moment";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

//Constants
import {
  INVOICE_DATE_FORMAT,
  MINIMART_RATING,
} from "../../constants/appConstants";

const renderDate = ({ value }) => {
  return (
    <span
      title={moment(value).format(INVOICE_DATE_FORMAT)}
      style={{ textAlign: "center" }}
    >
      {moment(value).format(INVOICE_DATE_FORMAT)}
    </span>
  );
};

const renderUserRating = ({ value }) => {
  return value !== "-" ? (
    <div>
      <Rater
        id={"SALES_PERSON"}
        title={value}
        rating={Number(value)}
        total={5}
        interactive={false}
        style={{
          fontSize: 15 + "px",
          color: "#3ea853",
        }}
      />
    </div>
  ) : (
    "-"
  );
};

const LOAN_PORTFOLIO_TAB = {
  key: 0,
  label: Strings("LoanPortFolio"),
  value: "LOAN_PORTFOLIO",
};
const SALES_TAB = {
  key: 1,
  label: Strings("Sales"),
  value: "SALES",
};

const SPECIAL_SALES_TAB = {
  key: 2,
  label: Strings("SpecialSales"),
  value: "SPECIAL_SALES",
};

const COLLECTIONS_TAB = {
  key: 3,
  label: Strings("collections"),
  value: "COLLECTIONS",
};

const WEB_COLLECTIONS_TAB = {
  key: 4,
  label: Strings("webCollections"),
  value: "WEB_COLLECTIONS",
}

const WALLET_TAB = {
  key: 5,
  label: Strings("wallet"),
  value: "WALLET",
};

export const REPORTS_TABS = {
  GENERAL_MANAGER: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
    WALLET_TAB,
  ],
  ADMIN: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
    WALLET_TAB,
  ],

  OPERATIONS_MODERATOR: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
    WALLET_TAB,
  ],

  SALES_MODERATOR: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
  MESSENGER_MODERATOR: [
    {
      key: 0,
      label: Strings("collections"),
      value: "COLLECTIONS",
    },
    {
      key: 1,
      label: Strings("webCollections"),
      value: "WEB_COLLECTIONS",
    },
  ],
  LOAN_PORTFOLIO_MANAGER: [
    LOAN_PORTFOLIO_TAB
  ],
  ACCOUNTING_OFFICER: [
    LOAN_PORTFOLIO_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
  COLLECTIONS_OFFICER: [
    LOAN_PORTFOLIO_TAB
  ],
  AUDITOR: [
    LOAN_PORTFOLIO_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
  COLLECTIONS_MANAGER: [
    LOAN_PORTFOLIO_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
  COLLECTIONS_SUPERVISOR: [
    LOAN_PORTFOLIO_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
  OPERATIONS_MANAGER: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
    WALLET_TAB,
  ],
  SALES_MANAGER: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
  ],
  ADMINISTRATIVE_MANAGER: [
    LOAN_PORTFOLIO_TAB,
    SALES_TAB,
    SPECIAL_SALES_TAB,
    COLLECTIONS_TAB,
    WEB_COLLECTIONS_TAB,
  ],
};

export const FIRST_LEVEL_TABLE_COLUMNS = {
  LOAN_PORTFOLIO: [
    {
      key: "mn",
      name: Strings("minimartName"),
      minWidth: 100,
    },
    {
      key: "ic",
      name: Strings("InvoiceCount"),
      minWidth: 100,
    },
    {
      key: "lc",
      name: Strings("loanCount"),
      minWidth: 100,
    },
  ],
  SALES: [
    {
      key: "ts",
      name: Strings("Date"),
      formatter: renderDate,
      minWidth: 120,
    },
    {
      key: "nm",
      name: Strings("SALES_PERSON"),
      minWidth: 200,
    },
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 100,
    },
    {
      key: "lc",
      name:
        Strings("loansGenerated") +
        " / " +
        Strings("assignedInvoices"),
      minWidth: 100,
    },
    {
      key: "lAmt",
      name:
        Strings("loanAmount") + " ($) / " + Strings("InvoiceAmount"),
      minWidth: 100,
    },
    {
      key: "mRat",
      name: Strings("minimartUserRating"),
      formatter: ({ value }) => {
        return value !== "-" ? (
          <div
            style={{ textAlign: "center" }}
            title={MINIMART_RATING[value]}
          >
            {MINIMART_RATING[value]}
          </div>
        ) : (
          "-"
        );
      },
      minWidth: 100,
    },
    {
      key: "pRat",
      name: Strings("userRating"),
      formatter: renderUserRating,
      minWidth: 100,
    },
  ],

  SPECIAL_SALES: [
    {
      key: "ts",
      name: Strings("Date"),
      formatter: renderDate,
      minWidth: 120,
    },
    {
      key: "nm",
      name: Strings("SALES_PERSON"),
      minWidth: 200,
    },
    {
      key: "lc",
      name: Strings("loansGenerated"),
      minWidth: 100,
    },
    {
      key: "lAmt",
      name: Strings("loanAmount"),
      minWidth: 100,
    },
  ],
  COLLECTIONS: [
    {
      key: "ts",
      name: Strings("Date"),
      formatter: renderDate,
      minWidth: 100,
    },
    {
      key: "nm",
      name: Strings("Messenger"),
      minWidth: 100,
    },
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 100,
    },
    {
      key: "amtC",
      name:
        Strings("collectedAmount") +
        " ($) / " +
        Strings("assignedAmount"),
      minWidth: 100,
    },
    {
      key: "amtD",
      name:
        Strings("depositedAmount") +
        " ($) / " +
        Strings("InTransitAmount") +
        " ($)",
      minWidth: 100,
    },
    {
      key: "pRat",
      name: Strings("userRating"),
      formatter: renderUserRating,
      minWidth: 100,
    },
  ],
  WEB_COLLECTIONS: [
    {
      key: "cd",
      name: Strings("Date"),
      formatter: renderDate,
      minWidth: 100,
    },
    {
      key: "ca",
      name: Strings("collectedAmount"),
      minWidth: 100,
    },
  ],
  WALLET: [
    {
      key: "mid",
      name: Strings("MatricesID"),
      minWidth: 100,
    },
    {
      key: "mc",
      name: Strings("minimartCIId"),
      minWidth: 100,
    },
    {
      key: "mn",
      name: Strings("minimartName"),
      minWidth: 100,
    },
    {
      key: "gr",
      name: Strings("MinimartGrade"),
      minWidth: 100,
    },
    {
      key: "da",
      name: Strings("totalDisbursementAmount"),
      minWidth: 100,
    },
    {
      key: "pdc",
      name: Strings("totalDueAmount"),
      minWidth: 100,
    },
    {
      key: "pda",
      name: Strings("totalPastDueAmount"),
      minWidth: 100,
    },
    {
      key: "pftc",
      name: Strings("profit"),
      minWidth: 100,
    },
  ],
};

export const FIRST_LEVEL_SUM_COLUMNS = {
  LOAN_PORTFOLIO: [
    {
      key: "mc",
      name: Strings("minimartCount"),
      minWidth: 100,
    },
    {
      key: "ic",
      name: Strings("InvoiceCount"),
      minWidth: 100,
    },
    {
      key: "lc",
      name: Strings("loanCount"),
      minWidth: 100,
    },
    {
      key: "ap",
      name: Strings("allTypesOfAmounts"),
      minWidth: 100,
    },
  ],
  SALES: [
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 100,
    },
    {
      key: "lc",
      name:
        Strings("loansGenerated") +
        " / " +
        Strings("assignedInvoices"),
      minWidth: 100,
    },
    {
      key: "lAmt",
      name:
        Strings("loanAmount") + " ($) / " + Strings("InvoiceAmount"),
      minWidth: 100,
    },
  ],
  SPECIAL_SALES: [
    {
      key: "lc",
      name: Strings("loansGenerated"),
      minWidth: 100,
    },
    {
      key: "lAmt",
      name: Strings("loanAmount"),
      minWidth: 100,
    },
  ],
  COLLECTIONS: [
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
      minWidth: 100,
    },
    {
      key: "amtC",
      name:
        Strings("collectedAmount") +
        " ($) / " +
        Strings("assignedAmount"),
      minWidth: 100,
    },
    {
      key: "amtD",
      name:
        Strings("depositedAmount") +
        " ($) /" +
        Strings("InTransitAmount") +
        " ($)",
      minWidth: 100,
    },
  ],
  WEB_COLLECTIONS: [
    {
      key: "amtC",
      name: Strings("collectedAmount"),
      minWidth: 100,
    },
  ],
  WALLET: [
    {
      key: "da",
      name: Strings("totalDisbursementAmount"),
      minWidth: 100,
    },
    {
      key: "pdc",
      name: Strings("totalDueAmount"),
      minWidth: 100,
    },
    {
      key: "pda",
      name: Strings("totalPastDueAmount"),
      minWidth: 100,
    },
    {
      key: "pftc",
      name: Strings("profit"),
      minWidth: 100,
    },
  ],
};

export const LIST_FIRST_REPORTS = {
  LOAN_PORTFOLIO: {
    url: "/loan-count",
    sumUrl: "/loan-count/summary",
    downloadUrl: "/loan-count/download?fileType=",
    keysArray: ["mn", "ic", "lc", "userPreservedData"],
    sumKeysArray: [
      "mc",
      "ic",
      "lc",
      ["ap", "at", "ad", { isAmount: true }],
    ],
  },
  SALES: {
    url: "/salesPersons",
    sumUrl: "/salesPersons/summary",
    downloadUrl: "/salesPersons/download?fileType=",
    keysArray: [
      "ts",
      "nm",
      ["vc", "mc", { isAmount: true }],
      ["lc", "ic", { isAmount: true }],
      ["lAmt", "iAmt", { isAmount: true }],
      "pRat",
      "mRat",
      "userPreservedData",
    ],
    sumKeysArray: [
      ["vc", "mc", { isAmount: true }],
      ["lc", "ic", { isAmount: true }],
      ["lAmt", "iAmt", { isAmount: true }],
    ],
  },
  SPECIAL_SALES: {
    url: "/specialSalesPersons",
    sumUrl: "/specialSalesPersons/summary",
    downloadUrl: "/specialSalesPersons/download?fileType=",
    keysArray: [
      "ts",
      ["nm", "ty", { isAmount: false }],
      ["lc", { isAmount: true }],
      ["lAmt", { isAmount: true }],
      "userPreservedData",
    ],
    sumKeysArray: [
      ["lc", { isAmount: true }],
      ["lAmt", { isAmount: true }],
    ],
  },
  COLLECTIONS: {
    url: "/messengers",
    sumUrl: "/messengers/summary",
    downloadUrl: "/messengers/download?fileType=",

    keysArray: [
      "ts",
      ["nm", "ty", { isAmount: false }],
      ["vc", "mc", { isAmount: true }],
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtD", "amtT", { isAmount: true }],
      "pRat",
      "userPreservedData",
    ],
    sumKeysArray: [
      ["vc", "mc", { isAmount: true }],
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtD", "amtT", { isAmount: true }],
    ],
  },
  WEB_COLLECTIONS: {
    url: "/web/collection",
    sumUrl: "/web/collection/summary",
    downloadUrl: "/web/collection/download?fileType=",

    keysArray: [
      "cd",
      ["ca", { isAmount: true }],
    ],
    sumKeysArray: [
      ["amtC", { isAmount: true }],
    ]
  },
  WALLET: {
    url: "/wallet-minimart",
    sumUrl: "/wallet-summary",
    downloadUrl: "/wallet-minimart/download?fileType=",
    keysArray: [
      "mid",
      "mc",
      "mn",
      "gr",
      ["da", { isAmount: true }],
      ["pdc", "pdi", "pdlf", { isAmount: true }],
      ["pda", { isAmount: true }],
      ["pftc", { isAmount: true }],
      "userPreservedData",
    ],
    sumKeysArray: [
      ["da", { isAmount: true }],
      ["pdc", "pdi", "pdlf", { isAmount: true }],
      ["pda", { isAmount: true }],
      ["pftc", { isAmount: true }],
    ],
  },
};

export const SECOND_LEVEL_TABLE_COLUMNS = {
  LOAN_PORTFOLIO: [
    {
      key: "mid",
      name: Strings("MatricesID"),
    },
    {
      key: "mc",
      name: Strings("minimartCIId"),
    },
    {
      key: "mn",
      name: Strings("minimartName"),
    },

    {
      key: "ts",
      name: Strings("DisbursementDate"),
    },
    {
      key: "ty",
      name: Strings("loanType"),
    },
    {
      key: "id",
      name: Strings("loanId"),
    },
    {
      key: "ap",
      name: Strings("allTypesOfAmounts"),
    },
    {
      key: "di",
      name: Strings("Installments"),
    },
    {
      key: "rt",
      name: Strings("rate"),
    },
    {
      key: "action",
      name: "action",
    },
  ],
  SALES: [
    {
      key: "ts",
      name: Strings("Date"),
    },
    {
      key: "mn",
      name: Strings("minimartName"),
    },
    {
      key: "in",
      name: Strings("InvoiceNumber"),
    },
    {
      key: "st",
      name: Strings("Status"),
    },
  ],
  SPECIAL_SALES: [
    {
      key: "ts",
      name: Strings("Date"),
    },
    {
      key: "mn",
      name: Strings("minimartName"),
    },
    {
      key: "lc",
      name: Strings("loansGenerated"),
    },
    {
      key: "lAmt",
      name: Strings("loanAmount"),
    },
  ],
  COLLECTIONS: [
    {
      key: "ts",
      name: Strings("Date"),
    },
    {
      key: "mid",
      name: Strings("MatricesID"),
    },
    {
      key: "mn",
      name: Strings("minimartName"),
    },
    {
      key: "cae",
      name: Strings("paidAdminExpense") + " ($)",
    },
    {
      key: "caec",
      name: Strings("collectedAdminExpenseInCash") + " ($)",
    },
    {
      key: "amtC",
      name:
        Strings("collectedAmount") +
        " ($) / " +
        Strings("assignedAmount"),
    },
    {
      key: "amtD",
      name:
        Strings("depositedAmount") +
        " ($) / " +
        Strings("InTransitAmount") +
        " ($)",
    },
    {
      key: "acic",
      name: Strings("amountCollectedInCash") + " ($)",
    },
    {
      key: "acid",
      name: Strings("amountCollectedInDeposit") + " ($)",
    },
    {
      key: "acit",
      name: Strings("amountCollectedInTransfer") + " ($)",
    },
  ],
  WEB_COLLECTIONS: [
    {
      key: "cd",
      name: Strings("Date"),
    },
    {
      key: "mid",
      name: Strings("MatricesID"),
    },
    {
      key: "cid",
      name: Strings("minimartCIId"),
    },
    {
      key: "mn",
      name: Strings("minimartName"),
    },
    {
      key: "ca",
      name: Strings("collectedAmount") + " ($)",
    },
    {
      key: "vd",
      name: Strings("visitDays"),
    },
    {
      key: "cb",
      name:
        Strings("collectedBy"),
    },
  ],
  WALLET: [
    {
      key: "ts",
      name: Strings("DisbursementDate"),
      minWidth: 150,
    },
    {
      key: "mid",
      name: Strings("MatricesID"),
      minWidth: 100,
    },
    {
      key: "appId",
      name: Strings("applicationId"),
      minWidth: 100,
    },
    {
      key: "id",
      name: Strings("loanId"),
      minWidth: 100,
    },
    {
      key: "da",
      name: Strings("totalDisbursementAmount"),
      minWidth: 150,
    },
    {
      key: "pdc",
      name: Strings("totalDueAmount"),
      minWidth: 150,
    },
    {
      key: "pdb",
      name: Strings("totalPastDueAmount"),
      minWidth: 150,
    },
    {
      key: "pftc",
      name: Strings("profit"),
      minWidth: 150,
    },
    {
      key: "arid",
      name: Strings("daysInArrears"),
      minWidth: 150,
    },
  ],
};

export const LOAN_PORTFOLIO_SECOND_ROW = [
  {
    key: "ae",
    name: Strings("paidAdminExpense"),
  },
  {
    key: "pro",
    name: Strings("Provision"),
  },
  {
    key: "st",
    name: Strings("Status"),
  },
  {
    key: "zn",
    name: Strings("Zone"),
  },
  {
    key: "mzn",
    name: Strings("mzone"),
  },
  {
    key: "sp",
    name: Strings("SALES_PERSON"),
  },
  {
    key: "ms",
    name: Strings("Messenger"),
  },
  {
    key: "co",
    name: Strings("CollectionsOfficer"),
  },
  {
    key: "sm",
    name: Strings("SALES_MODERATOR"),
  },
  {
    key: "emi",
    name: Strings("emi"),
  },
  {
    key: "aid",
    name: Strings("Arrears_in_Days"),
  },
  {
    key: "ed",
    name: Strings("Number_of_days_the_EMI_is_delayed"),
  },
];

export const SECOND_LEVEL_SUM_COLUMNS = {
  LOAN_PORTFOLIO: [
    {
      key: "lc",
      name: Strings("loanCount"),
    },
    {
      key: "ap",
      name: Strings("allTypesOfAmounts"),
    },
  ],

  SALES: [
    {
      key: "vc",
      name:
        Strings("visitedMinimart") +
        " / " +
        Strings("AssingedMinimart"),
    },
    {
      key: "lc",
      name:
        Strings("loansGenerated") +
        " / " +
        Strings("assignedInvoices"),
    },

    {
      key: "lAmt",
      name:
        Strings("loanAmount") + " ($) / " + Strings("InvoiceAmount"),
    },
  ],

  COLLECTIONS: [
    {
      key: "amtC",
      name:
        Strings("collectedAmount") +
        " ($) /" +
        Strings("assignedAmount"),
    },
    {
      key: "amtD",
      name:
        Strings("depositedAmount") +
        " ($) /" +
        Strings("InTransitAmount") +
        " ($)",
    },
  ],
};

export const LIST_SECOND_REPORTS = {
  LOAN_PORTFOLIO: {
    url: "/loan-list",
    sumUrl: "/loan-list/summary",
    downloadUrl: "/loan-list/download?fileType=",
    keysArray: [
      "id",
      "mid",
      "ty",
      "pro",
      "di",
      "appId",
      "rt",
      ["ap", "at", "ad", { isAmount: true }],
      "st",
      "ts",
      "mn",
      "mc",
      "zn",
      "mzn",
      "br",
      "sp",
      ["ms", "msty", { isAmount: false }],
      "co",
      "userPreservedData",
      "sm",
      "ae",
      "emi",
      "aid",
      "ed",
      "crli",
      "cli",
    ],
    sumKeysArray: ["lc", ["ap", "at", "ad", { isAmount: true }]],
  },
  SALES: {
    url: "/salesPerson",
    // sumUrl: "/salesPerson/summary",
    downloadUrl: "/salesPerson/download?fileType=",
    keysArray: [
      "un",
      "ts",
      "mn",
      "in",
      "appId",
      "st",
      "userPreservedData",
    ],
  },
  SPECIAL_SALES: {
    url: "/specialSalesPerson",
    // sumUrl: "/salesPerson/summary",
    downloadUrl: "/specialSalesPerson/download?fileType=",
    keysArray: [
      "un",
      "ts",
      "mn",
      "lc",
      ["lAmt", { isAmount: true }],
      "userPreservedData",
    ],
  },
  COLLECTIONS: {
    url: "/messenger",
    sumUrl: "/messenger/summary",
    downloadUrl: "/messenger/download?fileType=",

    keysArray: [
      "ts",
      "mid",
      "mn",
      "cae",
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtD", "amtT", { isAmount: true }],
      "caec",
      "acic",
      "acid",
      "acit",
    ],
    sumKeysArray: [
      ["amtC", "amtTbC", { isAmount: true }],
      ["amtD", "amtT", { isAmount: true }],
    ],
  },
  WEB_COLLECTIONS: {
    url: "/web/collection/minimart",
    downloadUrl: "/web/collection/minimart/download?fileType=",

    keysArray: [
      "cd",
      "mid",
      "cid",
      "mn",
      ["ca", { isAmount: true }],
      "vd",
      "cb"
    ],
  },
  WALLET: {
    url: "/wallet-loan-list",
    downloadUrl: "/wallet-loan-list/download?fileType=",
    keysArray: [
      "id",
      "mid",
      "appId",
      "rt",
      ["pdc", "pdi", "pdlf", { isAmount: true }],
      "ts",
      "mn",
      "ty",
      "da",
      "pdb",
      "userPreservedData",
      "pftc",
      "arid",
      "cli",
      "crli",
    ],
  },
};

export const LIST_THIRD_REPORTS = {
  LOAN_PORTFOLIO: {
    url: "/salesPersons",
    keysArray: ["name", "date", "userPreservedData"],
  },
};

export const PROVISION = [
  { key: 0, label: Strings("selectProvision"), value: Strings("selectProvision") },
  { key: 1, label: "A", value: "A" },
  { key: 2, label: "B", value: "B" },
  { key: 3, label: "C", value: "C" },
  { key: 4, label: "D", value: "D" },
  { key: 5, label: "D1", value: "D1" },
  { key: 6, label: "D2", value: "D2" },
  { key: 7, label: "E", value: "E" },
  // // { key: 7, label: "A", value: "A" },
];

export const RESTRUCTURING_STATUS = [
  { key: 0, label: Strings("RestructuringStatus"), value: Strings("RestructuringStatus") },
  { key: 1, label: Strings("NonRestructured"), value: '0' },
  { key: 2, label: Strings("Restructured"), value: '1' },
  { key: 3, label: Strings("createdByRestructuring"), value: '2' },
  { key: 4, label: Strings("closedByRestructuring"), value: '3' },
  { key: 5, label: Strings("CreatedAndClosedRestructuredLoan"), value: '4' },
]

export const DATE_KEYS = [
  "fromLoanDate",
  "toLoanDate",
  "fromInvoiceDate",
  "toInvoiceDate",
  "fromDate",
  "toDate",
  "rd",
];

export const ARRAY_OF_ZEROS = [
  "fromInvoiceCount",
  "toInvoiceCount",
  "fromLoanCount",
  "toLoanCount",
  "FromLoanAmount",
  "toLoanAmount",
  "upperAmount",
  "lowerAmount",
  "toLoanAmount",
  "FromLoanAmount",
];

export const LOAN_STATUS = [
  { value: "", label: Strings("selectLoanStatus") },
  { value: "ACTIVE", label: Strings("Active") },
  { value: "LEGAL", label: Strings("Legal") },
  { value: "W_OFF", label: Strings("W_OFF") },
  { value: "CLOSED", label: Strings("Closed") },
  { value: "DISAPPEARED", label: Strings("DISAPPEARED") },
  { value: "PAYMENT_AGREEMENT", label: Strings("PAYMENT_AGREEMENT") },
];

export const DEFAULT_SELECTION = [
  "Tipo de Préstamo",
  "Estado de Préstamo",
  "selectBranch",
  "Zona",
  "Provisión",
  Strings("selectRouteDays"),
  "selectSalesModerator",
  "Zona del vendedor",
  "Zona de Mensajería",
  "Estatus de Préstamo",
  Strings("RestructuringStatus"),
];

export const ROUTE_DAYS = [
  {
    key: 0,
    value: Strings("selectRouteDays"),
    label: Strings("selectRouteDays"),
  },
  {
    key: 1,
    value: "MONDAY",
    label: Strings("MONDAY"),
  },
  {
    key: 2,
    value: "TUESDAY",
    label: Strings("TUESDAY"),
  },
  {
    key: 3,
    value: "WEDNESDAY",
    label: Strings("WEDNESDAY"),
  },
  {
    key: 4,
    value: "THURSDAY",
    label: Strings("THURSDAY"),
  },
  {
    key: 5,
    value: "FRIDAY",
    label: Strings("FRIDAY"),
  },
  {
    key: 6,
    value: "SATURDAY",
    label: Strings("SATURDAY"),
  },
];

export const GET_DEFAULT_SALES_MODERATOR_DROPDOWN = () => ({
  id: "selectSalesModerator",
  role: Strings("selectSalesModerator"),
});
