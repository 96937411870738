import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import ProfileDetails from "./ProfileDetails";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

//Actions
import {
  updateUserProfileAction,
  uploadProfilePicAction,
  // getBranchDetailsAction,
  createUserAction,
  deactivateUserAction,
  getUserProfilePicUrlAction,
  getReportingUsersAction,
  updateProfileInfoAction,
  getCollectionOfficersAction,
  getPositionsAction,
  getDeactivationAndChangeRoleStatusofUser,
  getAvailabilityStatusOfUser,
  getSameRoleUserList,
  workloadDistribution,
  workloadDistributionForUnavailableUsers,
  changeAvailabilityStatus,
  workloadDistributionForChangeRole,
  changeRole
} from "./ProfileDetailsActions";
import {
  setUserDetailsAction,
  getUserRolesForAddUserAction,
  getUsersBySearchFilterAction,
  setIsAddUSerFlagAction,
} from "../UserList/UserListAction";
import {
  getBranchBySearchFilterAction,
  getZonesUnderSelectedBranchAction,
} from "../BranchList/BranchListAction";
import { setSearchFilterAction } from "../../constants/Action";

//Strings
import { Strings } from "../../resources/i18n/i18n";

// Constants
import { OPERATION_STATUS } from "../../config/axios.init";
import { ROUTES } from "../../constants/routeConstants";
import { DEFAULT_USER_DETAILS } from "./Constants";
import {
  USER_ROLES,
  USER_ROLES_WITH_REPORTING,
  REPORTING_USER_ROLES,
  USER_ROLES_TO_SHOW_ZONES,
  SEARCH_FILTER_PAGES,
  USER_LIST_FILTER,
  USER_ROLES_WITH_COLLECTION_OFFICER,
  USER_ROLES_WITH_POSITION,
  POSITIONS_USER_ROLES,
  SPECIAL,
} from "../../constants/appConstants";

// Utils
import Utility from "../../utils/Utility";

export class ProfileDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    /**
     * Below function call is used for restricting multiple API calls.
     * Passed API call and time in miliseconds to debounce function as arguments which ensures that the API call is made only once within a specified time frame, even if the button is clicked multiple times.
     */
    this.debounceCreateUserAction = Utility.debounce(this.callHandleCreateUser, 90000);
    this.state = {
      errorMessage: "",
      profilePicUrl: null,
      userDetails: DEFAULT_USER_DETAILS,
      showLoader: false,
      userRoles: props.userRoles,
      branches: props.branches,
      reportingUsers: null,
      zones: null,
      collectionOfficers: null,
      positions: null,
      level: null,
      successToastMessage: "",
      toShowToast: false,
      error: null,
      deactivationFlag: false,
      workloadCount: 0,
      cannotDeactivateReason: "",
      cannotChangeRoleReason: "",
      unavailabilityFlag: false,
      cannotUnavailableReason: ""
    };
  }

  componentDidMount() {
    this.getUserRoles(this.props.isAddUser);
    this.props.getBranchList();
    this.props.getAllZones("SELLER");
    this.props.getAllZones("MESSENGER");
    let role =
      this.props.userDetails !== undefined
        ? this.props.userDetails.role
        : this.props.loggedInUserInfo.role;
    if (this.props.userDetails?.role?.role) {
      role = this.props.userDetails.role.role;
    }

    if (USER_ROLES_WITH_REPORTING.indexOf(role) !== -1) {
      this.props.userDetails.branch &&
        USER_ROLES_WITH_REPORTING.includes(role) &&
        this.getReportingUsersUnderSelectedBranch({
          branches: this.props.userDetails.branchIds,
          Role: REPORTING_USER_ROLES[role],
        });
    }

    if (USER_ROLES_WITH_COLLECTION_OFFICER.indexOf(role) !== -1) {
      this.props.userDetails?.branch &&
        USER_ROLES_WITH_COLLECTION_OFFICER.includes(role) &&
        this.getCollectionOfficersUnderSelectedBranch({
          branches: this.props.userDetails.branchIds,
        });
    }

    if (USER_ROLES_WITH_POSITION.indexOf(role) !== -1) {
      this.props.userDetails?.branch &&
        USER_ROLES_WITH_POSITION.includes(role) &&
        this.props.userDetails?.type !== SPECIAL &&
        this.getPositionsUnderSelectedBranch({
          branches: this.props.userDetails.branchIds,
          Role: POSITIONS_USER_ROLES[role],
        });
    }

    if (USER_ROLES_TO_SHOW_ZONES.indexOf(role) !== -1) {
      this.props.userDetails?.branch &&
        USER_ROLES_TO_SHOW_ZONES.includes(role) &&
        this.props.getZonesByBranch(this.props.userDetails.branchIds[0]);
    }
    if (
      this.props.loggedInUserInfo.role === USER_ROLES.admin ||
      this.props.loggedInUserInfo.role === USER_ROLES.generalManager ||
      this.props.loggedInUserInfo.role === USER_ROLES.operationsModerator
    ) {
      if (this.props.userDetails && this.props.userDetails.active) {
        this.getDeactivationAndChangeRoleStatusofUser(this.props.userDetails.id);
        (role !== USER_ROLES.salesPerson
          && role !== USER_ROLES.messenger)
          && this.props.userDetails.available
          && this.getAvailabilityStatusOfUser(this.props.userDetails.id);
        if (role === USER_ROLES.analyst
          || role === USER_ROLES.operationsOfficer
          || role === USER_ROLES.accountingOfficer) {
          this.getSameRoleUserList(this.props.userDetails.id)
        }
      }
    }
  }

  componentWillUnmount() {
    if (
      this.props.searchPage === SEARCH_FILTER_PAGES.USER_LIST &&
      !this.props.selectedFeature?.urls.includes(
        this.props.history.location.pathname
      )
    ) {
      this.props.setSearchFilterAction(
        SEARCH_FILTER_PAGES.USER_LIST,
        { ...USER_LIST_FILTER }
      );
    }
  }

  isRoleAdmin = () => {
    let loggedInUserInfo = this.props.loggedInUserInfo;
    return (
      (loggedInUserInfo.role === USER_ROLES.admin) ||
      (loggedInUserInfo?.role === USER_ROLES.generalManager) ||
      (loggedInUserInfo?.role === USER_ROLES.operationsModerator) ||
      (loggedInUserInfo?.role === USER_ROLES.technology)
    );
  };

  getUserRoles = (isAddUser = false) => {
    if (this.isRoleAdmin()) {
      this.setState({ showLoader: true });
      getUserRolesForAddUserAction(isAddUser, (response) => {
        this.setState({ showLoader: false });
        if (response.status === OPERATION_STATUS.SUCCESS) {
          let userRoles = response.data.data.roles;
          userRoles = userRoles.map(((role) => {
            return { name: role.role, value: role.role };
          }))
          this.setState({
            userRoles,
          });
        } else {
          this.setState({
            errorMessage: response.error.message,
          });
        }
      });
    } else {
      let loggedInUserProfile = this.props.loggedInUserProfile;
      let userRole = [];
      if (loggedInUserProfile)
        userRole.push(loggedInUserProfile.role);
      this.setState({
        userRoles: userRole,
      });
    }
  };

  getDeactivationAndChangeRoleStatusofUser = (userId) => {
    this.setState({ showLoader: true });
    getDeactivationAndChangeRoleStatusofUser(userId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoader: false,
          deactivationFlag: response.data.data.df,
          workloadCount: response.data.data.wc,
          cannotDeactivateReason: response.data.data.r,
          cannotChangeRoleReason: response.data.data.cr
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false
        });
      }
    });
  }

  getAvailabilityStatusOfUser = (userId) => {
    this.setState({ showLoader: true });
    getAvailabilityStatusOfUser(userId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoader: false,
          unavailabilityFlag: response.data.data.uf,
          workloadCount: response.data.data.wc,
          cannotUnavailableReason: response.data.data.r,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false
        });
      }
    });
  }

  getSameRoleUserList = (userId) => {
    this.setState({ showLoader: true });
    getSameRoleUserList(userId, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: "",
          showLoader: false,
          sameRoleUserList: response.data.data
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false
        });
      }
    });
  }

  workloadDistribution = (workloadSelectedUserIds, callback) => {
    this.setState({ showLoader: true });

    let request = { tbdid: this.props?.userDetails?.id, uid: workloadSelectedUserIds }
    workloadDistribution(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          errorMessage: "",
          toShowToast: true,
          successToastMessage: Strings("userDeactivatedSuccessfully"),
          level: "success",
        }, () => {
          callback();
          setTimeout(() => {
            this.props.history.push(ROUTES.USER_LIST);
          }, 3000);
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  }

  workloadDistributionForUnavailableUsers = (workloadSelectedUserIds, callback) => {
    this.setState({ showLoader: true });
    let request = { oui: this.props?.userDetails?.id, uid: workloadSelectedUserIds }
    workloadDistributionForUnavailableUsers(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          errorMessage: "",
          toShowToast: true,
          successToastMessage: Strings("userIsUnavailableNow"),
          level: "success",
        }, () => {
          callback();
          setTimeout(() => {
            this.props.history.push(ROUTES.USER_LIST);
          }, 3000);
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  }

  workloadDistributionForChangeRole = (workloadSelectedUserIds, userRequest, callback) => {
    this.setState({ showLoader: true });
    userRequest["id"] = undefined;
    userRequest["branches"] = userRequest.branch;
    userRequest["branchIds"] = undefined;

    let request = {
      oui: this.props.userDetails?.id,
      nr: userRequest.role,
      uid: workloadSelectedUserIds,
      or: this.props.userDetails.role,
      crd: userRequest
    }
    workloadDistributionForChangeRole(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          errorMessage: "",
          toShowToast: true,
          successToastMessage: Strings("userRoleChangedSuccessfully"),
          level: "success",
        }, () => {
          callback();
          setTimeout(() => {
            this.props.history.push(ROUTES.USER_LIST);
          }, 3000);
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  }



  changeAvailabilityStatus = (availabilityFlag, callback) => {
    this.setState({ showLoader: true });

    let request = { oui: this.props?.userDetails?.id, af: availabilityFlag }
    changeAvailabilityStatus(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          errorMessage: "",
          toShowToast: true,
          successToastMessage: !availabilityFlag
            ? Strings("userIsUnavailableNow")
            : Strings("userIsAvailableNow"),
          level: "success",
        }, () => {
          callback();
          setTimeout(() => {
            this.props.history.push(ROUTES.USER_LIST);
          }, 3000);
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  }

  changeRole = (userRequest, callback) => {
    this.setState({ showLoader: true });
    userRequest["id"] = undefined;
    userRequest["branches"] = userRequest.branch;
    userRequest["branchIds"] = undefined;
    let request = {
      oui: this.props.userDetails?.id,
      nr: userRequest.role,
      or: this.props.userDetails.role,
      crd: userRequest
    }
    changeRole(request, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          errorMessage: "",
          toShowToast: true,
          successToastMessage: Strings("userRoleChangedSuccessfully"),
          level: "success",
        }, () => {
          callback();
          setTimeout(() => {
            this.props.history.push(ROUTES.USER_LIST);
          }, 3000);
        });
      } else {
        this.setState(
          { errorMessage: response.error.message },
        );
      }
    });
  }


  updateUserProfile = (userProfile, callback) => {
    let isSelfProfile =
      this.props.loggedInUserInfo &&
      userProfile &&
      this.props.loggedInUserInfo.username === userProfile.username;
    this.setState({ showLoader: true });
    updateUserProfileAction(userProfile, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        isSelfProfile &&
          this.props.updateProfileInfoAction(response.data.data);
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("UserUpdatedSuccessfully"),
            level: "success",
          },
          () => {
            setTimeout(() => {
              callback(response);
              this.props.setIsAddUSerFlagAction({ isAddUser: false });
              this.pushProfileRoute(isSelfProfile);
            }, 3000);
          }
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false
        });
      }
    });
  };

  pushProfileRoute = (isSelfProfile) => {
    if (!isSelfProfile) {
      this.props.history.push(ROUTES.USER_LIST);
    } else {
      switch (this.props.loggedInUserInfo.role) {
        case USER_ROLES.collectionsOfficer:
          this.props.history.push(ROUTES.LOAN_PORTFOLLIO_MANAGEMENT);
          break;
        case USER_ROLES.configurator:
          this.props.history.push(ROUTES.EARLY_PAYMENT);
          break;
        case USER_ROLES.accountsOfficer:
          this.props.history.push(ROUTES.COLLECTION_VERIFICATION);
          break;
        case USER_ROLES.analystModerator:
          this.props.history.push(ROUTES.LOAN_APPROVAL_DASHBOARD);
          break;
        case USER_ROLES.analyst:
          this.props.history.push(ROUTES.LOAN_APPROVAL_DASHBOARD);
          break;
        default:
          this.props.history.push(ROUTES.DASHBOARD);
      }
    }
  };

  uploadProfilePic = (username, profilePicFile) => {
    let profilePicData = { username: username, file: profilePicFile };
    this.setState({ showLoader: true });

    uploadProfilePicAction(profilePicData, (response) => {
      if (response.status !== OPERATION_STATUS.SUCCESS) {
        this.setState({
          errorMessage: response.error.message
        });
      } else {
        if (this.props.loggedInUserProfile.username === username) {
          //If user has changed his own profile data we update the profile pic in Header
          this.props.getUserProfilePicUrlAction(
            username,
            () => {
              /* This is intentional */
            },
            true
          );
          this.setState({
            errorMessage: ""
          });
        }
      }
      this.setState({ showLoader: false });
    });
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  handleDeactivateUser = (userDetails) => {
    if (userDetails) {
      this.setState({ showLoader: true });
      deactivateUserAction(userDetails.username, (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          this.setState({
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("userDeactivatedSuccessfully"),
            level: "success",
          }, () => {
            setTimeout(() => {
              this.props.history.push(ROUTES.USER_LIST);
            }, 3000);
          });
        } else {
          this.setState({
            errorMessage: response.error.message,
            showLoader: false
          });
        }
      });
    }
  };

  handleCreateUser = (userDetails, file, callback) => {
    this.setState({ showLoader: true });

    this.debounceCreateUserAction(userDetails, file, callback);
  };

  callHandleCreateUser = (userDetails, file, callback) => {
    createUserAction(userDetails, (response) => {
      this.setState({ showLoader: false });

      if (response.status === OPERATION_STATUS.SUCCESS) {
        if (file) {
          this.uploadProfilePic(userDetails.username, file);
        }
        let successToastMessage;
        if (response.data.data) {
          successToastMessage = response?.data?.data.iacd
            ? Strings("UserCreatedSuccessfully")
            : Strings("UserCreatedSuccessfullyAssignmentsNotDone")
        } else {
          successToastMessage = Strings("UserCreatedSuccessfully")
        }
        this.setState(
          {
            errorMessage: "",
            toShowToast: true,
            successToastMessage: successToastMessage,
            level: "success",
          },
          () => {
            setTimeout(() => {
              this.props.setIsAddUSerFlagAction({ isAddUser: false });
              this.props.history.push(ROUTES.USER_LIST);
            }, 3000);
          }
        );
      } else {
        this.setState({ errorMessage: response.error.message });
      }
      callback(response);
    });
  }

  //Get profile pic
  getProfilePic = (username) => {
    if (username) {
      this.setState({ showLoader: true });
      this.props.getUserProfilePicUrlAction(username, (response) => {
        if (response.status === OPERATION_STATUS.SUCCESS) {
          if (response.data.data.s3Url) {
            this.setState({
              errorMessage: "",
              profilePicUrl: Utility.getDecryptedData(
                response.data.data.s3Url
              ),
              showLoader: false
            });
          }
        } else {
          let error = response.error;
          this.setState({
            profilePicUrl: null, error,
            showLoader: false
          });
        }
      });
    }
  };

  getUserProfile = () => {
    let userProfile = this.props.userDetails;
    if (userProfile?.role?.role) {
      userProfile.role = userProfile.role.role;
    }
    if (userProfile.branch)
      userProfile.branch = this.props.userDetails.branch;

    return userProfile;
  };

  getReportingUsersUnderSelectedBranch = (searchFilterObject) => {
    this.setState({ showLoader: true });
    getReportingUsersAction(searchFilterObject, (response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userDetails = response.data.data;
        userDetails.forEach((element) => {
          if (element.roleDto) {
            element.role = element.roleDto;
          }
        });

        this.setState({
          errorMessage: "",
          reportingUsers: userDetails,
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  };

  getCollectionOfficersUnderSelectedBranch = (searchFilterObject) => {
    this.setState({ showLoader: true });
    getCollectionOfficersAction(searchFilterObject, (response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let userDetails = response.data.data;
        userDetails = userDetails.map((user) => {
          return { name: user.username, value: user.id }
        })
        this.setState({
          errorMessage: "",
          collectionOfficers: userDetails,
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  };

  getPositionsUnderSelectedBranch = (searchFilterObject) => {
    this.setState({ showLoader: true });
    getPositionsAction(searchFilterObject, (response) => {
      this.setState({ showLoader: false });
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let positionDetails = response.data.data;
        positionDetails = positionDetails.map((position) => {
          return { name: position.position, value: position.position, zones: position.zones }
        })
        this.setState({
          errorMessage: "",
          positions: positionDetails,
        });
      } else {
        this.setState({ errorMessage: response.error.message });
      }
    });
  };


  render() {
    return (
      <div className='profileDetails'>
        <LoadingModal showLoader={this.state.showLoader} />
        {this.state.toShowToast && (
          <UserNotification
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: this.state.level,
            }}
          />
        )}

        {this.state.userRoles &&
          this.props.branches &&
          this.props.loggedInUserInfo && (
            <ProfileDetails
              userProfile={this.getUserProfile()}
              loggedInUserInfo={this.props.loggedInUserInfo}
              userRole={this.props.loggedInUserInfo.role}
              userRolesList={this.state.userRoles}
              profilePicUrl={this.state.profilePicUrl}
              errorMessage={this.state.errorMessage}
              updateUserProfile={this.updateUserProfile}
              uploadProfilePic={this.uploadProfilePic}
              onBackClick={this.handleBackButtonClick}
              handleDeactivateUser={this.handleDeactivateUser}
              handleCreateUser={this.handleCreateUser}
              getProfilePic={this.getProfilePic}
              branches={this.props.branches}
              userBranches={this.props.branches}
              reportingUsers={this.state.reportingUsers}
              getReportingUsersUnderSelectedBranch={
                this.getReportingUsersUnderSelectedBranch
              }
              isAddUser={this.props.isAddUser}
              getZonesByBranch={this.props.getZonesByBranch}
              zones={this.props.zones}
              mZones={this.props.mZones}
              collectionOfficers={this.state.collectionOfficers}
              getCollectionOfficersUnderSelectedBranch={
                this.getCollectionOfficersUnderSelectedBranch
              }
              getPositionsUnderSelectedBranch={
                this.getPositionsUnderSelectedBranch
              }
              positions={this.state.positions}
              error={this.state.error}

              //User Deactivation
              deactivationFlag={this.state.deactivationFlag}
              workloadCount={this.state.workloadCount}
              cannotDeactivateReason={this.state.cannotDeactivateReason}
              sameRoleUserList={this.state.sameRoleUserList}
              workloadDistribution={this.workloadDistribution}

              //User availability
              cannotUnavailableReason={this.state.cannotDeactivateReason}
              workloadDistributionForUnavailableUsers={this.workloadDistributionForUnavailableUsers}
              changeAvailabilityStatus={this.changeAvailabilityStatus}

              //change role
              cannotChangeRoleReason={this.state.cannotChangeRoleReason}
              workloadDistributionForChangeRole={this.workloadDistributionForChangeRole}
              changeRole={this.changeRole}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUserInfo: state.loginReducer.loggedInUserInfo,
  loggedInUserProfile: state.loginReducer.loggedInUserProfile,
  userDetails: state.userListReducer.userDetails,
  // userRoles: state.userListReducer.userRoles,
  isAddUser: state.userListReducer.isAddUser,
  branches: state.branchListReducer.branchDetails.branches,
  searchPage: state.searchFilterReducer.searchPage,
  selectedFeature: state.sidebarReducer.selectedFeature,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfilePicUrlAction,
      setUserDetailsAction,
      getUsersBySearchFilterAction,
      getBranchBySearchFilterAction,
      setIsAddUSerFlagAction,
      getZonesUnderSelectedBranchAction,
      updateProfileInfoAction,
      setSearchFilterAction,
      getDeactivationAndChangeRoleStatusofUser,
      getSameRoleUserList,
      workloadDistribution,
      workloadDistributionForUnavailableUsers,
      changeAvailabilityStatus,
      workloadDistributionForChangeRole,
      changeRole
    },
    dispatch
  );

const ProfileDetailsWrapper = withHOC(ProfileDetailsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDetailsWrapper);
