import React from "react";
import { compose } from "react-recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Component
import LoadingModal from "../components/CustomUIComponents/LoadingModal/LoadingModal";

//Action
import {
  getBranchDetailsAction,
  getZonesUnderSelectedBranchAction,
  getAllZonesAction,
} from "../components/BranchList/BranchListAction";

//Constants
import { OPERATION_STATUS } from "../config/axios.init";
import {
  GET_DEFAULT_ZONE_SELECTION,
  DEFAULT_ZONE,
} from "../components/BranchList/BranchListConstants";
import {
  DEFAULT_BRANCH_SELECTION,
  GET_DEFAULT_BRANCH_SELECTION,
} from "../components/UserList/UserListConstants";
import { DEFAULT_MESSENGER_ZONE, GET_DEFAULT_MESSENGER_ZONE_SELECTION } from "../components/BranchList/BranchListConstants";

//Strings

//Utility

//Styles

//Assets

export default function withHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showLoader: false,
        branches: [],
        zones: [],
        mZones: [],
        errorMessage: "",
      };
    }

    getBranchList = () => {
      this.setState({
        showLoader: true,
      });
      this.props.getBranchDetailsAction((response) => {
        this.setState({ showLoader: false });
        if (response.status === OPERATION_STATUS.SUCCESS) {
          let branchesList = response.data.data.branches;
          branchesList[0].id !== DEFAULT_BRANCH_SELECTION &&
            branchesList.unshift(GET_DEFAULT_BRANCH_SELECTION());
          this.setState({
            branches: branchesList,
          });
        } else {
          this.setState({
            errorMessage: response.error.message,
          });
        }
      });
    };

    getZonesByBranch = (branchId, userId = null) => {
      //If branch is selected,get zones for that branch
      if (branchId) {
        this.setState({ showLoader: true });
        this.props.getZonesUnderSelectedBranchAction(
          branchId,
          (Response) => {
            if (Response.status === OPERATION_STATUS.SUCCESS) {
              let zonesList =
                Response.data.data[0] &&
                this.convertZoneArray(Response.data.data[0].zones);
              let mZonesList =
                Response.data.data[0] &&
                this.convertZoneArray(Response.data.data[0].mzones);
              zonesList[0]?.name !== DEFAULT_ZONE &&
                zonesList.unshift(GET_DEFAULT_ZONE_SELECTION());
              mZonesList[0]?.name !== DEFAULT_MESSENGER_ZONE &&
                mZonesList.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());

              this.setState({
                errorMessage: "",
                zones: zonesList,
                mZones: mZonesList,
                showLoader: false,
              });
            } else {
              this.setState({
                showLoader: false,
                errorMessage: Response.error.message,
              });
            }
          },
          userId
        );
      }
      // else get all zones
      else {
        this.getAllZones("SELLER");
      }
    };

    getAllZones = (role) => {
      this.setState({ showLoader: true });
      this.props.getAllZonesAction(role, (response) => {
        this.setState({ showLoader: false });
        if (response.status === OPERATION_STATUS.SUCCESS) {
          let zonesList = [];
          let mZonesList = [];

          if (role === "SELLER") {
            zonesList = response.data.data;
            mZonesList = this.state.mZones;
          } else if (role === "MESSENGER") {
            zonesList = this.state.zones;
            mZonesList = response.data.data;
          }
          zonesList[0]?.name !== DEFAULT_ZONE &&
            zonesList.unshift(GET_DEFAULT_ZONE_SELECTION());
          mZonesList[0]?.name !== DEFAULT_MESSENGER_ZONE &&
            mZonesList.unshift(GET_DEFAULT_MESSENGER_ZONE_SELECTION());

          this.setState({
            zones: zonesList,
            mZones: mZonesList,
          }, () => {
            if (role === "SELLER") {
              this.getAllZones("MESSENGER");
            }
          });
        } else {
          this.setState({
            showLoader: false,
            errorMessage: response.error.message,
          });
        }
      });
    };

    convertZoneArray = (zones) => {
      let zonesArray = [];
      zones.forEach((zone) => {
        zonesArray.push({ name: zone });
      });
      return zonesArray;
    };

    render() {
      return (
        <>
          <LoadingModal showLoadingModal={this.state.showLoader} />
          <WrappedComponent
            branches={this.state.branches}
            zones={this.state.zones}
            mZones={this.state.mZones}
            errorMessage={this.state.errorMessage}
            getAllZones={this.getAllZones}
            getBranchList={this.getBranchList}
            getZonesByBranch={this.getZonesByBranch}
            {...this.props}
          />
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    loggedInUser: state.loginReducer.loggedInUserInfo,
    commissionSearchFilter:
      state.searchFilterReducer.commissionSearchFilter,
    approvalDashboardSearchFilter:
      state.searchFilterReducer.approvalDashboardSearchFilter,
    flrSearchFilter: state.searchFilterReducer.flrSearchFilter,
    slrSearchFilter: state.searchFilterReducer.slrSearchFilter,
    userListSearchFilter:
      state.searchFilterReducer.userListSearchFilter,
    notesSearchFilter:
      state.searchFilterReducer.notesSearchFilter,
    minimartListSearchFilter:
      state.searchFilterReducer.minimartListSearchFilter,
    spedL1SearchFilter: state.searchFilterReducer.spedL1SearchFilter,
    dailyStatusSearchFilter:
      state.searchFilterReducer.dailyStatusSearchFilter,
    loanDetailFilter: state.searchFilterReducer.loanDetailFilter,
    loanPortfolioSearchFilter:
      state.searchFilterReducer.loanPortfolioSearchFilter,
  });

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getBranchDetailsAction,
        getZonesUnderSelectedBranchAction,
        getAllZonesAction,
      },
      dispatch
    );
  };
  return compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(HOC);
}
