//Libraries
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import { Row } from "react-bootstrap";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import MotorcycleCatalog from "./MotorcycleCatalog";
import withHOC from "../../HOC/HOC";

//Utility
import Utility from "../../utils/Utility";
//Constants
import { Strings } from "../../resources/i18n/i18n";
import { USER_ROLES } from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

//Actions
import {
  getMotorcycleCategoriesAction,
  getMotorcycleCatalogAction,
  addNewMotorcycleAction,
  addMotorcycleCategoryAction,
  updateMotorcycleCategoryAction,
  editMotorcycleAction,
} from "./MotorcycleCatalogueAction";

//Stylesheets
import "./MotorcycleCatalog.scss";

class MotorcycleCatalogContainer extends React.Component {
  constructor(props) {
    super(props);
    /**
     * Below function call is used for restricting multiple API calls.
     * Passed API call and time in miliseconds to debounce function as arguments which ensures that the API call is made only once within a specified time frame, even if the button is clicked multiple times.
     */
    this.debounceAddNewMotocycle = Utility.debounce(this.callAddNewMotorcycle, 90000);
    this.debounceEditMotorcycle = Utility.debounce(this.callEditMotorcycle, 90000);
    this.state = {
      motorcycleData: [],
      categories: [],
      numberOfPages: 1,
      showLoader: false,
      errorMessage: "",
      toShowToast: false,
      successToastMessage: "",
      readOnly:
        props.loggedInUser.role === USER_ROLES.analyst ||
        props.loggedInUser.role === USER_ROLES.operationsOfficer ||
        props.loggedInUser.role === USER_ROLES.operationsModerator ||
        props.loggedInUser.role === USER_ROLES.analystModerator ||
        props.loggedInUser.role === USER_ROLES.collectionsSupervisor ||
        props.loggedInUser.role === USER_ROLES.administrativeManager ||
        props.loggedInUser.role === USER_ROLES.customerService ||
        props.loggedInUser.role === USER_ROLES.salesManager ||
        props.loggedInUser.role === USER_ROLES.collectionsManager ||
        props.loggedInUser.role === USER_ROLES.technology,
    };
    this.apiProcessCounter = 0;
  }

  componentDidMount() {
    this.getAllCategories();
    this.handleSearchFilter({ search: {}, limit: 10, offset: 0 });
  }

  handleSearchFilter = (searchFilterObjject) => {
    this.setState({
      showLoader: true,
      dummyFilters: searchFilterObjject,
      errorMessage: "",
    });
    this.apiProcessCounter = this.apiProcessCounter + 1;
    getMotorcycleCatalogAction(searchFilterObjject, (apiResponse) => {
      this.apiProcessCounter = this.apiProcessCounter - 1;
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        let motorcycleData = [];
        for (const motorcycle of apiResponse.data.data.li) {
          let motorCycleProduct = { ...motorcycle };
          if (motorCycleProduct.img) {
            // S3 URL decryption
            motorCycleProduct.img = Utility.getDecryptedData(
              motorCycleProduct.img
            );
          }
          motorcycleData.push(motorCycleProduct);
        }
        this.setState({
          showLoader: this.apiProcessCounter !== 0,
          motorcycleData,
          errorMessage: "",
          numberOfPages: apiResponse.data.data.np,
        });
      } else {
        this.setState({
          motorcycleData: [],
          numberOfPages: 1,
          showLoader: this.apiProcessCounter !== 0,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  addCategory = (category) => {
    this.setState({ showLoader: true, toShowToast: false });
    addMotorcycleCategoryAction(category, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("categoryNotification"),
          },
          () => {
            this.getAllCategories();
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getAllCategories = () => {
    this.setState({ showLoader: true });
    this.apiProcessCounter = this.apiProcessCounter + 1;
    getMotorcycleCategoriesAction((apiResponse) => {
      this.apiProcessCounter = this.apiProcessCounter - 1;
      if (apiResponse.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: this.apiProcessCounter !== 0,
          categories: apiResponse.data.data,
          errorMessage: "",
        });
      } else {
        this.setState({
          categories: [],
          showLoader: this.apiProcessCounter !== 0,
          errorMessage: apiResponse.error.message,
        });
      }
    });
  };

  updateMotorcycleCategory = (category) => {
    this.setState({ showLoader: true, toShowToast: false });
    updateMotorcycleCategoryAction(category, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            showLoader: false,
            errorMessage: "",
            toShowToast: true,
            successToastMessage: Strings("categoryNotification"),
          },
          () => {
            this.getAllCategories();
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  updateProduct = () => {
    // Code is coming soon
  };

  handleAddNewMotorcycle = (reqObj, callback) => {
    this.setState({ showLoader: true });
    this.debounceAddNewMotocycle(reqObj, callback);
  };

  callAddNewMotorcycle = (reqObj, callback) => {
    addNewMotorcycleAction(reqObj, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            successToastMessage: Strings(
              "MotorcycleAddedSuccessfully"
            ),
          },
          () => {
            callback();
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          showLoader: false,
          errorMessage: Strings("SorryAnErrorHasOccurred"),
        });
      }
    });
  }

  handleEditMotorcycle = (reqObj, id, callback) => {
    this.setState({ showLoader: true });
    this.debounceEditMotorcycle(reqObj, id, callback);
  };

  callEditMotorcycle = (reqObj, id, callback) => {
    editMotorcycleAction(reqObj, id, (Response) => {
      //NOSONAR
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState(
          {
            toShowToast: true,
            successToastMessage: Strings(
              "MotorcycleAddedSuccessfully"
            ),
          },
          () => {
            callback();
            this.handleSearchFilter(this.state.dummyFilters);
          }
        );
      } else {
        this.setState({
          showLoader: false,
          errorMessage: Strings("SorryAnErrorHasOccurred"),
        });
      }
    });
  }

  render() {
    return (
      <div className='motorcycleCatalog'>
        <Row className='heading'>
          {Strings("motorcycleCatalogueTitleText")}
        </Row>
        {this.state.toShowToast && (
          <UserNotification
            key={this.state.successToastMessage}
            userNotificationObj={{
              message: this.state.successToastMessage,
              level: "success",
            }}
          />
        )}
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <MotorcycleCatalog
          errorMessage={this.state.errorMessage}
          handleSearchFilter={this.handleSearchFilter}
          numberOfPages={this.state.numberOfPages}
          categories={this.state.categories}
          addCategory={this.addCategory}
          addProduct={this.addProduct}
          getAllCategories={this.getAllCategories}
          updateCategory={this.updateMotorcycleCategory}
          updateProduct={this.updateProduct}
          readOnly={this.state.readOnly}
          loggedInUser={this.props.loggedInUser}
          motorcycleData={this.state.motorcycleData}
          handleAddNewMotorcycle={this.handleAddNewMotorcycle}
          handleEditMotorcycle={this.handleEditMotorcycle}
          branches={this.props.branches}
          getBranchList={this.props.getBranchList}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //NOSONAR
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
const MotorcycleCatalogWrapper = withHOC(MotorcycleCatalogContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MotorcycleCatalogWrapper);
