import React from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";

// Constants
import {
  DEFAULT_STATUS,
  GET_CONFIGURATION_STATUS_DROPDOWN,
} from "./ConfigurationListConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  USER_ROLES,
} from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

// Styles
import "./ConfigurationList.scss";
class ConfigurationList extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      version: props.searchFilters?.version || "",
      status: props.searchFilters?.status || DEFAULT_STATUS,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
    };
  }

  componentDidMount() {
    this.props.loggedInUserInfo.role === USER_ROLES.configurator &&
      this.handleOnClickSearchFilter(false);
  }

  /* #endregion */

  /* #region  Events */
  handleOnChangeVersion = (event) => {
    this.setState({
      version: event.target.value,
    });
  };

  handleOnChangeFilterState = (event) => {
    this.setState({
      status: event.target.value,
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleOnClickSearchFilter({
      version: this.state.version ? this.state.version : undefined,
      status:
        this.state.status !== DEFAULT_STATUS
          ? this.state.status
          : undefined,
      limit: this.state.recordsPerPage,
      offset: isDefaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE
        : this.state.activePage,
    });
  };

  handleOnClickResetSearch = () => {
    this.setState(
      {
        version: "",
        status: DEFAULT_STATUS,
      },
      () => this.handleOnClickSearchFilter(true)
    );
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => {
        this.handleOnClickSearchFilter(false);
      }
    );
  };
  /* #endregion */

  renderSearchFilter = () => {
    let DEFAULT_DROPDOWN_SELECTION =
      GET_CONFIGURATION_STATUS_DROPDOWN();

    let configurationListFilters = [
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        searchPlaceHolder: Strings("Version"),
        handleOnChange: this.handleOnChangeVersion,
        Value: this.state.version,
        fieldname: SEARCH_FILTER_FIELDS.VERSION,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleOnChangeFilterState,
        Value: this.state.status,
        fieldname: SEARCH_FILTER_FIELDS.STATE,
        filterDropdownList: DEFAULT_DROPDOWN_SELECTION,
      },
    ];
    return (
      <SearchFilter
        filters={configurationListFilters}
        paddingLeft={true}
        searchPlaceHolder={Strings("Version")}
        filterValue={this.state.status}
        searchValue={this.state.version}
        filterDropdownList={GET_CONFIGURATION_STATUS_DROPDOWN()}
        handleOnClickSearchFilter={this.handleOnClickSearchFilter}
        handleOnClickResetFilter={this.handleOnClickResetSearch}
      />
    );
  };

  renderCustomDataGrid = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='d-flex justify-content-center mx-3 mb-3'>
        <TableContainer component={Paper} className='tableWidth'>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                {this.props.columns.length > 0 &&
                  this.props.columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center header px-0"}
                      title={column.name}
                    >
                      <div className='tableCell'>{column.name}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + " " + row.version}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.onRowClick(row)}
                >
                  {this.props.columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={"text-center px-0"}
                      component='th'
                      scope='row'
                      title={row[column.key] ? row[column.key] : "--"}
                    >
                      <div className='tableCell'>
                        {row[column.key] ? row[column.key] : "--"}
                      </div>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  configWarning = () => {
    const showWarning = this.props?.isObsolete;
    return (
      <>
        {showWarning ? (
          <Row className="ps-3 pb-4 ">
            <Col md={12}>
              <span className="warningText d-flex justify-content-center">
                {Strings("disabledConfiguration")}
              </span>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };
  renderPagination = () => {
    return (
      <Pagination
        activePage={this.state.activePage}
        recordsPerPage={this.state.recordsPerPage}
        numberOfPages={this.props.numberOfPages}
        onPageChange={this.onPageChange}
        dropup={true}
      />
    );
  };

  render() {
    return (
      <div className='configurationList'>
        <Row className='paddingLeft'>
          <span className='heading p-0'>
            {Strings("ConfigurationManagement_" + this.props.type)}
          </span>
        </Row>
        <div className='ListContainer '>
          {this.renderSearchFilter()}
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
          {this.props.rowsCount !== 0 ? (
            <div>
              {this.configWarning()}
              {this.renderCustomDataGrid()}
              <Col md={12} className='noPadding'>
                {this.renderPagination()}
              </Col>
            </div>
          ) : (
            <div className='noRecordsFound noBranchRecords'>
              {Strings("NoRecordsFound")}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConfigurationList;
