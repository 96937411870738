import { Strings } from "../../resources/i18n/i18n";

export const DEFAULT_USER_DETAILS = {
  active: false,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  photo: "defaultImage",
  role: "",
  username: "",
  branch: [],
  reportingToUserId: "",
  collectionOfficer: "",
  position: "",
};

export const PROFILE_PHOTO_FILE_SIZE = 2048000;

export const DEFAULT_USER_SELECTION = "UserRole";

export const GET_DEFAULT_DROPDOWN_SELECTION = () => ({
  name: Strings("UserRole"),
  value: "",
});

export const DEFAULT_REPORTING_USER = "SelectReportingUser";

export const SELECT_DEFAULT_REPORTING_USER = () => ({
  id: DEFAULT_REPORTING_USER,
  role: Strings(DEFAULT_REPORTING_USER),
});

export const DEFAULT_COLLECTION_OFFICER_DROPDOWN =
  "SelectCollectionOfficer";

export const SELECT_COLLECTION_OFFICER_USER = () => ({
  name: Strings(DEFAULT_COLLECTION_OFFICER_DROPDOWN),
  value: "",
});

export const DEFAULT_POSITION_DROPDOWN = "SelectPosition";

export const SELECT_POSITION_USER = () => ({
  name: Strings(DEFAULT_POSITION_DROPDOWN),
  value: "",
});

export const POSITION_TYPE = {
  EXISTING_POSITION: "EXISTING_POSITION",
  NEW_POSITION: "NEW_POSITION"
}

export const POSITION_TYPES = [
  {
    name: Strings("position"),
    value: ""
  },
  {
    name: Strings(POSITION_TYPE.EXISTING_POSITION),
    value: POSITION_TYPE.EXISTING_POSITION,
  },
  {
    name: Strings(POSITION_TYPE.NEW_POSITION),
    value: POSITION_TYPE.NEW_POSITION,
  },
]