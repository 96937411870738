/* eslint-disable array-callback-return */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

//Components
import SuperDashBoard from "./SuperDashBoard";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
import withHOC from "../../HOC/HOC";

//Actions
import {
  getLoanChartDataAction,
  getInvoiceChartDataAction,
  getUserAndMiniMartDataAction,
  getNewUserAndMiniMartDataAction,
  getLoanApplicationChartDataAction,
  getProvisionDataAction,
  getDenialReasonsDataAction,
  getAmountDataAction,
  getFinanceProfitDataAction,
  getMinimartBusinessTypes,
  getPromotionStatsAction,
} from "./SuperDashBoardAction";
import { clearDailyStatusDateAction } from "../DailyStatus/DailyStatusActions";

//Constants
import {
  LOAN_CHART_TABS,
  USER_ROLES,
  ALL,
  DENIAL_REASON_TYPE,
  AMOUNT_COLLECTION_STATUS,
  LOAN_APPLICATION_LEGENDS,
  LOAN_STATUS_PIE_CHART_KEYS,
  CARD_TYPE,
  DEFAULT_BRANCH_SELECTION,
  DEFAULT_LOAN_TYPE,
  USER_RATING_TABS,
  DEFAULT_DURATION,
  DEFAULT_DATE_FORMAT,
  DATE_TYPE,
} from "../../constants/appConstants";
import { OPERATION_STATUS } from "../../config/axios.init";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./SuperDashBoard.scss";

class SuperDashBoardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimartsFilters: {
        branchId: "",
        minimartType: "ALL",
        businessType: "",
      },
      newMinimartsFilters: {
        branchId: "",
        minimartType: "ALL",
        businessType: "",
      },
      invoiceFilters: {
        branchId: "",
        duration: {
          month: Utility.getCurrentMonthYear().currentMonth,
          year: Utility.getCurrentMonthYear().currentYear,
        },
      },
      loanFilters: {
        loanType: "",
        branchId: "",
        duration: {
          month: Utility.getCurrentMonthYear().currentMonth,
          year: Utility.getCurrentMonthYear().currentYear,
        },
      },

      promotionFilter: {
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: DEFAULT_DURATION.toDate,
      },

      invoiceData: null,
      minimart_userData: null,
      new_minimart_userData: null,
      loanData: null,
      loanApplicationData: null,
      loanStatusCount: 0,
      loanCategoryCount: 0,
      provisionCount: 0,
      provisionPieChartData: null,
      provisionBarChartData: null,
      denialBarChartData: null,
      denialPieChartData: null,
      financeProfitBarChartData: null,
      denialCount: 0,
      errorMessage: props.errorMessage,
      activeTab:
        props.loggedInUser.role !== USER_ROLES.salesModerator
          ? LOAN_CHART_TABS[0]
          : LOAN_CHART_TABS[1],
      selectedRatingTab: USER_RATING_TABS[0],
      loanBarchartData: [],
      MonthYearStrings: [],
      type: ALL,
      disbursedAmount: 0,
      amountToBeCollected: 0,
      amount: null,
      calender: "MonthYearPicker",
      businessTypeList: [
        {
          key: 0,
          value: "",
          label: Strings("BusinessType"),
        },
      ],
      promotionStatData: null,
    };
  }

  componentDidMount() {
    this.props.clearDailyStatusDateAction();
    this.props.getBranchList();
    this.getMiniMartAndUserData(this.state.minimartsFilters);
    this.getNewUserAndMiniMartData(this.state.newMinimartsFilters);
    this.props.loggedInUser.role !== USER_ROLES.messengerModerator &&
      this.getInvoiceData();
    this.getLoanChartData();
    this.getMinimartBusinessTypes();
    if (this.props.loggedInUser.role === USER_ROLES.generalManager
      || this.props.loggedInUser.role === USER_ROLES.admin
      || this.props.loggedInUser.role === USER_ROLES.salesModerator) {
      this.searchPromotionFilterClick();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }

  handleBranchChange = (key, event) => {
    let branchId =
      event.target.value &&
        event.target.value !== "" &&
        event.target.value !== DEFAULT_BRANCH_SELECTION
        ? event.target.value
        : "";
    switch (key) {
      case CARD_TYPE.INVOICE:
        this.setState({
          invoiceFilters: {
            ...this.state.invoiceFilters,
            branchId,
          },
        });
        break;
      case CARD_TYPE.LOAN:
        this.setState({
          loanFilters: {
            ...this.state.loanFilters,
            branchId,
          },
        });
        break;
      case CARD_TYPE.NEW_MINIMART:
        this.setState({
          newMinimartsFilters: {
            ...this.state.newMinimartsFilters,
            branchId,
          },
        });
        break;
      default:
        this.setState({
          minimartsFilters: {
            ...this.state.minimartsFilters,
            branchId,
          },
        });
    }
  };

  handleBusinessTypeChange = (event) => {
    this.setState({
      minimartsFilters: {
        ...this.state.minimartsFilters,
        businessType:
          event.target.value !== Strings("BusinessType")
            ? event.target.value
            : "",
      },
    });
  };

  handleMinimartTypeChange = (event) => {
    this.setState({
      minimartsFilters: {
        ...this.state.minimartsFilters,
        minimartType: event.target.value,
      },
    });
  };

  handleNewBusinessTypeChange = (event) => {
    this.setState({
      newMinimartsFilters: {
        ...this.state.newMinimartsFilters,
        businessType:
          event.target.value !== Strings("BusinessType")
            ? event.target.value
            : "",
      },
    });
  };

  handleNewMinimartTypeChange = (event) => {
    this.setState({
      newMinimartsFilters: {
        ...this.state.newMinimartsFilters,
        minimartType: event.target.value,
      },
    });
  };

  handleChangeMonthYearSelection = (selectedMonthYearObject) => {
    let month = moment(selectedMonthYearObject).get("month") + 1;
    let year = moment(selectedMonthYearObject).get("year");

    this.setState({
      invoiceFilters: {
        ...this.state.invoiceFilters,
        duration: {
          month: month,
          year: year,
        },
      },
    });
  };

  getMinimartData = () => {
    this.getMiniMartAndUserData(this.state.minimartsFilters);
  };

  getNewMinimartData = () => {
    this.getNewUserAndMiniMartData(this.state.newMinimartsFilters);
  };

  getMinimartBusinessTypes = () => {
    this.setState({ showLoader: true });
    getMinimartBusinessTypes((response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let list = Utility.createBusinessTypesList(
          response.data.data.bty
        );
        this.setState({
          businessTypeList: list,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getMiniMartAndUserData = (requestData) => {
    this.setState({ showLoader: true });
    getUserAndMiniMartDataAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        let minimartTypes = [...responseData.data.data.minimartTypes];
        minimartTypes.filter((minimartType) => {
          return minimartType.type === "NEW";
        });
        responseData.data.data.minimartTypes = minimartTypes;
        this.setState({
          minimart_userData:responseData.data.data, 
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: false,
        });
      }
    });
  };

  getNewUserAndMiniMartData = (requestData) => {
    this.setState({ showLoader: true });
    getNewUserAndMiniMartDataAction(requestData, (responseData) => {
      if (responseData.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          new_minimart_userData: responseData.data.data,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: responseData.error.message,
          showLoader: false,
        });
      }
    });
  };

  resetMinimartData = () => {
    this.setState(
      {
        minimartsFilters: {
          branchId: "",
          minimartType: "ALL",
          businessType: "",
        },
      },
      () => {
        this.getMiniMartAndUserData(this.state.minimartsFilters);
      }
    );
  };

  resetNewMinimartData = () => {
    this.setState(
      {
        newMinimartsFilters: {
          branchId: "",
          minimartType: "ALL",
          businessType: "",
        },
      },
      () => {
        this.getNewUserAndMiniMartData(
          this.state.newMinimartsFilters
        );
      }
    );
  };

  resetInvoiceData = () => {
    this.setState(
      {
        invoiceFilters: {
          branchId: "",
          duration: {
            month: Utility.getCurrentMonthYear().currentMonth,
            year: Utility.getCurrentMonthYear().currentYear,
          },
        },
      },
      () => {
        this.getInvoiceData();
      }
    );
  };

  getInvoiceData = () => {
    this.getInvoiceChartData(this.state.invoiceFilters);
  };

  getInvoiceChartData = (requestData) => {
    this.setState({ showLoader: true });
    getInvoiceChartDataAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setInvoiceBarchartDatakeys(
          response.data.data.graphResponse,
          "MonthYearStrings"
        );
        this.setState({
          invoiceData: response.data.data,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  setInvoiceBarchartDatakeys = (invoiceData, key) => {
    let MonthYearStrings = [];
    if (invoiceData !== null) {
      invoiceData.map((oneMonthCount) => {
        let count = oneMonthCount.count;
        let year = oneMonthCount.year.toString().substring(2, 4);
        let month =
          Utility.getSpanishStringForMonth(
            Utility.getIntegerValue(oneMonthCount.month)
          ).substring(0, 3) +
          " " +
          year;

        MonthYearStrings.push({ count, month });
      });

      this.setState({
        [key]: MonthYearStrings,
      });
    } else {
      this.setState({
        [key]: [],
      });
    }
  };

  getLoanAppData = (requestData) => {
    this.setState({ showLoader: true });
    getLoanChartDataAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let tempStatus = this.getOrderedPieChartData(
          response.data.data.loanStatus,
          LOAN_APPLICATION_LEGENDS,
          "status"
        );
        this.setInvoiceBarchartDatakeys(
          response.data.data.graphResponse,
          "loanBarchartData"
        );
        this.setState({
          loanApplicationData: tempStatus,
          loanStatusCount: response.data.data.loanStatusCount,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getLoanData = (requestData) => {
    this.setState({ showLoader: true });
    getLoanApplicationChartDataAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        let pieLoanCategories = this.getOrderedPieChartData(
          response.data.data.loanCategories,
          LOAN_STATUS_PIE_CHART_KEYS,
          "category"
        );
        this.setInvoiceBarchartDatakeys(
          response.data.data.graphResponse,
          "loanBarchartData"
        );

        this.setState({
          loanData: pieLoanCategories,
          loanCategoryCount: response.data.data.loanCategoryCount,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getOrderedPieChartData = (propsArray, legendsArray, key) => {
    let tempStatus = [];
    propsArray !== null &&
      legendsArray.forEach((legend, index) => {
        let objIndex = propsArray.findIndex((loanAppStatus) => {
          return loanAppStatus[key] === legend;
        });
        tempStatus[index] = propsArray[objIndex];
      });
    return tempStatus;
  };

  getAmountData = (requestData) => {
    let entries = [];
    this.setState({ showLoader: true });
    getAmountDataAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        entries = [
          {
            amountStatus: "amountCollected",
            count: response.data.data.ca,
          },
          {
            amountStatus: "PastDues",
            count: response.data.data.pda,
          },
          {
            amountStatus: "Dues",
            count: response.data.data.da,
          },
        ];
        const pieAmountData = this.getOrderedPieChartData(
          entries,
          AMOUNT_COLLECTION_STATUS,
          "amountStatus"
        );
        // let pieAmountData=
        this.setState({
          amount: pieAmountData,
          amountToBeCollected: response.data.data.atbc,
          disbursedAmount: response.data.data.dsa,
          showLoader: false,
        });
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
    getFinanceProfitDataAction(requestData, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({ showLoader: false });
        this.setBarGraphDataForFinanceProfit(Response.data.data);
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  setBarGraphDataForFinanceProfit = (GraphData) => {
    if (GraphData !== null) {
      this.mapFinanceProfitDataBarGraph(GraphData);
    } else {
      this.setState({
        financeProfitBarChartData: [],
      });
    }
  };

  mapFinanceProfitDataBarGraph = (GraphData) => {
    let graphdata = [];
    GraphData.map((data) => {
      let month =
        Utility.getSpanishStringForMonth(data.month).substring(0, 3) +
        "-" +
        String(data["year"]).substring(2, 4);
      let tempObj = {
        totalInterestPaid: 0,
        totalLateFeesPaid: 0,
        conciliationInterest: 0,
        conciliationLateFees: 0,
        month,
      };
      if (data.profits[0]) {
        tempObj = {
          totalInterestPaid: data.profits[0].totalInterestPaid,
          totalLateFeesPaid: data.profits[0].totalLateFeesPaid || 0,
          conciliationInterest: data.profits[0].conciliationInterest,
          conciliationLateFees:
            data.profits[0].conciliationLateFees || 0,
          month,
        };
      }
      graphdata.push(tempObj);
    });
    this.setState({
      financeProfitBarChartData: graphdata,
    });
  };

  handleTypeChange = (event) => {
    let loanType =
      event.target.value &&
        event.target.value !== "" &&
        event.target.value !== DEFAULT_LOAN_TYPE
        ? event.target.value
        : "";

    this.setState({
      loanFilters: {
        ...this.state.loanFilters,
        loanType,
      },
    });
  };

  handleChangeMonthYearForLoanSelection = (
    selectedMonthYearObject
  ) => {
    let month = moment(selectedMonthYearObject).get("month") + 1;
    let year = moment(selectedMonthYearObject).get("year");

    this.setState({
      loanFilters: {
        ...this.state.loanFilters,
        duration: {
          month: month,
          year: year,
        },
      },
    });
  };

  getLoanChartData = () => {
    let loanFilters = { ...this.state.loanFilters };
    if (loanFilters.branchId === "") {
      delete loanFilters.branchId;
    }
    if (loanFilters.loanType === "") {
      delete loanFilters.loanType;
    }

    if (this.state.activeTab.key === 1) {
      this.getLoanAppData(loanFilters);
      this.setState({
        calender: "MonthYearPicker",
      });
    } else if (this.state.activeTab.key === 0) {
      this.getLoanData(loanFilters);
      this.setState({
        calender: "MonthYearPicker",
      });
    } else if (this.state.activeTab.key === 2) {
      this.getProvisionData(loanFilters);
      this.setState({
        calender: "MonthYearPicker",
      });
    } else if (this.state.activeTab.key === 3) {
      this.getDenialData(loanFilters);
      this.setState({
        calender: "MonthYearPicker",
      });
    } else if (this.state.activeTab.key === 4) {
      this.getAmountData(loanFilters);
      this.setState({
        calender: "YearPicker",
      });
    }
  };

  handleTabs = (index) => {
    this.setState({ activeTab: LOAN_CHART_TABS[index] }, () => {
      this.getLoanChartData();
    });
  };

  handleRatingTabs = (index) => {
    this.setState(
      {
        selectedRatingTab: USER_RATING_TABS[index],
        minimartsFilters: {
          ...this.state.minimartsFilters,
          businessType: "",
        },
      },
      () => {
        this.getMinimartData();
      }
    );
  };

  resetLoanChartData = () => {
    this.setState(
      {
        loanFilters: {
          loanType: "",
          branchId: "",
          duration: {
            month: Utility.getCurrentMonthYear().currentMonth,
            year: Utility.getCurrentMonthYear().currentYear,
          },
        },
      },
      () => {
        this.getLoanChartData();
      }
    );
  };

  /* #region  Provision */

  getProvisionData = (requestData) => {
    this.setState({ showLoader: true });
    getProvisionDataAction(requestData, (response) => {
      if (response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
        });
        this.setPieChartDataForProvision(
          response.data.data,
          this.state.type
        );
        this.setBarChartDataForProvision(
          response.data.data,
          this.state.type
        );
      } else {
        this.setState({
          errorMessage: response.error.message,
          showLoader: false,
        });
      }
    });
  };

  setPieChartDataForProvision = (
    GraphData,
    type,
    responseType = "PROVISION"
  ) => {
    let graphData = [],
      totalCount = 0;
    if (responseType === "PROVISION") {
      if (GraphData !== null) {
        this.mapProvisionGraphDataPieChart(
          GraphData,
          type,
          totalCount,
          graphData
        );
      } else {
        this.setState({
          provisionPieChartData: [],
        });
      }
    } else {
      if (GraphData !== null) {
        this.mapNonProvisionGraphDataPieChart(
          GraphData,
          type,
          totalCount,
          graphData
        );
      } else {
        this.setState({
          denialPieChartData: [],
        });
      }
    }
  };
  mapProvisionGraphDataPieChart = (
    GraphData,
    type,
    totalCount,
    graphData
  ) => {
    GraphData.map((data) => {
      if (
        parseInt(data.month) ===
        parseInt(this.state.loanFilters.duration.month) &&
        parseInt(data.year) ===
        parseInt(this.state.loanFilters.duration.year)
      ) {
        let tempObj = { A: 0, B: 0, C: 0, D: 0, D1: 0, D2: 0, E: 0 };
        data.data.map((mapobj, index) => {
          if (type.indexOf(mapobj.ty, 0) >= 0) {
            tempObj["month"] =
              Utility.getSpanishStringForMonth(
                data["month"]
              ).substring(0, 3) +
              "-" +
              String(data["year"]).substring(2, 4);
            tempObj[data.data[index].ty] += data.data[index].cnt;
            totalCount += data.data[index].cnt;

            graphData.push({
              category: data.data[index].ty,
              count: data.data[index].cnt,
            });
          }
        });
      }
    });
    this.setState({
      provisionPieChartData: graphData,
      provisionCount: totalCount,
    });
  };
  mapNonProvisionGraphDataPieChart = (
    GraphData,
    type,
    totalCount,
    graphData
  ) => {
    GraphData.map((data) => {
      if (
        parseInt(data.month) ===
        parseInt(this.state.loanFilters.duration.month) &&
        parseInt(data.year) ===
        parseInt(this.state.loanFilters.duration.year)
      ) {
        let tempObj = {
          El_cliente_no_tenia_el_dinero: 0,
          Colmado_estaba_cerrado: 0,
          El_administrador_estaba_fuera_la_persona_no_sabía: 0,
        };
        data.data.map((mapobj, index) => {
          let keyIndex = type.indexOf(mapobj.rs, 0);
          if (keyIndex >= 0) {
            tempObj["month"] =
              Utility.getSpanishStringForMonth(
                data["month"]
              ).substring(0, 3) +
              "-" +
              String(data["year"]).substring(2, 4);
            tempObj[Object.keys(tempObj)[keyIndex]] +=
              data.data[index].cnt;
            totalCount += data.data[index].cnt;

            graphData.push({
              category: tempObj[Object.keys(tempObj)[keyIndex]],
              count: data.data[index].cnt,
            });
          }
        });
      }
    });

    this.setState({
      denialPieChartData: graphData,
      denialCount: totalCount,
    });
  };

  setBarChartDataForProvision = (
    GraphData,
    type,
    responseType = "PROVISION"
  ) => {
    let graphdata = [];
    if (responseType === "PROVISION") {
      this.mapProvisionGraphDataBarGraph(GraphData, type, graphdata);
    } else {
      this.mapNonProvisionGraphDataBarGraph(
        GraphData,
        type,
        graphdata
      );
    }
  };
  mapProvisionGraphDataBarGraph = (GraphData, type, graphdata) => {
    GraphData.map((data) => {
      if (data.data.length >= 1) {
        let tempObj = { A: 0, B: 0, C: 0, D: 0, D1: 0, D2: 0, E: 0 };
        data.data.map((mapobj, index) => {
          if (type.indexOf(mapobj.ty, 0) >= 0) {
            tempObj["month"] =
              Utility.getSpanishStringForMonth(
                data["month"]
              ).substring(0, 3) +
              "-" +
              String(data["year"]).substring(2, 4);
            tempObj[data.data[index].ty] += data.data[index].proAmt;
          }
        });
        graphdata.push(tempObj);
      }
    });
    this.setState({
      provisionBarChartData: graphdata,
    });
  };
  mapNonProvisionGraphDataBarGraph = (GraphData, type, graphdata) => {
    GraphData.map((data) => {
      if (data.data.length >= 1) {
        let El_cliente_no_tenia_el_dinero =
          "El cliente no tenia el dinero";
        let Colmado_estaba_cerrado = "Colmado estaba cerrado";
        let El_administrador_estaba_fuera_la_persona_no_sabía =
          "El administrador estaba fuera, la persona no sabía";
        let tempObj = {
          [El_cliente_no_tenia_el_dinero]: 0,
          [Colmado_estaba_cerrado]: 0,
          [El_administrador_estaba_fuera_la_persona_no_sabía]: 0,
        };
        data.data.map((mapobj, index) => {
          let keyIndex = type.indexOf(mapobj.rs, 0);
          if (keyIndex >= 0) {
            tempObj["month"] =
              Utility.getSpanishStringForMonth(
                data["month"]
              ).substring(0, 3) +
              "-" +
              String(data["year"]).substring(2, 4);
            tempObj[Object.keys(tempObj)[keyIndex]] +=
              data.data[index].cnt;
          }
        });
        graphdata.push(tempObj);
      }
    });
    this.setState({
      denialBarChartData: graphdata,
    });
  };

  getDenialData = (Filters) => {
    this.setState({ showLoader: true });
    getDenialReasonsDataAction(Filters, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({ showLoader: false });
        this.setPieChartDataForProvision(
          Response.data.data,
          DENIAL_REASON_TYPE,
          "DENIAL_REASON"
        );
        this.setBarChartDataForProvision(
          Response.data.data,
          DENIAL_REASON_TYPE,
          "DENIAL_REASON"
        );
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
        });
      }
    });
  };

  getPromotionStats = (request) => {
    this.setState({ showLoader: true });
    getPromotionStatsAction(request, (Response) => {
      if (Response.status === OPERATION_STATUS.SUCCESS) {
        this.setState({
          showLoader: false,
          promotionStatData: Response.data.data,
        });
      } else {
        this.setState({
          errorMessage: Response.error.message,
          showLoader: false,
          promotionStatData: null,
        });
      }
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.promotionFilter.toDate)) {
          DateValue = this.state.promotionFilter.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.promotionFilter.fromDate)
        ) {
          DateValue =
            moment(dateMoment).diff(
              this.state.promotionFilter.fromDate,
              "days"
            ) === 0
              ? this.state.promotionFilter.fromDate
              : this.state.promotionFilter.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      promotionFilter: {
        ...this.state.promotionFilter,
        [key]: DateValue,
      },
    });
  };
  resetPromotionFilterClick = () => {
    this.setState(
      {
        promotionFilter: {
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: DEFAULT_DURATION.toDate,
        },
      },
      this.searchPromotionFilterClick
    );
  };

  searchPromotionFilterClick = () => {
    this.getPromotionStats({
      fd: Utility.convertDateIntoMiliSeconds(
        this.state.promotionFilter.fromDate
      ),
      td: Utility.convertDateIntoMiliSeconds(
        this.state.promotionFilter.toDate
      ),
    });
  };

  /* #endregion */

  render() {
    return (
      <div className='superDashBoard'>
        <LoadingModal showLoadingModal={this.state.showLoader} />
        <SuperDashBoard
          amount={this.state.amount}
          amountToBeCollected={this.state.amountToBeCollected}
          disbursedAmount={this.state.disbursedAmount}
          invoiceData={this.state.invoiceData}
          minimart_userData={this.state.minimart_userData}
          new_minimart_userData={this.state.new_minimart_userData}
          loanData={this.state.loanData}
          loanApplicationData={this.state.loanApplicationData}
          loanStatusCount={this.state.loanStatusCount}
          loanCategoryCount={this.state.loanCategoryCount}
          branches={this.props.branches}
          handleBranchChange={this.handleBranchChange}
          resetInvoiceData={this.resetInvoiceData}
          resetMinimartData={this.resetMinimartData}
          resetNewMinimartData={this.resetNewMinimartData}
          getInvoiceChartData={this.getInvoiceData}
          getMiniMartAndUserData={this.getMinimartData}
          geNewtMiniMartAndUserData={this.getNewMinimartData}
          handleChangeMonthYearSelection={
            this.handleChangeMonthYearSelection
          }
          invoiceFilters={this.state.invoiceFilters}
          minimartFilters={this.state.minimartsFilters}
          newMinimartsFilters={this.state.newMinimartsFilters}
          loanFilters={this.state.loanFilters}
          MonthYearStrings={this.state.MonthYearStrings}
          handleChangeMonthYearForLoanSelection={
            this.handleChangeMonthYearForLoanSelection
          }
          handleChangeStatusFilter={this.handleChangeStatusFilter}
          handleTypeChange={this.handleTypeChange}
          handleTabs={this.handleTabs}
          getLoanChartData={this.getLoanChartData}
          resetLoanChartData={this.resetLoanChartData}
          activeTab={this.state.activeTab}
          loanBarchartData={this.state.loanBarchartData}
          loggedInUser={this.props.loggedInUser}
          provisionBarChartData={this.state.provisionBarChartData}
          provisionPieChartData={this.state.provisionPieChartData}
          provisionCount={this.state.provisionCount}
          selectedRatingTab={this.state.selectedRatingTab}
          handleRatingTabs={this.handleRatingTabs}
          denialCount={this.state.denialCount}
          denialBarChartData={this.state.denialBarChartData}
          denialPieChartData={this.state.denialPieChartData}
          financeProfitBarChartData={
            this.state.financeProfitBarChartData
          }
          calender={this.state.calender}
          minimartTypes={this.state.minimartTypes}
          handleMinimartTypeChange={this.handleMinimartTypeChange}
          handleBusinessTypeChange={this.handleBusinessTypeChange}
          handleNewMinimartTypeChange={
            this.handleNewMinimartTypeChange
          }
          handleNewBusinessTypeChange={
            this.handleNewBusinessTypeChange
          }
          businessTypeList={this.state.businessTypeList}
          handleDateChange={this.handleDateChange}
          promotionFilter={this.state.promotionFilter}
          resetPromotionFilterClick={this.resetPromotionFilterClick}
          searchPromotionFilterClick={this.searchPromotionFilterClick}
          promotionStatData={this.state.promotionStatData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({}); //NOSONAR

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearDailyStatusDateAction,
    },
    dispatch
  );
};

const SuperDashBoardWrapper = withHOC(SuperDashBoardContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperDashBoardWrapper);
