import { Strings } from "../../resources/i18n/i18n";

export const LOAN_HISTORY_STATUSES = [
  {
    key: 0,
    value:"selectLoanStatus",
    label: Strings("selectLoanStatus"),
  },

  {
    key: 1,
    value: "ANALYSIS",
    label: Strings("ANALYSIS"),
  },
  {
    key: 2,
    value: "REJECTED",
    label: Strings("Rejected"),
  },
  {
    key: 3,
    value: "DISMISSED",
    label: Strings("DISMISSED"),
  },
  {
    key: 4,
    value: "DISBURSED",
    label: Strings("DISBURSED"),
  },
  {
    key: 5,
    value: "INCOMPLETE",
    label: Strings("INCOMPLETE"),
  },
];
