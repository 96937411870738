// Constants
import { API_ERROR_CODE, OPERATION_STATUS } from "./axios.init";

// Strings
import { Strings } from "../resources/i18n/i18n";

//Utils
import Utility from "../utils/Utility";


const defaultErrorInfo = {
  code: API_ERROR_CODE.SERVER_ERROR,
  message: Strings("SorryAnErrorHasOccurred"),
  title: Strings("Error"),
};

export function getErrorInfo(errorCode) {
  let errorInfo = {};
  errorInfo.code = errorCode;

  switch (errorCode) {
    case OPERATION_STATUS.NETWORK_NOT_REACHABLE:
      errorInfo.message = Strings("NetworkNotReachable");
      errorInfo.title = Strings("ConnectionError");
      break;

    default:
      errorInfo = defaultErrorInfo;
  }

  return errorInfo;
}

// Error look-up function for error returned from APIs
export function getAPIErrorInfo(errorDetails) {
  let errorInfo = {};
  let errorDetailsObj;
  let loanTypes;
  let loanIds;
  errorInfo.code = errorDetails.code;

  switch (errorDetails.code) {
    case API_ERROR_CODE.UNAUTHORIZED:
      errorInfo.message = Strings("UnauthorizedUser");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.INVALID_LOGIN_CREDENTIALS:
    case API_ERROR_CODE.BAD_REQUEST:
      errorInfo.message = Strings("InvalidLoginCredentials");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DOCUMENT_NOT_FOUND:
      errorInfo.message = Strings("NoDocsForProcessing");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.SCHEDULED_PROCESS_FAILED:
      errorInfo.message = Strings("ScheduledProcessFailed");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ERROR_IN_FILE:
      errorInfo.message = Strings("ErrorInFile");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DUPLICATE_USER:
      errorInfo.message = Strings("DuplicateUserName");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_NOT_FOUND:
      errorInfo.message = Strings("UserNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_ALREADY_EXISTS:
      errorInfo.message = Strings("UserAlreadyExists");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.XML_PARSING_ERROR:
    case API_ERROR_CODE.ENCRYPTION_DECRYPTION_ERROR:
      errorInfo.message = Strings("InvalidXML");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.VALIDATION_ERROR:
      errorInfo.message = Strings("ValidationError");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.FORBIDDEN:
      errorInfo.message = Strings("Forbidden");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.INVALID_OLD_PASSWORD:
      errorInfo.message = Strings("InvalidOldPassword");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_INACTIVE:
      errorInfo.message = Strings("UserNotActive");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USERNAME_ALREADY_EXIST:
      errorInfo.message = Strings("UsernameAlreadyExists");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.ROLE_NOT_FOUND:
      errorInfo.message = Strings("RoleNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.FAILED_TO_SEND_EMAIL:
      errorInfo.message = Strings("FailedToSendEmail");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.ACCESS_NOT_FOUND:
      errorInfo.message = Strings("AccessNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.BINDING_RESULT:
      errorInfo.message = Strings("InvalidRequestParameters");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.SEARCH_PARAMETERS_CANT_BE_NULL:
      errorInfo.message = Strings("SearchParametersCanNotBeAllNull");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_APP_NOT_FOUND:
      errorInfo.message = Strings("LoanApplicationNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_APP_NOT_CREATED:
      errorInfo.message = Strings("LoanApplicationNotCreated");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_APP_INCOMPLETE:
      errorInfo.message = Strings("LoanApplicationIncomplete");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_APP_REJECTED:
      errorInfo.message = Strings("LoanApplicationRejected");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_UPDATE_NOT_ALLOWED:
      errorInfo.message = Strings("LOAN_UPDATE_NOT_ALLOWED");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_STATUS_UPDATE_NOT_ALLOWED:
      errorInfo.message = Strings("LoanStatusUpdateNotAllowed");
      errorInfo.title = Strings("SearchParametersCanNotBeAllNull");
      break;

    case API_ERROR_CODE.LOAN_OTP_CREATE_FAILED:
      errorInfo.message = Strings("OTPGenerationFailed");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_OTP_NOT_FOUND:
      errorInfo.message = Strings("LoanOTPNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_ANALYSIS_NOT_FOUND:
      errorInfo.message = Strings("AnalysisDataNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_OTP_NOT_CREATE_ALLOWED:
      errorInfo.message = Strings("LoanOTPCreateNotAllowed");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.INSUFFICIENT_LOAN_APPLICATION_DATA:
      errorInfo.message = Strings("INSUFFICIENT_LOAN_APPLICATION_DATA");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.LOAN_APP_INVALID_STATUS:
      errorInfo.message = Strings("LoanAppInvalidStatus");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.DOCUMENT_ALREADY_UPLOADED:
      errorInfo.message = Strings("DocumentAlreadyUploaded");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.INSUFFICIENT_DOCUMENTS:
      errorInfo.message = Strings("InsufficientDocuments");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.DOCUMENT_DELETE_NOT_ALLOWED:
      errorInfo.message = Strings("DocumentDeleteNotAllowed");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.INVALID_CONFIGURATION_FORMAT:
      errorInfo.message = Strings("ErrorInvalidConfiguration");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.VERSION_ALREADY_EXIST:
      errorInfo.message = Strings("ErrorVersionExists");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.DRAFTED_CONFIGURATION_ALREADY_EXISTS:
      errorInfo.message = Strings("ErrorDraftedConfigurationExists");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_CONFIGURATION_FOUND:
      errorInfo.message = Strings("ErrorConfigurationNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NOT_ALLOWED_TO_UPDATE_CONFIGURATION:
      errorInfo.message = Strings("ErrorCannotUpdateConfig");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_DRAFTED_CONFIGURATION_FOUND:
      errorInfo.message = Strings("ErrorDraftedConfigNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_ACTIVE_CONFIGURATION_FOUND:
      errorInfo.message = Strings("ErrorActiveNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.BRANCH_NOT_FOUND:
      errorInfo.message = Strings("ErrorBranchNotFound");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.BRANCHS_NOT_DEFINED:
      errorInfo.message = Strings("ErrorBranchNotDefined");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.BRANCH_ALREADY_EXIST:
      errorInfo.message = Strings("ErrorBranchAlreadyExists");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DEPOSIT_NOT_ALLOWED:
      errorInfo.message = Strings("ErrorDepositNotAllowed");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_NOT_FOUND:
      errorInfo.message = Strings("LOAN_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_REQUEST:
      errorInfo.message = Strings("InvalidNegotiationRequest");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_NOT_FOUND:
      errorInfo.message = Strings("NegotiationNotFound");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_AMOUNT:
      errorInfo.message = Strings("InvalidNegotiationAmount");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_RESPONSE:
      errorInfo.message = Strings("InvalidNegotiationResponse");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_STATE:
      errorInfo.message = Strings("InvalidNegotiationState");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_LATE_FEES:
      errorInfo.message = Strings("InvalidNegotiationLateFees");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_NEGOTIATION_INTEREST:
      errorInfo.message = Strings("InvalidNegotiationInterest");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NO_RECORDS_FOUND:
      errorInfo.message = Strings("NoRecordsFound");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOW_CREDIT_SCORE_APP_REJECTED:
      errorInfo.message = Strings(
        "appRejectedBecauseOfLowCreditScore"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.OPERATIONS_OFFICER_APPROVAL_FAILED:
      errorInfo.message = Strings(
        "failedApprovalOfOperationsOfficer"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_APPILCATION_SIGNATURE_NOT_FOUND:
      errorInfo.message = Strings("SignatureNotFound");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INSUFFICIENT_CREDIT_LIMIT:
      errorInfo.message = Strings("InsufficientCreditLimit");
      errorInfo.title = Strings("Error");
      errorInfo.data = errorDetails.message;
      break;
    case API_ERROR_CODE.PRODUCT_CATEGORY_ALREADY_EXISTS:
      errorInfo.message = Strings("CategoryAlreadyExists");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.PRODUCT_ALREADY_EXISTS:
      errorInfo.message = Strings("ProductAlreadyExists");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_BRANCH_COUNT:
      errorInfo.message = Strings("InvalidBranchCount");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.USER_DEACTIVATION_FAILED:
      errorInfo.message = Strings("USER_DEACTIVATION_FAILED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NO_ADJACENT_USER_FOUND:
      errorInfo.message = Strings("NO_ADJACENT_USER_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_USER_DEACTIVATION_REQUEST:
      errorInfo.message = Strings("INVALID_USER_DEACTIVATION_REQUEST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_REJECTED_DUE_TO_LOW_GRADE:
      errorInfo.message = Strings("LoanRejectedDuetoLowGrade");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_REJECTED_DUE_TO_NEGATIVE_REFERENCES:
      errorInfo.message = Strings(
        "LoanRejectedDueToNegativeReferences"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CANNOT_CHANGE_ASSIGNMENT:
      errorInfo.message = Strings("CannotChangeAssignments");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.SAME_DATE_LOAN_CANCEL_FAIL:
      errorInfo.message = Strings("CannotCloseSameDateLoan");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ACCOUNT_LOCKED_ERROR:
      errorInfo.message = Strings("ACCOUNT_LOCKED_ERROR_MESSAGE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_EXPIRED:
      errorInfo.message = Strings("NEGOTIATION_EXPIRED_MESSAGE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_EMPTY_FIELD_ERROR:
      errorInfo.message = Strings("EmptyFieldsError");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.SALESPERSON_ALREADY_ASSIGNED:
      errorInfo.message = Strings("SALESPERSON_ALREADY_ASSIGNED");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.PERMANENT_ASSIGNMENT_NOT_FOUND:
      errorInfo.message = Strings("PERMANENT_ASSIGNMENT_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.SALESPERSONS_VISIT_IS_ALREADY_PLANNED:
      errorInfo.message = Strings(
        "SALESPERSONS_VISIT_IS_ALREADY_PLANNED"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.HOLIDAY_ERROR:
      errorInfo.message = Strings("HOLIDAY_ERROR");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.HOLIDAY_ASSIGNMENT_ERROR:
      errorInfo.message = Strings("HOLIDAY_ASSIGNMENT_ERROR");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INTERNAL_SERVER_ERROR:
      errorInfo.message = Strings("INTERNAL_SERVER_ERROR");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.SALESPERSON_POSITION_NOT_FILLED_ROUTE:
      errorInfo.message = Strings(
        "salesPersonPositionNotFilledRoute"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_LATE_FEES_REQUEST:
      errorInfo.message = Strings("invalidLateFeesRequest");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_AMOUNT:
      errorInfo.message = Strings("INVALID_AMOUNT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.POSITION_NOT_FOUND:
      errorInfo.message = Strings("POSITION_NOT_FOUND");
      break;
    case API_ERROR_CODE.POSITION_NAME_ALREADY_PRESENT:
      errorInfo.message = Strings("POSITION_NAME_ALREADY_PRESENT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.BRANCH_ZONE_MAPPING_NOT_FOUND:
      errorInfo.message = Strings("BRANCH_ZONE_MAPPING_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ROUTE_NOT_FOUND:
      errorInfo.message = Strings("ROUTE_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_MESSENGER_FOUND_FOR_NEW_POSITION:
      errorInfo.message = Strings(
        "NO_MESSENGER_FOUND_FOR_NEW_MESSENGER_POSITION"
      );
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_MESSENGER_FOUND_IN_THE_ZONE:
      errorInfo.message = Strings("NO_MESSENGER_FOUND_IN_THE_ZONE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_CREDIT_LIMIT_AMOUNT:
      errorInfo.message = Strings("INVALID_CREDIT_LIMIT_AMOUNT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MESSENGER_POSITION_NOT_FILLED_FOR_ROUTE:
      errorInfo.message = Strings(
        "MESSENGER_POSITION_NOT_FILLED_FOR_ROUTE"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NOTE_NOT_FOUND:
      errorInfo.message = Strings("NOTE_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REQUEST_NOT_FOUND:
      errorInfo.message = Strings("REQUEST_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MINIMART_NOT_FOUND:
      errorInfo.message = Strings("MINIMART_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REMITTANCE_CORE_CONNECTION_PROBLEM:
      errorInfo.message = errorDetails.message;
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_AMOUNT_ON_PROMOTION:
      errorInfo.message = Strings("INVALID_AMOUNT_ON_PROMOTION");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MULTIPLE_ACTIVE_LOANS:
      loanIds = errorDetails.message.substring(
        errorDetails.message.indexOf("[") + 1,
        errorDetails.message.indexOf("]")
      );
      errorInfo.message = Strings("MULTIPLE_ACTIVE_LOANS").replace(
        "$",
        loanIds
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_LOAN_COMBINATION:
      errorDetailsObj = JSON.parse(errorDetails.data);

      loanTypes = errorDetailsObj.aldl.map((loanType) => {
        return Strings(loanType.trim());
      });
      errorInfo.message = Strings("INVALID_LOAN_COMBINATION")
        .replace("$", Strings(errorDetailsObj.rlt))
        .replace("#", errorDetailsObj.aid)
        .replace("^", loanTypes);
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.PRODUCT_NOT_FOUND:
      errorInfo.message = Strings("PRODUCT_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.FILE_UPLOAD_FAIL:
      errorInfo.message = Strings("FILE_UPLOAD_FAIL");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INSUFFICIENT_REWARD_POINTS:
      errorInfo.message = Strings("INSUFFICIENT_REWARD_POINTS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MINIMART_BLOCKED:
      errorInfo.message = Strings("MINIMART_BLOCKED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.PAYMENT_NOT_FOUND:
      errorInfo.message = Strings("PAYMENT_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REVERT_PAYMENTS_NOT_POSSIBLE:
      errorInfo.message = Strings("REVERT_PAYMENTS_NOT_POSSIBLE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MESSENGER_ALREADY_ASSIGNED:
      errorInfo.message = Strings("MESSENGER_ALREADY_ASSIGNED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NOTHING_TO_COLLECT:
      errorInfo.message = Strings("NOTHING_TO_COLLECT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_LOAN_STATE_CHANGE_REQUEST:
      errorInfo.message = Strings("THE_LOAN_STATE_CANNOT_BE_CHANGED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVOICE_NOT_FOUND:
      errorInfo.message = Strings("INVOICE_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ROUTE_NOT_ASSIGNED_FOR_MINIMART:
      errorInfo.message = Strings("ROUTE_NOT_ASSIGNED_FOR_MINIMART");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DISAPPEARED_LOAN_CANNOT_PAYOFF:
      errorInfo.message = Strings("DISAPPEARED_LOAN_CANNOT_PAYOFF");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.METAMAP_AUTHENTICATION_FAILED:
      errorInfo.message = Strings("METAMAP_AUTHENTICATION_FAILED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.FAILED_RO_GET_MEDIA_URL:
      errorInfo.message = Strings("FAILED_RO_GET_MEDIA_URL");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_COLLECTION_CYCLE_CHANGE_REQUEST:
      errorInfo.message = Strings(
        "INVALID_COLLECTION_CYCLE_CHANGE_REQUEST"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_COLLECTION_CYCLE_CHANGE:
      errorInfo.message = Strings("INVALID_COLLECTION_CYCLE_CHANGE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CREDO_DATASET_INSIGHT_RESPONSE_PARSING_FAILED:
    case API_ERROR_CODE.CREDO_LOAN_APPLICATION_NOT_FOUND:
      errorInfo.message = Strings(
        "CREDO_DATASET_INSIGHT_RESPONSE_PARSING_FAILED"
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.BRANCH_CANT_BE_NULL:
      errorInfo.message = Strings("BRANCH_CANT_BE_NULL");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ZONE_ALREADY_EXISTS:
      errorInfo.message = Strings("ZONE_ALREADY_EXISTS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NO_MESSENGER_FOUND_FOR_GIVEN_POSITION:
      errorInfo.message = Strings("NO_MESSENGER_FOUND_FOR_GIVEN_POSITION");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CASH_TOP_UP_LOAN_IS_ALREADY_ACTIVE:
      errorInfo.message = Strings("CASH_TOP_UP_LOAN_IS_ALREADY_ACTIVE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_LOAN_APPROVAL_REQUEST:
      errorInfo.message = Strings("INVALID_LOAN_APPROVAL_REQUEST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.PAYMENT_CANNOT_BE_REVERTED:
      errorInfo.message = Strings("PAYMENT_CANNOT_BE_REVERTED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.OWNER_OF_MINIMART_IS_BLACKLISTED:
      errorInfo.message = Strings("OWNER_OF_MINIMART_IS_BLACKLISTED");
      break;
    case API_ERROR_CODE.ALREADY_ASSIGNED_POSITION:
      errorInfo.message = Strings("ALREADY_ASSIGNED_POSITION");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CLIENT_ALREADY_EXIST:
      errorInfo.message = Strings("CLIENT_ALREADY_EXIST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ACCOUNT_DETAILS_NOT_FOUND:
      errorInfo.message = Strings("ACCOUNT_DETAILS_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REQUEST_ALREADY_EXISTS:
      errorInfo.message = Strings("REQUEST_ALREADY_EXISTS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_REQUEST:
      errorInfo.message = Strings("INVALID_REQUEST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.FILE_UPLOAD_NOT_ALLOWED:
      errorInfo.message = Strings("FILE_UPLOAD_NOT_ALLOWED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.TRANSFER_RECEIPT_NOT_FOUND:
      errorInfo.message = Strings("TRANSFER_RECEIPT_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CLIENT_NOT_FOUND:
      errorInfo.message = Strings("CLIENT_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.SUPPLIER_ALREADY_EXIST:
      errorInfo.message = Strings("SUPPLIER_ALREADY_EXIST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.RNC_NOT_FOUND:
      errorInfo.message = Strings("RNC_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.RNC_SERVICE_NOT_ACCESSIBLE:
      errorInfo.message = Strings("RNC_SERVICE_NOT_ACCESSIBLE");
      break;

    case API_ERROR_CODE.USER_NOT_FOUND_TO_HANDLE_WORKLOAD:
      errorInfo.message = Strings("USER_NOT_FOUND_TO_HANDLE_WORKLOAD");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_CEDULA_UPDATE_REQUEST:
      errorInfo.message = Strings("INVALID_CEDULA_UPDATE_REQUEST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CEDULA_DETAILS_MISMATCH:
      errorInfo.message = Strings("CEDULA_DETAILS_MISMATCH");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REPORT_FOR_DISBURSED_LOAN:
      errorInfo.message = Strings("REPORT_FOR_DISBURSED_LOAN");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.REPORT_NOT_AVAILABLE_FOR_OLD_LOAN:
      errorInfo.message = Strings("REPORT_NOT_AVAILABLE_FOR_OLD_LOAN");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.EMI_CALCULATION_NOT_FOUND:
      errorInfo.message = Strings("EMI_CALCULATION_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEW_LOAN_AMOUNT_INSUFFICIENT_FOR_CONCILIATION:
      errorInfo.message = Strings("NEW_LOAN_AMOUNT_INSUFFICIENT_FOR_CONCILIATION");
      break;
    case API_ERROR_CODE.ACCOUNT_NUMBER_PATTERN_MISMATCH:
      errorInfo.message = Strings("ACCOUNT_NUMBER_PATTERN_MISMATCH");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.BASIC_INFO_CANNOT_BE_NULL:
      errorInfo.message = Strings("BASIC_INFO_CANNOT_BE_NULL");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_BASIC_INFO_DATA:
      errorInfo.message = Strings("INVALID_BASIC_INFO_DATA");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_INCOME_DATA:
      errorInfo.message = Strings("INVALID_INCOME_DATA");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_SITE_VISIT_DATA:
      errorInfo.message = Strings("INVALID_SITE_VISIT_DATA");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_EXPENSES_DATA:
      errorInfo.message = Strings("INVALID_EXPENSES_DATA");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_CANT_CHANGE_ROLE_ITSELF:
      errorInfo.message = Strings("USER_CANT_CHANGE_ROLE_ITSELF");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_HAVE_SUBORDINATES_TO_CHANGE_ROLE:
      errorInfo.message = Strings("USER_HAVE_SUBORDINATES_TO_CHANGE_ROLE");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.USER_HAVE_ONGOING_LOANS_TO_CHANGE_ROLE:
      errorInfo.message = Strings("USER_HAVE_ONGOING_LOANS_TO_CHANGE_ROLE");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.NO_ADJACENT_USER_FOUND_TO_CHANGE_ROLE:
      errorInfo.message = Strings("NO_ADJACENT_USER_FOUND_TO_CHANGE_ROLE");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.RAISE_REMITTANCE_BEFORE_DISBURSEMENT:
      errorInfo.message = Strings("RAISE_REMITTANCE_BEFORE_DISBURSEMENT");
      errorInfo.title = Strings("Error");
      break;

    case API_ERROR_CODE.AMOUNT_APPROVED_CHANGE_NOT_ALLOWED:
      errorInfo.message = Strings("AMOUNT_APPROVED_CHANGE_NOT_ALLOWED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NO_DUE_INSTALLMENT_FOUND_FOR_CHANGIING_COLLECTION_CYCLE:
      errorInfo.message = Strings("NO_DUE_INSTALLMENT_FOUND_FOR_CHANGIING_COLLECTION_CYCLE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_UPDATE_NOT_ALLOWED:
      errorInfo.message = Strings("NEGOTIATION_UPDATE_NOT_ALLOWED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_UPDATE_BY_ANALYST:
      errorInfo.message = Strings("NEGOTIATION_UPDATE_BY_ANALYST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEGOTIATION_UPDATE_BY_OPERATION_USER:
      errorInfo.message = Strings("NEGOTIATION_UPDATE_BY_OPERATION_USER");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_FREQUENCY:
      errorInfo.message = Strings("INVALID_FREQUENCY");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_COLLECTION_DAYS:
      errorInfo.message = Strings("INVALID_COLLECTION_DAYS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.FREQUENCY_SHOULD_NOT_BY_EMPTY:
      errorInfo.message = Strings("FREQUENCY_SHOULD_NOT_BY_EMPTY");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MINIMART_CANNOT_BE_MARKED_NORMAL:
      errorInfo.message = Strings("MINIMART_CANNOT_BE_MARKED_NORMAL");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_LOAN_AMOUNT_REQUEST:
      errorInfo.message = Strings("INVALID_LOAN_AMOUNT_REQUEST");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_DOWNPAYMENT:
      errorInfo.message = Strings("INVALID_DOWNPAYMENT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MOTORCYCLE_NOT_FOUND:
      errorInfo.message = Strings("MOTORCYCLE_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_NOT_FOUND_FOR_COLLECTION:
      errorInfo.message = Strings("LOAN_NOT_FOUND_FOR_COLLECTION");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.COLLECTION_FAILED:
      errorInfo.message = Strings("COLLECTION_FAILED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INSUFFICIENT_ADMINISTRATIVE_EXPENSE_AMOUNT:
      errorDetailsObj = JSON.parse(errorDetails.message);
      console.log('errorDetailsObj: ', errorDetailsObj);
      errorInfo.message = Strings("INSUFFICIENT_ADMINISTRATIVE_EXPENSE_AMOUNT").replace(
        "$",
        `$ ${Utility.getCurrencyRepresentationOfAmount(errorDetailsObj.amt)}`
      );
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DUE_LOAN_NOT_FOUND:
      errorInfo.message = Strings("DUE_LOAN_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.FILE_NOT_FOUND:
      errorInfo.message = Strings("FILE_NOT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_INSTALLMENT_DATE_FOR_CHANGE_COLLECTION_CYCLE:
      errorInfo.message = Strings("INVALID_INSTALLMENT_DATE_FOR_CHANGE_COLLECTION_CYCLE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MINIMART_WITH_GRADE_A_CANNOT_BE_UPGRADED:
      errorInfo.message = Strings("MINIMART_WITH_GRADE_A_CANNOT_BE_UPGRADED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MINIMART_WITH_GRADE_D_CANNOT_BE_DOWNGRADED:
      errorInfo.message = Strings("MINIMART_WITH_GRADE_D_CANNOT_BE_DOWNGRADED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NO_ANY_DOWNPAYMENT_FOUND:
      errorInfo.message = Strings("NO_ANY_DOWNPAYMENT_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.CHOOSE_TWO_DIFFERENT_MINIMARTS:
      errorInfo.message = Strings("CHOOSE_TWO_DIFFERENT_MINIMARTS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.BRANCH_ZONE_MAPPING_NOT_PRESENT:
      errorInfo.message = Strings("BRANCH_ZONE_MAPPING_NOT_PRESENT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ACTIVE_CASHTOPUP_LOAN_FOUND:
      errorInfo.message = Strings("ACTIVE_CASHTOPUP_LOAN_FOUND");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NEW_LOAN_AMOUNT_NOT_SUFFICIENT:
      errorInfo.message = Strings("NEW_LOAN_AMOUNT_NOT_SUFFICIENT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.MULTIPLE_LOANS_CANT_BE_CONCILIATED:
      errorInfo.message = Strings("MULTIPLE_LOANS_CANT_BE_CONCILIATED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.LOAN_APPLICATION_FORM_NOT_FILLED:
      errorInfo.message = Strings("LOAN_APPLICATION_FORM_NOT_FILLED").replace(
        "$", (errorDetails.data));
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.AMOUNT_IS_NOT_VALID:
      errorInfo.message = Strings("AMOUNT_IS_NOT_VALID");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_DAYS:
      errorInfo.message = Strings("INVALID_DAYS");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.NOTE_INVALID_CONTEXT:
      errorInfo.message = Strings("NOTE_INVALID_CONTEXT");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.INVALID_PROVISION_TYPE:
      errorInfo.message = Strings("INVALID_PROVISION_TYPE");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.TERMS_ARE_REPEATED:
      errorInfo.message = Strings("TERMS_ARE_REPEATED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.DAYS_PASTDUE_ASCENDING_VERIFICATION_FAILED:
      errorInfo.message = Strings("DAYS_PASTDUE_ASCENDING_VERIFICATION_FAILED");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.OBSOLETE_PROVISION_TERM_CONFIGURATION:
      errorInfo.message = Strings("OBSOLETE_PROVISION_TERM_CONFIGURATION");
      errorInfo.title = Strings("Error");
      break;
    case API_ERROR_CODE.ABSOLUTE_CONFIGURATION:
      errorInfo.message = Strings("ABSOLUTE_CONFIGURATION");
      errorInfo.title = Strings("Error");
      break;
    default:
      errorInfo = defaultErrorInfo;
  }

  return errorInfo;
}
