import React from "react";
import { Col } from "react-bootstrap";
import _ from "lodash";

//Components
import SingleValuedConfiguration from "./SingleValuedConfiguration";
import MultiValuedConfiguration from "./MultiValuedCofiguration";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import UserNotification from "../CustomUIComponents/UserNotification/UserNotification";
import InputModal from "../CustomUIComponents/InputModal/InputModal";
import ConfigurationHeader from "../CustomUIComponents/ConfigurationHeader/ConfigurationHeader";
import ConfigurationButtons from "../CustomUIComponents/ConfigurationButtons/ConfigurationButtons";
import HolidayConfiguration from "./HolidayConfiguration";
import CreditScoreConfiguration from "./CreditScoreConfiguration";
import Ladder from "../MTPLadder/Ladder";
import AnalystConfiguration from "./AnalystConfiguration";
import RewardsConfiguration from "./RewardsConfiguration";
import EMIConfiguration from "./EMIConfiguration";
import LateFeesConfiguration from "./LateFeesConfiguration";
//Constants
import {
  SINGLE_VALUED_CONFIGURATION,
  CONFIG_ACTIONS,
  CONFIGURATION_STATUS,
  translateStringToStatusConstant,
  SCHEDULAR_CONSTANT,
  CONFIGURATION_TYPE,
  CONFIGURATION_DATA_TYPE,
  CONFIGURATION_SINGLE_KEY_TYPE,
  MODAL_TYPE,
  USER_ROLES,
} from "../../constants/appConstants";
import { DATA_TYPE } from "./Constants";
import { LOAN_TYPES } from "../../constants/appConstants";

// Strings
import { Strings } from "../../resources/i18n/i18n";

//Styles
import "./CustomConfiguration.scss";

//Utility
import Utility from "../../utils/Utility";
import PaymentFrequenciesConfiguration from "./PaymentFrequenciesConfig";
import LoanStatusAndGradeConfig from "./LoanStatusAndGradeConfig";

class CustomConfiguration extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      configuration: props.configurationData,
      editedConfiguration: props.configurationData,
      readOnly: true,
      showError: false,
      errorMessage: "",
      isValid: true,
      confirmationModalInformation: {
        show: false,
      },
      openModal: false,
      emptyRateConfigurationData: [],
      emptyTermConfigurationData: [],
      emptyLowerAmountConfigurationData: [],
      emptyUpperAmountConfigurationData: [],
      emptyLevelConfigurationData: [],
      noAscendingOrderDays: [],
      repeatedTerms: [],
      repeatedDaysPastDueErrors:[]
    };
    this.errorRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, PrevState) {
    if (
      PrevState.editedConfiguration.configurationObject !==
      nextProps.configurationData.configurationObject
    ) {
      return {
        editedConfiguration: nextProps.configurationData,
        confirmationModalInformation:
          nextProps.confirmationModalInformation,
      };
    }
    return null;
  }

  /* #endregion */

  /* #region  Methods */

  resetNotification = (callResetError = false) => {
    callResetError && this.resetError();
    this.props.resetNotification();
  };

  setError = (errorMessage) => {
    this.setState(
      { showError: true, isValid: false, errorMessage },
      () => {
        this.props.type !== CONFIGURATION_TYPE.EMI &&
          this.props.type !== CONFIGURATION_TYPE.LOAN_STATUS &&
          this.props.type !== CONFIGURATION_TYPE.COMMISSION_SCHEME &&
          this.props.type !== CONFIGURATION_TYPE.REWARDS &&
          this.props.type !== CONFIGURATION_TYPE.LOAN_STATUS_AND_GRADE &&
          this.errorRef.current.scrollIntoView();
      }
    );
  };

  resetError = (
    callback = () => {
      //NOSONAR
    }
  ) => {
    this.setState(
      {
        showError: false,
        isValid: true,
        errorMessage: "",
      },
      callback
    );
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.confirmationModalInformation.show}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={this.state.confirmationModalInformation.type}
        modalText1={this.state.confirmationModalInformation.text}
        modalButtonOk={{
          text: Strings("Set"),
          onClick:
            this.state.confirmationModalInformation.onClickOkCallback,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick:
            this.state.confirmationModalInformation
              .onClickCancelCallback,
        }}
        showHr={true}
      />
    );
  };

  closeConfirmationModal = () => {
    this.setState({
      confirmationModalInformation: {
        show: false,
      },
    });
  };

  /* #endregion */

  /* #region  Events */

  handleBackButtonClick = () => {
    this.props.handleBackButtonClick();
    this.closeConfirmationModal();
  };

  handleActivateConfiguration = () => {
    this.closeConfirmationModal();
    this.setState({ readOnly: true });
    this.props.handleActivateConfiguration();
  };

  prepareLoanStatusConfigurationData = () => {
    let configuration =
      this.state.editedConfiguration.configurationObject
        .configuration;
    configuration.sort((a, b) => {
      if (a.term === -1) return 1;
      if (b.term === -1) return -1;
      return a.term - b.term;
    });
    return configuration;
  };

  isAllConfigurationDataFilled = (
    configurationObject,
    checkType,
    configurationType = "loanConfig"
  ) => {
    let emptyRateArray = [];
    let emptyTermArray = [];
    let emptyLowerArray = [];
    let emptyUpperArray = [];
    let emptyLevelArray = [];
    if (configurationType === "loanConfig") {
      configurationObject.loanConfig.map(
        (loanConfig, loanConfigIdx) => {
          loanConfig.amountRange.map((amtRange, rangeIdx) => {
            if (checkType === "lower") {
              if (isNaN(amtRange.lower)) {
                emptyLowerArray.push({
                  rangeIndex: rangeIdx,
                  loanTypeIndex: loanConfigIdx,
                  freqIndex: 0,
                  termIndex: 0,
                });
              }
            } else {
              emptyLowerArray =
                this.state.emptyLowerAmountConfigurationData;
            }
            if (checkType === "upper") {
              if (isNaN(amtRange.upper)) {
                emptyUpperArray.push({
                  rangeIndex: rangeIdx,
                  loanTypeIndex: loanConfigIdx,
                  freqIndex: 0,
                  termIndex: 0,
                });
              }
            } else {
              emptyUpperArray =
                this.state.emptyUpperAmountConfigurationData;
            }
            amtRange.frequencies.map((freq, freqIdx) => {
              freq.terms.map((term, termIdx) => {
                if (checkType === "rate") {
                  if (term.rate === "" || term.rate === "0") {
                    emptyRateArray.push({
                      rangeIndex: rangeIdx,
                      loanTypeIndex: loanConfigIdx,
                      freqIndex: freqIdx,
                      termIndex: termIdx,
                    });
                  }
                } else {
                  emptyRateArray =
                    this.state.emptyRateConfigurationData;
                }
                if (checkType === "term") {
                  if (term.term === "" || term.term === "0") {
                    emptyTermArray.push({
                      rangeIndex: rangeIdx,
                      loanTypeIndex: loanConfigIdx,
                      freqIndex: freqIdx,
                      termIndex: termIdx,
                    });
                  }
                } else {
                  emptyTermArray =
                    this.state.emptyTermConfigurationData;
                }

                return null;
              });
              return null;
            });
            return null;
          });
          return null;
        }
      );
      this.setState({
        emptyRateConfigurationData: emptyRateArray,
        emptyTermConfigurationData: emptyTermArray,
        emptyLowerAmountConfigurationData: emptyLowerArray,
        emptyUpperAmountConfigurationData: emptyUpperArray,
      });
    } else if (configurationType === "levelConfig") {
      configurationObject.levelConfig.map(
        (levelConfig, levelConfigIdx) => {
          if (
            levelConfig.amount === "" ||
            levelConfig.amount === "0"
          ) {
            emptyLevelArray.push({
              rangeIndex: 0,
              loanTypeIndex: levelConfigIdx,
              freqIndex: 0,
              termIndex: 0,
            });
          }
          return null;
        }
      );
      this.setState({
        emptyLevelConfigurationData: emptyLevelArray,
      });
    }
    if (
      emptyRateArray.length ||
      emptyTermArray.length ||
      emptyLowerArray.length ||
      emptyUpperArray.length ||
      emptyLevelArray.length
    ) {
      this.setError(Strings("PleaseFillAllFields"));
      return false;
    } else return true;
  };
  isAllLevelConfigurationDataFilled = (configurationObject, checkType) => {
    let emptyLevelArray = [];
    let zeroTerm=false;
    if (checkType === "levelConfig") {
      configurationObject.levelConfig.forEach((levelConfig, levelConfigIdx) => {
        levelConfig.loanType.forEach((loan, loanTypeIndex) => {
          Object.keys(loan).filter(key => key !== 'type').forEach((sectionKey, sectionKeyIndex) => {
            Object.keys(loan[sectionKey]).forEach((subSectionKey, subSectionKeyIndex) => {
              const amount = loan[sectionKey][subSectionKey].minAmount;
              if (amount === "" || amount === "0") {
                emptyLevelArray.push({
                  levelConfigIdx: levelConfigIdx,
                  loanTypeIndex: loanTypeIndex,
                  sectionKeyIndex: sectionKeyIndex,
                  subSectionKeyIndex: subSectionKeyIndex,
                });
              }
            });
          });
        });
      });
    }
    if (checkType === "loanStatusAndGradeConfig") {
      configurationObject.levelConfig.forEach((frequency, frequencyIndex) => {
        frequency.termsConfig.forEach((termConfig, termIndex) => {
         if(termConfig.term === "" || termConfig.term === "0"){
          if(termConfig.term === "0"){
            zeroTerm=true;
          }
             emptyLevelArray.push({
               frequencyIndex: frequencyIndex,
               termIndex: termIndex,
               field: "term",
             });

         }
          

          termConfig.gradeConfig.forEach((gradeConfig, gradeIndex) => {
             (gradeConfig.daysPastDue === "") &&
              emptyLevelArray.push({
                frequencyIndex: frequencyIndex,
                termIndex: termIndex,
                gradeIndex: gradeIndex,
                field: "daysPastDue",
              });
            
          });
          
        });
      });
    }
    this.setState({
      emptyLevelConfigurationData: emptyLevelArray,
    });
    if (emptyLevelArray.length) {
      if(zeroTerm){
        this.setError(Strings("zeroTermError"));
      }else{

        this.setError(Strings("PleaseFillAllFields"));
      }
      return false;
    } else {
      return true;
    }
  };


  handleSaveAsDraftConfiguration = () => {
    //NOSONAR
    let configuration;
    let validData = true;
    if (
      SINGLE_VALUED_CONFIGURATION.includes(this.props.type) ||
      this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
    ) {
      configuration =
        this.state.editedConfiguration.configurationObject;
    } else if (
      this.props.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
      this.props.type === CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE
    ) {
      configuration =
        this.state.editedConfiguration.configurationObject;
      validData = this.props.validateTypeData();
    } else if (this.props.type === CONFIGURATION_TYPE.HOLIDAY) {
      configuration =
        this.state.editedConfiguration.configurationObject
          .holidayList;
    } else if (this.props.type === SCHEDULAR_CONSTANT.CI_DATA) {
      configuration =
        this.state.editedConfiguration.configurationObject.hasOwnProperty(
          "time"
        )
          ? this.state.editedConfiguration.configurationObject
          : {
            time: this.getSchedularLongTime(
              this.state.editedConfiguration.configurationObject
            ),
          };
    } else if (
      this.props.type === CONFIGURATION_TYPE.COMMISSION_SCHEME
    ) {
      configuration = {
        commissionData:
          this.state.editedConfiguration.configurationObject
            .commissionData,
        newCommissionData:
          this.state.editedConfiguration.configurationObject
            .newCommissionData,
      };
    } else if (this.props.type === CONFIGURATION_TYPE.LOAN_STATUS) {
      configuration = this.prepareLoanStatusConfigurationData();
    } else if (
      this.props.type === CONFIGURATION_TYPE.AUTO_ANALYSIS ||
      this.props.type === CONFIGURATION_TYPE.REWARDS ||
      this.props.type === CONFIGURATION_TYPE.LATE_FEES ||
      this.props.type === CONFIGURATION_TYPE.PAYMENT_FREQUENCY
    ) {
      configuration = {
        ...this.state.editedConfiguration.configurationObject,
      };
    } else if (this.props.type === CONFIGURATION_TYPE.EMI) {
      configuration =
        this.state.editedConfiguration.configurationObject;
      configuration.levelConfig = [];
    }
    else if (this.props.type === CONFIGURATION_TYPE.PROVISION) {
      configuration =
        this.state.editedConfiguration.configurationObject;
      configuration.configuration = undefined;
      configuration.terms=[];
    }
    else {
      configuration = this.getCustomizedObject(
        this.state.editedConfiguration.configurationObject
          .configuration
      );
    }

    let requestData = {
      version: this.state.editedConfiguration.version,
      description: this.state.editedConfiguration.description,
      configuration: configuration,
      type: this.props.type,
    };
    if (
      this.state.editedConfiguration.state !==
      CONFIGURATION_STATUS.drafted
    ) {
      this.resetError();
      validData && this.handleSaveAsDraftCreateConfig(requestData);
    } else {
      if (configuration) {
        this.resetError();
        validData && this.handleSaveAsDraftUpdateConfig(requestData);
      }
    }
    if (!validData) {
      this.closeConfirmationModal();
      this.setError(Strings("DataIsNotValid"));
    } else {
      this.setState({
        isValid: true,
      });
    }
  };

  handleSaveAsDraftCreateConfig = (requestData) => {
    this.closeConfirmationModal();
    this.props.handleSaveAsDraftCreateConfig(requestData);
  };

  handleSaveAsDraftUpdateConfig = (requestData) => {
    this.closeConfirmationModal();
    this.props.handleSaveAsDraftUpdateConfig(requestData);
  };

  validateDataType = (
    range,
    errorForRangeOverlap,
    daysArrears = false,
    isFastCash = false //isFastCash special fast cash condition ( Do not check lower value of amount table for 1st row)
  ) => {
    let allValid = true,
      errorMsg = "";
    //Validations if number overlap
    if (Utility.checkIfRangeOverlap(range)) {
      allValid = false;
      errorMsg = errorForRangeOverlap;
    }

    //Validation if Upper range is less than Lower range

    if (!Utility.checkIfRangeValid(range, isFastCash)) {
      allValid = false;
      errorMsg = daysArrears
        ? Strings("ErrorDaysLowerMustBeLessThanDaysUpper")
        : Strings("EMIAmountRangeInvalid");
    }

    if (
      !Utility.checkIfContainsZero(range, daysArrears, isFastCash)
    ) {
      // isFastCash special fast cash condition ( Do not check lower value of amount table for 1st row)
      allValid = false;
      errorMsg = daysArrears
        ? Strings("ErrorDaysRangeShouldStartWithZero")
        : Strings("ErrorDaysRangeShouldStartWithOne");
    }
    //Validation if ranges are not continuous
    if (!Utility.checkIfRangeIsContinuous(range)) {
      allValid = false;
      errorMsg = daysArrears
        ? Strings("ErrorInvalidArrearDaysRange")
        : Strings("EMIAmountRangeInvalid");
    }

    if (!allValid) {
      this.setError(errorMsg);
    } else {
      this.resetError();
    }
    return allValid;
  };

  validationsForProvision = (dataRows, termsArray, tableNumber) => {
    if (this.props.type === CONFIGURATION_TYPE.PROVISION) {
      if (tableNumber !== 1) {
        for (let i of dataRows) {
          this.isColateralPercentageValid(i.percentWithoutCollateral);
          this.isColateralPercentageValid(i.percentWithCollateral);
        }
      } else if (Utility.checkIfDuplicateEntryInArray(termsArray)) {
        this.setError(Strings("duplicateTerms"));
      }
    }
  };

  //isFastCash special fast cash condition ( Do not check lower value of amount table for 1st row)
  validationsForEMI = (configurationData) => {
    //NOSONAR
    for (let dataRows of configurationData) {
      for (let dataRow of dataRows.dataRows) {
        if (dataRow.terms.length === 0) {
          this.setError(Strings("invalidTerms"));
          return;
        } else if (dataRow.terms.length > 0) {
          for (let term of dataRow.terms) {
            if (term.toString().length > 3 || term === 0) {
              this.setError(Strings("invalidTerms"));
              return;
            } else if (
              Utility.checkIfDuplicateEntryInArray(dataRow.terms)
            ) {
              this.setError(Strings("duplicateTerms"));
              return;
            } else if (!this.isPercentageValid(dataRow.rate)) {
              return;
            } else if (
              !this.validateDataType(
                dataRows.dataRows?.map((row) => ({
                  lower: parseInt(row?.lower),
                  upper: parseInt(row?.upper),
                })),
                Strings("EMIAmountRangeInvalid"),
                false,
                dataRows.isFastCash //isFastCash special fast cash condition ( Do not check lower value of amount table for 1st row)
              )
            ) {
              return;
            }
          }
        }
      }
    }
  };

  validationsForPaymentDiscount = (dataRows) => {
    if (this.props.type === CONFIGURATION_TYPE.PAYMENT_DISCOUNT) {
      for (let i of dataRows) {
        if (!this.isPercentageValid(i.discount)) {
          break;
        }
      }
    }
  };

  validationsForCommissionScheme = (dataRows) => {
    if (this.props.type === CONFIGURATION_TYPE.COMMISSION_SCHEME) {
      for (let i of dataRows) {
        if (!this.isPercentageValid(i.percentage)) {
          return false;
        }
      }
    }
    return true;
  };

  validSalesModCommissionScheme = (commission) => {
    let isValid = true;
    if (
      _.find(commission.data, (rangeObject) => {
        return (
          rangeObject.start >= rangeObject.end &&
          rangeObject.end !== -1
        );
      })
    ) {

      this.setError(
        Strings("errorStartValueShouldBeLessThanEndValue")
      );
    } else if (
      !Utility.checkIfCreditScoreRangeIsContinuous(commission.data)
    ) {

      this.setError(Strings("invalidRange"));
    } else {
      if (this.validationsForCommissionScheme(commission.data))
        this.resetError();
    }
    return isValid;
  };

  validationsForPeriod = (dataRows) => {
    if (dataRows[0].period === CONFIGURATION_DATA_TYPE.PERIOD) {
      this.setError(Strings("PleaseSelectValidPeriod"));
    } else if (dataRows[0].hours === 0) {
      this.setError(Strings("hourMustBeNonZero"));
    } else {
      this.resetError();
    }
  };

  validationsForCreditlimit = (
    levelName,
    dataRows,
    pdIndex,
    errorMessage = ""
  ) => {
    let keys = Object.keys(dataRows.lder);
    let values = Object.values(dataRows.lder);
    let index = keys.findIndex((level) => level === levelName);
    values.forEach((level, lIndex) => {
      if (errorMessage) {
        this.setError(errorMessage);
      } else if (
        pdIndex !== false &&
        (!parseInt(dataRows.lder[levelName].pd[pdIndex].maxla) ||
          !parseInt(dataRows.lder[levelName].pd[pdIndex].minla) ||
          !dataRows.lder[levelName].pd[pdIndex].ln)
      ) {
        this.setError(Strings("ErrorEmptyValue"));
      } else if (lIndex > index) {
        if (dataRows.lder[levelName]["cl"] >= level.cl)
          this.setError(Strings("HigherCreditLimitErroMessage"));
      } else if (lIndex < index) {
        if (dataRows.lder[levelName]["cl"] <= level.cl)
          this.setError(Strings("LowerCreditLimitErrorMessage"));
      } else if (!dataRows.lder[levelName]["cl"]) {
        this.setError(Strings("ErrorEmptyValue"));
      } else if (
        pdIndex !== false &&
        dataRows.lder[levelName].pd[pdIndex].ln !==
        LOAN_TYPES.FAST_CASH &&
        dataRows.lder[levelName].pd[pdIndex].ln !==
        LOAN_TYPES.FACTORING &&
        parseInt(dataRows.lder[levelName].pd[pdIndex].minla) >=
        parseInt(dataRows.lder[levelName].pd[pdIndex].maxla)
      ) {
        this.setError(Strings("minimumLoanAmountWarning"));
      } else if (
        pdIndex !== false &&
        dataRows.lder[levelName].pd[pdIndex].ln !==
        LOAN_TYPES.FACTORING &&
        dataRows.lder[levelName].pd[pdIndex].maxla > level.cl
      ) {
        this.setError(Strings("maximumLoanAmountWarning"));
      }
      else if (!this.isPercentageValid(level.renewalCriteria)) {
        this.setError(Strings("InvalidPercentage"));
      }
    });
  };

  analystConfigurationValidation = (configurationData) => {
    if (
      configurationData.grade.length === 0 &&
      configurationData.type.length === 0
    ) {
      this.setError(Strings("pleaseSelectGradeType"));
    } else if (configurationData.grade.length === 0) {
      this.setError(Strings("pleaseSelectGrade"));
    } else if (configurationData.type.length === 0) {
      this.setError(Strings("pleaseSelectType"));
    } else {
      this.resetError();
    }
  };

  validationsForAdministrativeExpense = (dataRows) => {
    if (!Utility.checkIfContainsZero(dataRows)) {
      this.setError(
        Strings("ErrorDaysRangeShouldStartWithOne")
      );
    } else if (
      _.find(dataRows, (rangeObject) => {
        return (
          rangeObject.lower >= rangeObject.upper &&
          rangeObject.upper !== -1
        );
      })
    ) {
      this.setError(
        Strings("errorStartValueShouldBeLessThanEndValue")
      );
    } else if (
      !Utility.checkIfRangeIsContinuousForInfiniteEnd(dataRows)
    ) {
      this.setError(Strings("invalidRange"));
    } else {
      this.resetError();
    }
  }
  isLateFeesConfigurationValid = (configurationData) => {
    let isValue = true;

    // Check if any key is empty, considering the toggles
    if (
      (configurationData.isCalculationFromDayActive && (configurationData.startCalculatationFromDay === null || isNaN(configurationData.startCalculatationFromDay))) ||
      (configurationData.isMaxCapitalPercentageActive && (configurationData.maxCapitalPercentage === null || isNaN(configurationData.maxCapitalPercentage)))
    ) {
      this.setError(Strings("fieldsCannotBeEmpty"));
      isValue = false;
    } else if (
      (!Utility.checkIfPercentageValid([configurationData.lateFeesPercent])) ||
      (configurationData.isMaxCapitalPercentageActive && !Utility.checkIfPercentageValid([configurationData.maxCapitalPercentage]))
    ) {
      this.setError(Strings("InvalidPercentage"));
      isValue = false;
    } else if (configurationData.isCalculationFromDayActive && !Utility.checkPossitiveCredoDays(configurationData.startCalculatationFromDay)) {
      this.setError(Strings("invalidDays"));
      isValue = false;
    } else {
      this.resetError();
    }
    return isValue;
  }
  // validation and save edited data in the state
  saveEditedDataInTheState = (
    configurationData,
    dataType = false,
    dataRows = false,
    cellValue = false,
    index = false,
    isNewCommission = false,
    indexList = null,
    editedProvisionTableNumber = 0,
    errorMessage = ""
  ) => {
    switch (dataType) {
      case DATA_TYPE.FLOAT:
        this.isPercentageValid(cellValue);
        break;
      case DATA_TYPE.INT:
        this.isIntegerValid(cellValue, this.props.type);
        break;
      case CONFIGURATION_DATA_TYPE.LOAN_STATUS:
        this.validationsForLoanStatus(dataRows);
        break;
      case CONFIGURATION_DATA_TYPE.DAY_RANGE:
        this.resetError();

        let termsArray = configurationData.map((term) =>
          parseInt(term.term)
        );

        if (this.props.type !== CONFIGURATION_TYPE.PROVISION) {
          this.isDaysRangeValid(configurationData);
        }
        this.validationsForProvision(
          configurationData,
          termsArray,
          editedProvisionTableNumber
        );
        break;
      case CONFIGURATION_DATA_TYPE.EMI:
        this.resetError();
        //isFastCash special fast cash condition ( Do not check lower value of amount table for 1st row)
        for (
          let i = 0;
          i <
          this.state.editedConfiguration.configurationObject
            .configuration.length;
          i++
        ) {
          dataRows[i].isFastCash =
            this.state.editedConfiguration.configurationObject
              .configuration[i].loanType === "FAST_CASH";
        }
        this.validationsForEMI(dataRows);
        break;
      case CONFIGURATION_DATA_TYPE.COMMISSION_SCHEME:
        if (isNewCommission) {
          this.validSalesModCommissionScheme(
            configurationData[indexList.userIndex].commission[
            indexList.commissionIndex
            ]
          );
        } else {
          this.validationsForCommissionScheme(dataRows);
        }
        break;
      case CONFIGURATION_DATA_TYPE.PERIOD:
        this.validationsForPeriod(dataRows);
        break;
      case CONFIGURATION_DATA_TYPE.ADMINISTRATIVE_EXPENSE_DATA:
        this.validationsForAdministrativeExpense(dataRows);
        break;
      case CONFIGURATION_DATA_TYPE.PAYMENT_DISCOUNT:
        this.validationsForPaymentDiscount(dataRows);
        break;
      case CONFIGURATION_DATA_TYPE.LEVEL:
        this.resetError();
        this.validationsForCreditlimit(
          configurationData,
          dataRows,
          index,
          errorMessage
        );
        break;
      case CONFIGURATION_DATA_TYPE.REWARDS:
        if (isNewCommission) {
          this.validSalesModCommissionScheme(
            configurationData.data[indexList.loanTypeIndex]
          );
        } else {
          if (Object.keys(configurationData).includes("rewards")) {
            if (configurationData.rewards < 1) {
              this.setError(Strings("DataIsNotValid"));
            } else {
              this.resetError();
            }
          } else if (configurationData.expiry < 1) {
            this.setError(Strings("expiryError"));
          } else {
            this.resetError(() => {
              let loanList =
                this.state.editedConfiguration.configurationObject
                  .loanRewards.data;

              //for (let i = 0; i < loanList.length; i++)
              for (const loan of loanList) {
                //NOSONAR
                if (!this.validSalesModCommissionScheme(loan)) {
                  break;
                }
              }
            });
          }
        }
        break;
      default:
        break;
    }

    let editedConfiguration = this.state.editedConfiguration;
    if (
      this.props.type === SCHEDULAR_CONSTANT.CI_DATA ||
      SINGLE_VALUED_CONFIGURATION.includes(this.props.type)
    )
      editedConfiguration.configurationObject = configurationData[0];
    else if (this.props.type === CONFIGURATION_TYPE.HOLIDAY)
      editedConfiguration.configurationObject.holidayList =
        configurationData;
    else if (this.props.type === CONFIGURATION_TYPE.EMI) {
      editedConfiguration.configurationObject.configuration[
        index
      ].emiConfig = configurationData;
    } else if (this.props.type === CONFIGURATION_TYPE.LOAN_STATUS) {
      let data = [];
      dataRows.forEach((rows) => {
        data.push(rows.dataRows);
        editedConfiguration.configurationObject.configuration = data;
      });
    } else if (
      this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
    ) {
      editedConfiguration.configurationObject = dataRows;
    } else if (this.props.type === CONFIGURATION_TYPE.AUTO_ANALYSIS) {
      editedConfiguration.configurationObject.grade = [
        ...configurationData.grade,
      ];
      editedConfiguration.configurationObject.type = [
        ...configurationData.type,
      ];
      editedConfiguration.configurationObject.minLevel =
        configurationData.minLevel;
      editedConfiguration.configurationObject.moreThanlargestAmountPercentage =
        configurationData.moreThanlargestAmountPercentage;
      editedConfiguration.configurationObject.loanAmount =
        configurationData.loanAmount;
      editedConfiguration.configurationObject.minCredoScore =
        configurationData.minCredoScore;
      editedConfiguration.configurationObject.stopAtOperationsAmount =
        configurationData.stopAtOperationsAmount;
      this.analystConfigurationValidation(configurationData);
    } else if (this.props.type === CONFIGURATION_TYPE.LATE_FEES) {
      editedConfiguration.configurationObject.lateFeesPercent =
        configurationData.lateFeesPercent;
      editedConfiguration.configurationObject.startCalculatationFromDay =
        configurationData.startCalculatationFromDay;
      editedConfiguration.configurationObject.maxCapitalPercentage =
        configurationData.maxCapitalPercentage;
      editedConfiguration.configurationObject.isCalculationFromDayActive =
        configurationData.isCalculationFromDayActive === null ? false : configurationData.isCalculationFromDayActive;
      editedConfiguration.configurationObject.isMaxCapitalPercentageActive =
        configurationData.isMaxCapitalPercentageActive === null ? false : configurationData.isMaxCapitalPercentageActive;
      this.isLateFeesConfigurationValid(configurationData);
    } else if (
      this.props.type === CONFIGURATION_TYPE.COMMISSION_SCHEME
    ) {
      if (isNewCommission) {
        editedConfiguration.configurationObject.newCommissionData =
          configurationData;
      } else {
        editedConfiguration.configurationObject.configuration =
          configurationData;
      }
    } else if (this.props.type === CONFIGURATION_TYPE.REWARDS) {
      if (Object.keys(configurationData).includes("rewards")) {
        editedConfiguration.configurationObject.referralRewards =
          configurationData;
      } else {
        editedConfiguration.configurationObject.loanRewards =
          configurationData;
      }
    } else if (this.props.type === CONFIGURATION_TYPE.PROVISION) {
      const provision = ["A", "B", "C", "D1", "D2", "E"];
      if (editedProvisionTableNumber === 1) {
        editedConfiguration.configurationObject.terms =
          configurationData.map((obj) => {
            let result = {
              term: obj.term === "Other" ? "-1" : obj.term,
            };
            result["provisions"] = provision.map((pro) => {
              return {
                provisionType: pro,
                installmentDelayedBy: obj[pro],
              };
            });
            return result;
          });
      } else {
        editedConfiguration.configurationObject.amounts =
          configurationData;
        editedConfiguration.configurationObject.configuration = null;
      }
    }
    else {

      editedConfiguration["configurationObject"].configuration = [
        ...configurationData,
      ];
    }
    this.setState(
      { editedConfiguration: editedConfiguration },
      () => {
        if (
          this.props.type === CONFIGURATION_TYPE.PROVISION
          || this.props.type === CONFIGURATION_TYPE.EMI
          || this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA
        ) {
          this.state.editedConfiguration.configurationObject &&
            this.handleAddRow();
        }
      }
    );
  };

  handleAddRow = () => {
    this.props.handleAddRow(
      this.state.editedConfiguration.configurationObject
    );
  };

  getCustomizedObject = (configurationDataobj) => {
    let configValueArray = [];
    if (!Array.isArray(configurationDataobj)) {
      configValueArray.push(configurationDataobj);
    } else {
      configValueArray = configurationDataobj;
    }
    let configurationObject = [];
    configValueArray.forEach((configurationEle) => {
      let tempObj = {};
      let configKeys = Object.keys(configurationEle);
      configKeys.forEach((singleKey) => {
        if (
          singleKey ===
          CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_LOWER
        ) {
          Object.assign(tempObj, {
            daysArrears: {
              lower: configurationEle[singleKey],
              upper:
                configurationEle[
                CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_UPPER
                ],
            },
          });
        } else if (
          singleKey === CONFIGURATION_SINGLE_KEY_TYPE.STATUS
        ) {
          Object.assign(tempObj, {
            status: translateStringToStatusConstant(
              configurationEle[CONFIGURATION_SINGLE_KEY_TYPE.STATUS]
            ),
          });
        } else if (
          singleKey !==
          CONFIGURATION_SINGLE_KEY_TYPE.DAYS_ARREARS_UPPER
        ) {
          Object.assign(tempObj, {
            [singleKey]: configurationEle[singleKey],
          });
        }
      });
      configurationObject.push(tempObj);
    });
    return configurationObject;
  };

  getSchedularLongTime = (timeConfiguration) => {
    let date = new Date();
    date.setHours(
      timeConfiguration.period === "PM"
        ? timeConfiguration.hours + 12
        : timeConfiguration.hours
    );
    date.setMinutes(timeConfiguration.minutes);
    return Utility.convertDateIntoMiliSecondsForScheduler(date);
  };

  handleResetData = () => {
    this.props.handleResetData();
    this.closeConfirmationModal();
    this.resetError();
  };

  handleDeleteDraftedConfiguration = () => {
    this.closeConfirmationModal();
    this.props.handleDeleteDraftedConfiguration();
  };

  handleChangeVersion = (key, version, description) => {
    let editedConfiguration = this.state.editedConfiguration;
    if (key === MODAL_TYPE.VERSION) {
      editedConfiguration.version = version;
      editedConfiguration.description = description;
      this.setState({
        editedConfiguration,
      });
    }
  };

  // button click actions for all the buttons on configurations
  handleOnClickAction = (
    configAction,
    typeIndex = null,
    subTypeIndex = null
  ) => {
    switch (configAction) {
      case CONFIG_ACTIONS.goback:
        this.resetNotification();
        this.setState({
          confirmationModalInformation: {
            type: Strings("Back"),
            show: true,
            text: Strings("BackToTheVersionList"),
            onClickOkCallback: this.handleBackButtonClick,
            onClickCancelCallback: this.closeConfirmationModal,
          },
        });
        break;

      case CONFIG_ACTIONS.activate:
        this.resetNotification();
        this.setState({
          confirmationModalInformation: {
            type: Strings("Activate"),
            show: true,
            text: Strings("ActivateConfirmationMessage"),
            onClickOkCallback: this.handleActivateConfiguration,
            onClickCancelCallback: this.closeConfirmationModal,
          },
        });
        break;

      case CONFIG_ACTIONS.saveAsDraft:
        this.props.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
          this.props.type === CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE
          ? this.resetNotification(true)
          : this.resetNotification();
        if (this.state.isValid) {
          this.setState({
            confirmationModalInformation: {
              type: Strings("SaveAsDraft"),
              show: true,
              text: Strings("SaveAsDraftConfirmationMessage"),
              onClickOkCallback: this.handleSaveAsDraftConfiguration,
              onClickCancelCallback: this.closeConfirmationModal,
            },
          });
        }
        break;

      case CONFIG_ACTIONS.reset:
        this.resetNotification();
        this.setState({
          confirmationModalInformation: {
            type: Strings("Reset"),
            show: true,
            text: Strings("ResetConfirmationMessage"),
            onClickOkCallback: this.handleResetData,
            onClickCancelCallback: this.closeConfirmationModal,
          },
        });
        break;

      case CONFIG_ACTIONS.deleteDraft:
        this.resetNotification();
        this.setState({
          confirmationModalInformation: {
            type: Strings("DeleteDraft"),
            show: true,
            text: Strings("DeleteDraftConfirmationMessage"),
            onClickOkCallback: this.handleDeleteDraftedConfiguration,
            onClickCancelCallback: this.closeConfirmationModal,
          },
        });
        break;

      default:
        this.resetNotification();
        break;
    }
  };

  handleEditClick = () => {
    this.setState({
      readOnly: false,
    });
  };
  /* #endregion */

  /* #region  Validations */

  isPercentageValid = (percentage) => {
    if (!Utility.checkIfPercentageValid([percentage])) {
      this.setError(Strings("InvalidPercentage"));
      return false;
    } else {
      this.resetError();
      return true;
    }
  };

  isColateralPercentageValid = (percentage) => {
    if (!Utility.checkIfPercentageValid([percentage])) {
      this.setError(Strings("InvalidPercentage"));
    }
  };

  isIntegerValid = (intVal, type) => {
    if (type === CONFIGURATION_TYPE.CREDO_CONFIG) {
      if (!Utility.checkPossitiveCredoDays(intVal)) {
        this.setError(Strings("InvalidIntegerValue"));
        return false;
      } else {
        this.resetError();
        return true;
      }
    }
    else {
      if (!Utility.checkIfPostiveInteger(intVal)) {
        this.setError(Strings("InvalidIntegerValue"));
        return false;
      } else {
        this.resetError();
        return true;
      }
    }
  };

  isDaysRangeValid = (configurationData) => {
    let dataRows = configurationData ? configurationData : null;
    if (dataRows) {
      //Invalid Range Error
      let daysArrears = dataRows.map((row) => ({
        lower: parseInt(row.daysArrears_lower),
        upper: parseInt(row.daysArrears_upper),
      }));

      this.validateDataType(
        daysArrears,
        Strings("DaysArrearsRangeInvalid"),
        true
      );
    }
  };

  validationsForLoanStatus = (dataRows) => {
    let terms = [];
    for (let dataRow of dataRows) {
      let data = dataRow.dataRows;
      terms.push(parseInt(data.term));
      if (Utility.checkIfDuplicateEntryInArray(terms)) {
        this.setError(Strings("duplicateTerms"));
        return;
      }
      if (terms.length === 0) {
        this.setError(Strings("invalidTerms"));
        return;
      } else if (terms.length > 0) {
        for (let term of terms) {
          if (!term) {
            this.setError(Strings("invalidTerms"));
            return;
          }
        }
      }
      if (
        !this.validateDataType(
          data.states.map((row) => ({
            lower: parseInt(row.daysArrears.lower),
            upper: parseInt(row.daysArrears.upper),
          })),
          Strings("DaysArrearsRangeInvalid"),
          true
        )
      ) {
        return;
      }
    }
  };

  getCombinedRating = () => {
    let total = 0;
    if (
      this.state.configuration &&
      (this.props.type === CONFIGURATION_TYPE.CREDIT_SCORE ||
        this.props.type === CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE)
    ) {
      this.state.configuration.configurationObject.type.map(
        (item) => {
          total += item.weight;
          return null;
        }
      );
    }
    return total;
  };

  showModal = (message, value, type, modalSubTypeIndex = null) => {
    this.setState(
      {
        modalHeader: message,
        modalValue: value,
        modalType: type,
        modalSubTypeIndex,
      },
      () => {
        this.toggleModal();
        this.props.resetNotification();
      }
    );
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleChangeValue = (key, value) => {
    if (this.state.modalType === MODAL_TYPE.ADD_TYPE) {
      this.props.handleAddType(value);
    } else {
      this.props.resetNotification(true);
      this.props.handleChangeValue(
        null,
        this.state.modalSubTypeIndex,
        key,
        value
      );
    }
    this.toggleModal();
  };

  renderHeader = () => {
    const total = this.getCombinedRating();

    let headerData;

    if (this.props.configurationData !== null) {
      headerData = {
        state: this.props.configurationData.state,
        version: this.props.configurationData.version,
        description: this.props.configurationData.description,
        user: this.props.configurationData.user,
        lastModified: this.props.configurationData.lastModified,
      };
    }
    return (
      <span>
        {this.state.notificationFailure && (
          <UserNotification
            userNotificationObj={{
              message: this.state.errorMessage,
            }}
          />
        )}
        {this.state.notificationSuccess && (
          <UserNotification
            userNotificationObj={{
              message: this.state.successMessage,
              level: "success",
            }}
          />
        )}
        {this.state.configuration && (
          <ConfigurationHeader
            headerText={Strings(this.props.type)}
            headerData={headerData}
            readOnly={this.state.readOnly}
            total={total}
            type={this.props.type}
            //methods
            handleEditClick={this.handleEditClick}
            handleChangeValue={this.handleChangeVersion}
            showModal={this.showModal}
            noButtons={
              this.props.loggedInUserInfo.role !==
              USER_ROLES.configurator &&
              this.props.type ===
              CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
            }
            disableEditButton={this.state.editedConfiguration?.isObsolete}
          />
        )}
      </span>
    );
  };

  renderVersionChangeModal = () => {
    return (
      <InputModal
        open={this.state.openModal}
        type={this.state.modalType}
        modalHeader={this.state.modalHeader}
        modalValue={this.state.modalValue}
        //methods
        toggleModal={this.toggleModal}
        handleChangeValue={this.handleChangeValue}
        handleChangeVersion={this.props.handleChangeVersion}
      />
    );
  };

  renderConfigurationButtons = () => {
    return (
      <ConfigurationButtons
        readOnly={this.state.readOnly}
        state={this.props?.configurationData?.state}
        type={this.props.type}
        isValid={this.state.isValid}
        //methods
        handleOnClickAction={this.handleOnClickAction}
        noButtons={
          this.props.loggedInUserInfo.role !==
          USER_ROLES.configurator &&
          this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
        }
      />
    );
  };

  renderSingleValuedConfiguration = () => {
    return (
      <SingleValuedConfiguration
        type={this.props.type}
        readOnly={this.state.readOnly}
        showError={this.state.showError}
        errorMessage={this.state.errorMessage}
        dataForTable={this.props.dataForTable}
        editedConfiguration={this.state.editedConfiguration}
        isValid={this.state.isValid}
        //methods
        errorRef={this.errorRef}
        renderDataForDataTable={this.props.renderDataForDataTable}
        handleEditClick={this.handleEditClick}
        handleChangeValue={this.handleChangeVersion}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
        handleOnClickAction={this.handleOnClickAction}
        resetNotification={this.resetNotification}
      />
    );
  };

  renderHolidayConfiguration = () => {
    return (
      <HolidayConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={this.props.configurationData}
        //methods
        saveEditedDataInTheState={this.saveEditedDataInTheState}
      />
    );
  };

  renderAnalystConfiguration = () => {
    return (
      <AnalystConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={this.props.configurationData}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
      />
    );
  };
  renderLateFeesConfiguration = () => {
    return (
      <LateFeesConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={this.props.configurationData}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
      />
    )
  };
  renderRewardsConfiguration = () => {
    return (
      <RewardsConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={this.props.configurationData}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
      />
    );
  };

  renderMultiValuedConfiguration = () => {
    return (
      <MultiValuedConfiguration
        type={this.props.type}
        readOnly={this.state.readOnly}
        showError={this.state.showError}
        errorMessage={this.state.errorMessage}
        dataForTable={this.props.dataForTable}
        dataForTable2={this.props.dataForTable2}
        editedConfiguration={this.state.editedConfiguration}
        isValid={this.state.isValid}
        //methods
        errorRef={this.errorRef}
        renderDataForDataTable={this.props.renderDataForDataTable}
        handleEditClick={this.handleEditClick}
        handleChangeValue={this.handleChangeVersion}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
        handleOnClickAction={this.handleOnClickAction}
        resetNotification={this.resetNotification}
        setError={this.setError}
      />
    );
  };

  renderCreditScoreConfiguration = () => {
    return (
      <CreditScoreConfiguration
        type={this.props.type}
        configurationData={this.props.configurationData}
        readOnly={this.state.readOnly}
        creditscoreMapping={this.props.creditscoreMapping}
        editSubtypeData={this.props.editSubtypeData}
        isValid={this.state.isValid}
        errorMessage={this.state.errorMessage}
        //methods
        errorRef={this.errorRef}
        handleChangeValue={this.props.handleChangeValue}
        resetError={this.resetError}
        handleEditClick={this.handleEditClick}
        handleChangeVersion={this.handleChangeVersion}
        handleOnClickAction={this.handleOnClickAction}
        handleAddType={this.props.handleAddType}
        resetNotification={this.resetNotification}
        saveEditedDataInTheState={this.saveEditedDataInTheState}
      />
    );
  };

  handleEMILoanValueChange = (loanConfig) => {
    //NOSONAR
    let editedConfiguration = this.state.editedConfiguration;
    let isValidTerms = true;
    let isValidAmount = true;
    loanConfig.forEach((loan) => {
      loan.amountRange.forEach((amountRange, index) => {
        if (
          (loan.loanType === "FAST_CASH" &&
            index === 0 &&
            parseInt(amountRange.lower) > 0 &&
            parseInt(amountRange.lower) ===
            parseInt(amountRange.upper)) ||
          (parseInt(amountRange.lower) > 0 &&
            parseInt(amountRange.lower) < parseInt(amountRange.upper))
        ) {
          if (index !== loan.amountRange.length - 1) {
            if (
              parseInt(amountRange.upper) ===
              parseInt(loan.amountRange[index + 1].lower) - 1
            ) {
              amountRange.frequencies.forEach((frequency) => {
                let terms = frequency.terms.map((termDetails) => {
                  return parseInt(termDetails.term);
                });
                let isDuplicate = terms.some((item, index) => {
                  return terms.indexOf(item) !== index;
                });
                if (isDuplicate) {
                  isValidTerms = false;
                }
              });
            } else {
              isValidAmount = false;
            }
          }
        } else {
          isValidAmount = false;
        }
      });
    });
    if (isValidAmount && isValidTerms) {
      this.resetError();
      editedConfiguration.configurationObject.loanConfig = loanConfig;
      this.setState({
        editedConfiguration: editedConfiguration,
      });
    } else if (!isValidAmount) {
      this.setError(Strings("EMIAmountRangeInvalid"));
    } else {
      this.setError(Strings("PleaseFillAllFields"));
    }
  };

  handleLevelConfigChange = (levelConfig) => {
    let editedConfiguration = this.state.editedConfiguration;
    let isFreqSelected = true;

    levelConfig.forEach(levelDetails => {

      levelDetails.loanType.forEach(loanTypeObject => {
        Object.keys(loanTypeObject).forEach(sectionKey => {
          let section = loanTypeObject[sectionKey];
          Object.keys(section).forEach(subSectionKey => {
            let subSection = section[subSectionKey];
            if (subSection && Array.isArray(subSection.frequencies) && subSection.frequencies.length === 0) {
              isFreqSelected = false;
              this.setError(Strings("FREQ_ERROR"));
            }
          });
        });
      });
    });

    if (isFreqSelected) {
      this.resetError();
      editedConfiguration.configurationObject.levelConfig = levelConfig;
      this.setState({
        editedConfiguration: editedConfiguration,
      });
    }
  };
  getRepeatingDaysPastDueIndices = (gradeConfigs) => {
    const occurrences = {};
    const repeatedDaysPastDueIndices = [];
    gradeConfigs.forEach((gradeConfig, index) => {
      const daysPastDue = gradeConfig.daysPastDue;
      if (daysPastDue === "" || daysPastDue === null || daysPastDue === undefined) {
        return;
      }
      if (occurrences[daysPastDue] === undefined) {
        occurrences[daysPastDue] = [index]; 
      } else {
        occurrences[daysPastDue].push(index); 
      }
    });
    for (const daysPastDue in occurrences) {
      if (occurrences[daysPastDue].length > 1) {
        repeatedDaysPastDueIndices.push(occurrences[daysPastDue]);
      }
    }
  
    return repeatedDaysPastDueIndices;
  };
  
  
getRepeatingIndices = (arr) => {
  const occurrences = {}; 
  const repeatingIndices = [];
  arr.forEach((item, index) => {
    if (occurrences[item] === undefined) {
      occurrences[item] = [index]; 
    } else {
      occurrences[item].push(index); 
    }
  });
  for (const item in occurrences) {
    if (occurrences[item].length > 1) {
      repeatingIndices.push(occurrences[item]);
    }
  }

  return repeatingIndices;
};

// function to check if the terms are unique for each frequency
 areTermsUniqueForFrequency = (levelConfig) => {
  const result = []; 

  levelConfig.forEach((frequencyConfig, frequencyIndex) => {
    const termValues = frequencyConfig.termsConfig.map((termConfig) => termConfig.term); // Extract the terms for each frequency
    const repeatedTermIndices = this.getRepeatingIndices(termValues); // Get repeated term indices
    if (repeatedTermIndices.length > 0) {
      result.push({
        frequencyIndex: frequencyIndex,
        repeatedTermIndices: repeatedTermIndices
      });
    }
  });
this.setState({ repeatedTerms: result });
if(result.length>0){

  this.setError(Strings("repeatedTerms"));
}
};
// function to check repeated daysPastDue
checkRepeatedDaysPastDue = (levelConfig) => {
  const result = []; 
  levelConfig.forEach((frequencyConfig, frequencyIndex) => {
    frequencyConfig.termsConfig.forEach((termConfig, termIndex) => {
      const repeatedDaysPastDueIndices = this.getRepeatingDaysPastDueIndices(termConfig.gradeConfig);
      if (repeatedDaysPastDueIndices.length > 0) {
        result.push({
          frequencyIndex: frequencyIndex,
          termIndex: termIndex,
          repeatedDaysPastDueIndices: repeatedDaysPastDueIndices
        });
      }
    });
  });
  this.setState({ repeatedDaysPastDueErrors: result });
  if (result.length > 0) {
    this.setError(Strings("repeatedDaysPastDue"));
  }
  return result;
};


//function to check if the days past due are in ascending order
areDaysPastDueInAscendingOrder = (levelConfig) => {
  let errors = []; 
  levelConfig.forEach((frequencyConfig, frequencyIndex) => {
      frequencyConfig.termsConfig.forEach((termConfig, termIndex) => {
          const daysPastDueValues = termConfig.gradeConfig
              .map(gradeConfig => gradeConfig.daysPastDue)
              .filter(value => value !== "" && value !== null && value !== undefined);  
          for (let i = 0; i < daysPastDueValues.length - 1; i++) {
              if (parseInt(daysPastDueValues[i]) > parseInt(daysPastDueValues[i + 1])) {
                  errors.push({
                      frequencyIndex: frequencyIndex,
                      termIndex: termIndex,
                  });
                  break;
              }
          }
      });
  });

  this.setState({ noAscendingOrderDays: errors });
  if (errors.length > 0) {
      this.setError(Strings("daysInAscendingOrder"));
  }
};

  hanldeLoanStatusAndGradeConfigChange = (levelConfig) => {
    let editedConfiguration = { ...this.state.editedConfiguration };
    editedConfiguration.configurationObject.configuration = levelConfig;
    this.resetError();
    this.setState(
      {
        editedConfiguration: editedConfiguration,
      }
    );
  };


  renderEMIConfiguration = () => {
    return (
      <EMIConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={{ ...this.props.configurationData }}
        // saveEditedDataInTheState={this.saveEditedDataInTheState}
        handleEMILoanValueChange={this.handleEMILoanValueChange}
        handleLevelConfigChange={this.handleLevelConfigChange}
        emptyRateConfigurationData={
          this.state.emptyRateConfigurationData
        }
        emptyTermConfigurationData={
          this.state.emptyTermConfigurationData
        }
        emptyLowerAmountConfigurationData={
          this.state.emptyLowerAmountConfigurationData
        }
        emptyUpperAmountConfigurationData={
          this.state.emptyUpperAmountConfigurationData
        }
        emptyLevelConfigurationData={
          this.state.emptyLevelConfigurationData
        }
        isAllConfigurationDataFilled={
          this.isAllConfigurationDataFilled
        }
      />
    );
  };
  renderPaymentConfigurationFrequency = () => {

    return (
      <PaymentFrequenciesConfiguration
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={{ ...this.props.configurationData }}
        handleEMILoanValueChange={this.handleEMILoanValueChange}
        handleLevelConfigChange={this.handleLevelConfigChange}
        emptyLevelConfigurationData={
          this.state.emptyLevelConfigurationData
        }
        isAllLevelConfigurationDataFilled={
          this.isAllLevelConfigurationDataFilled
        }
      />
    )
  }
  renderLoanStatusAndGradesConfiguration = () => {
    return (
      <LoanStatusAndGradeConfig
        type={this.props.type}
        id={this.state.configuration.id}
        readOnly={this.state.readOnly}
        configurationData={{ ...this.props.configurationData }}
        hanldeLoanStatusAndGradeConfigChange={this.hanldeLoanStatusAndGradeConfigChange}
        emptyLevelConfigurationData={
          this.state.emptyLevelConfigurationData
        }
        isAllLevelConfigurationDataFilled={
          this.isAllLevelConfigurationDataFilled
        }
        noAscendingOrderDays={this.state.noAscendingOrderDays}
        areDaysPastDueInAscendingOrder={this.areDaysPastDueInAscendingOrder}
        areTermsUniqueForFrequency={this.areTermsUniqueForFrequency}
        repeatedTerms={this.state.repeatedTerms}
        checkRepeatedDaysPastDue={this.checkRepeatedDaysPastDue}
        repeatedDaysPastDueErrors={this.state.repeatedDaysPastDueErrors}
      />
    )
  }
  renderCustomConfiguration = () => {
    // NOSONAR
    if (SINGLE_VALUED_CONFIGURATION.includes(this.props.type)) {
      if (
        this.props.configurationData !== null &&
        this.props.dataForTable !== undefined
      )
        return this.renderSingleValuedConfiguration();
    } else {
      if (this.props.type === CONFIGURATION_TYPE.EMI) {
        return this.renderEMIConfiguration();
      }
      if (this.props.type === CONFIGURATION_TYPE.PAYMENT_FREQUENCY) {
        return this.renderPaymentConfigurationFrequency();
      }
      if (this.props.type === CONFIGURATION_TYPE.LOAN_STATUS_AND_GRADE) {
        return this.renderLoanStatusAndGradesConfiguration();
      }
      if (this.props.type === CONFIGURATION_TYPE.HOLIDAY) {
        return this.renderHolidayConfiguration();
      } else if (
        this.props.type === CONFIGURATION_TYPE.AUTO_ANALYSIS
      ) {
        return this.renderAnalystConfiguration();
      } else if (this.props.type === CONFIGURATION_TYPE.LATE_FEES) {
        return this.renderLateFeesConfiguration();
      }
      else if (
        this.props.type === CONFIGURATION_TYPE.ELIGIBILITY_CRITERIA
      ) {
        return (
          <Ladder
            levelsList={
              this.state.editedConfiguration.configurationObject
            }
            readOnly={this.state.readOnly}
            saveEditedDataInTheState={this.saveEditedDataInTheState}
            originalConfiguration={this.props.configuration}
          />
        );
      } else if (this.props.type === CONFIGURATION_TYPE.REWARDS) {
        return this.renderRewardsConfiguration();
      } else if (
        this.props.type !== CONFIGURATION_TYPE.CREDIT_SCORE &&
        this.props.type !== CONFIGURATION_TYPE.PROSPECT_CREDIT_SCORE
      ) {
        if (this.props.dataForTable !== undefined)
          return this.renderMultiValuedConfiguration();
      } else {
        return this.renderCreditScoreConfiguration();
      }
    }
  };

  /* #endregion */

  render() {
    return (
      <div className='customConfiguration'>
        <div className='positionInnerPanel containerPadding'>
          {this.renderHeader()}
          <Col md={12}>
            <div className='errors' ref={this.errorRef}>
              <span>{this.state.errorMessage}</span>
            </div>
          </Col>

          {this.state.confirmationModalInformation.show &&
            this.renderConfirmationModal()}
          {/* render all type of configurations */}
          {this.renderCustomConfiguration()}
          {/* render buttons for all type of configurations */}
          {this.renderConfigurationButtons()}
          {/* render input modal to change version of configuration */}
          {this.renderVersionChangeModal()}
        </div>
      </div>
    );
  }
}

export default CustomConfiguration;
