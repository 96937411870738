import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Strings
import { Strings } from "../../resources/i18n/i18n";
import "./MultiValuedConfiguration.scss";
// Components
import EditableDataTable from "../CustomUIComponents/EditableDataTable/EditableDataTable";
import UserModal from "../CustomUIComponents/UserModal/UserModal";

import {
  LOAN_TYPE_FOR_EMI,
  CONFIGURATION_TYPE,
  USER_COMMISSION_TYPES,
  DAY_RANGE_VALIDATION,
  CONFIGURATION_DATA_TYPE,
  COLUMNS_FOR_CONFIGURATION,
  DEFAULT_LOAN_STATUS_ADD_ROW,
} from "../../constants/appConstants";

import { CONFIGURATION_EDIT_FIELD_KEYS } from "./Constants";

//Assets
import addSubType from "../../resources/images/ic_add_subtype_bold.svg";
import addUser from "../../resources/images/ic_add_user_blue.svg";
import deleteRange from "../../resources/images/ic_notes_minus_blue.svg";

class MultiValuedConfiguration extends Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      dataForTable: props.dataForTable,
      dataForTable2: props.dataForTable2,
      showLoader: false,
      editedProvisionTableNumber: null,
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    this.props.renderDataForDataTable();
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = { ...state };
    if (
      props.dataForTable &&
      props.dataForTable !== state.dataForTable
    ) {
      derivedState = {
        ...derivedState,
        dataForTable: props.dataForTable,
        dataForTable2: props.dataForTable2,
      };
    }

    return derivedState;
  }

  /* #endregion */

  /* #region  Control Events */

  handleChangeValue = (key, value) => {
    this.props.handleChangeValue(key, value);
    this.props.resetNotification();
  };

  editedProvisionTableNumber(tableNum, dataRow) {
    this.setState({ editedProvisionTableNumber: tableNum }, () => {
      this.saveEditedDataInTheState(dataRow);
    });
  }

  saveEditedDataInTheState = (rowData, index = false) => {
    let cellValue, dataType, dataRows;
    if (this.state.dataForTable) {
      if (this.props.type !== CONFIGURATION_TYPE.EMI) {
        dataRows = this.state.dataForTable.dataRows;
      }
      if (DAY_RANGE_VALIDATION.includes(this.props.type)) {
        dataType = CONFIGURATION_DATA_TYPE.DAY_RANGE;
      } else if (this.props.type === CONFIGURATION_TYPE.EMI) {
        dataRows = this.state.dataForTable;
        dataType = CONFIGURATION_DATA_TYPE.EMI;
      } else if (this.props.type === CONFIGURATION_TYPE.LOAN_STATUS) {
        dataRows = this.state.dataForTable;
        dataType = CONFIGURATION_DATA_TYPE.LOAN_STATUS;
      } else if (
        this.props.type === CONFIGURATION_TYPE.COMMISSION_SCHEME
      ) {
        dataRows = this.state.dataForTable[index].dataRows;
        dataType = CONFIGURATION_DATA_TYPE.COMMISSION_SCHEME;
      } else if (
        this.props.type === CONFIGURATION_TYPE.PAYMENT_DISCOUNT
      ) {
        dataRows = this.state.dataForTable.dataRows;
        dataType = CONFIGURATION_DATA_TYPE.PAYMENT_DISCOUNT;
      }
      if (this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA) {
        dataRows = this.state.dataForTable.dataRows;
        dataType = CONFIGURATION_DATA_TYPE.ADMINISTRATIVE_EXPENSE_DATA;
      }
    }
    this.props.saveEditedDataInTheState(
      rowData,
      dataType,
      dataRows,
      cellValue,
      index,
      false,
      null,
      this.state.editedProvisionTableNumber
    );
  };

  handleOnClickAction = (configAction) => {
    this.props.handleOnClickAction(configAction);
  };

  /* #endregion */

  /* #region  Methods */

  resetNotification = () => {
    this.setState({
      showError: false,
      errorMessage: "",
      successMessage: "",
      notificationSuccess: false,
      notificationFailure: false,
    });
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.confirmationModalInformation.show}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={this.state.confirmationModalInformation.type}
        modalText1={this.state.confirmationModalInformation.text}
        //methods
        modalButtonOk={{
          text: Strings("Set"),
          onClick:
            this.state.confirmationModalInformation.onClickOkCallback,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick:
            this.state.confirmationModalInformation
              .onClickCancelCallback,
        }}
      />
    );
  };

  renderEMI = (data, index) => {
    return (
      <React.Fragment key={this.props.type + index}>
        <EditableDataTable
          errorMessage={this.props.errorMessage}
          setError={this.props.setError}
          readOnly={this.props.readOnly}
          resetData={
            // A condition for changing the Data in the component
            // In case of Reset same data for both editedConfiguration and configuration is used
            // So that is the condition which tells EditableDataTable to change Data
            this.state.configuration !==
            this.state.editedConfiguration
          }
          data={data}
          rowDelete={this.props.type === CONFIGURATION_TYPE.EMI}
          rowAdd={this.props.type === CONFIGURATION_TYPE.EMI}
          onRowDataChange={(dataRow, indexRow) => {
            this.saveEditedDataInTheState(dataRow, indexRow);
          }}
          type={this.props.type}
          index={index}
        />
      </React.Fragment>
    );
  };

  renderInputForWeight = (range, index) => {
    return (
      <NumericFormat
        onValueChange={(event) => {
          this.handleEditValue(
            index,
            CONFIGURATION_EDIT_FIELD_KEYS.WEIGHT,
            { target: { value: event.value } }
          );
        }}
        value={range.percentage}
        type='text'
        decimalScale='2'
        isAllowed={({ floatValue }) =>
          floatValue <= 1.0 && floatValue >= 0.0
        }
        allowNegative={false}
      />
    );
  };

  stylesTableCell = () => {
    return styled(TableCell)(({ theme }) => {
      return {
        [`&.${tableCellClasses.head}`]: {
          fontWeight: "bold",
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      };
    });
  };
  renderNumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        type='text'
        decimalScale={props.name === "percentage" ? "2" : "0"}
        isNumericString
        allowNegative={false}
        isAllowed={props.isAllowed}
        disabled={props.disabled}
      />
    );
  });

  onInputValueChange = (event, commissionIndex, index, userIndex) => {
    const value = event.target.value;

    let commissionData =
      this.props.editedConfiguration.configurationObject
        .newCommissionData;

    commissionData[userIndex].commission[commissionIndex].data[index][
      event.target.name
    ] = parseFloat(value === "-" ? -1 : value);

    this.props.saveEditedDataInTheState(
      commissionData,
      CONFIGURATION_DATA_TYPE.COMMISSION_SCHEME,
      false,
      false,
      false,
      true,
      { userIndex: userIndex, commissionIndex: commissionIndex }
    );
  };
  renderTableCell = (
    name,
    value,
    isEnd,
    isAllowed,
    commissionIndex,
    index,
    userIndex
  ) => {
    let cellValue = isEnd && value === -1 ? "-" : value;
    return (
      <TableCell component='th' scope='row' title={cellValue}>
        {this.props.readOnly ? (
          cellValue
        ) : (
          <div className='textBox input'>
            <TextField
              placeholder={"0"}
              value={cellValue}
              onChange={(event) =>
                this.onInputValueChange(
                  event,
                  commissionIndex,
                  index,
                  userIndex
                )
              }
              name={name}
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: this.renderNumberFormatCustom,
              }}
              isAllowed={isAllowed}
              variant='standard'
              disabled={isEnd && value === -1}
            />
          </div>
        )}
      </TableCell>
    );
  };

  deleteRange = (commissionIndex, index, userIndex) => {
    let commissionData =
      this.props.editedConfiguration.configurationObject
        .newCommissionData;
    commissionData[userIndex].commission[commissionIndex].data.splice(
      index,
      1
    );
    this.props.saveEditedDataInTheState(
      commissionData,
      CONFIGURATION_DATA_TYPE.COMMISSION_SCHEME,
      false,
      false,
      false,
      true,
      { userIndex: userIndex, commissionIndex: commissionIndex }
    );
  };

  addRange = (commissionIndex, userIndex) => {
    let newRangeObject = {
      start: 0,
      end: 0,
      percentage: 0,
    };
    let commissionData =
      this.props.editedConfiguration.configurationObject
        .newCommissionData;
    //Adding row in second last position
    commissionData[userIndex].commission[commissionIndex].data.splice(
      commissionData[userIndex].commission[commissionIndex].data - 1,
      0,
      newRangeObject
    );
    this.props.saveEditedDataInTheState(
      commissionData,
      CONFIGURATION_DATA_TYPE.COMMISSION_SCHEME,
      false,
      false,
      false,
      true,
      { userIndex: userIndex, commissionIndex: commissionIndex }
    );
  };

  renderNewCommissionScheme = () => {
    const StyledTableCell = this.stylesTableCell();
    return (
      <React.Fragment>
        <hr className="opacity-100" />

        <div className='pb-3 versions'>
          {USER_COMMISSION_TYPES[3]}
        </div>
        {this.props.editedConfiguration.configurationObject.newCommissionData.map(
          (user, userIndex) => {
            return user.commission.map(
              (singleCommission, commissionIndex) => {
                return (
                  <div
                    className='ps-3'
                    key={Strings(singleCommission.type) + userIndex}
                  >
                    <div className='pb-3 versions'>
                      {Strings(singleCommission.type)}
                    </div>
                    <Row className='justify-content-center newCommissionScheme'>
                      <Col
                        md={{ span: 7, offset: 0 }}
                        className='px-4 d-flex '
                      >
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label='customized table'
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  {Strings("start")} &nbsp;&gt;=
                                </StyledTableCell>
                                <StyledTableCell>
                                  {Strings("end")} &nbsp;&lt;=
                                </StyledTableCell>
                                <StyledTableCell>
                                  {Strings("Commission")}
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {singleCommission.data.length > 0 &&
                                singleCommission.data.map(
                                  (range, index) => {
                                    return (
                                      <TableRow key={index}>
                                        {this.renderTableCell(
                                          "start",
                                          range.start,
                                          false,
                                          true,
                                          commissionIndex,
                                          index,
                                          userIndex
                                        )}
                                        {this.renderTableCell(
                                          "end",
                                          range.end,
                                          true,
                                          true,
                                          commissionIndex,
                                          index,
                                          userIndex
                                        )}
                                        {this.renderTableCell(
                                          "percentage",
                                          range.percentage,
                                          false,
                                          ({ floatValue }) =>
                                            floatValue <= 1.0 &&
                                            floatValue >= 0.0,
                                          commissionIndex,
                                          index,
                                          userIndex
                                        )}
                                        {index !==
                                          singleCommission.data
                                            .length -
                                          1 &&
                                          !this.props.readOnly ? (
                                          <td>
                                            <img
                                              className='deleteIcon'
                                              src={deleteRange}
                                              onClick={() =>
                                                this.deleteRange(
                                                  commissionIndex,
                                                  index,
                                                  userIndex
                                                )
                                              }
                                              alt='delete'
                                            />
                                          </td>
                                        ) : (
                                          <td width='35px' />
                                        )}
                                      </TableRow>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                      {!this.props.readOnly && (
                        <Col
                          md={{ span: 2, offset: 5 }}
                          className='px-4 text-end '
                        >
                          <img
                            src={addUser}
                            alt='add'
                            onClick={() => {
                              this.addRange(
                                commissionIndex,
                                userIndex
                              );
                            }}
                            className='addRange'
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              }
            );
          }
        )}
      </React.Fragment>
    );
  };

  renderCommissionScheme = () => {
    return this.props.type === CONFIGURATION_TYPE.COMMISSION_SCHEME
      ? this.state.dataForTable.map((dataforSingleTable, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 0 &&
              index < this.state.dataForTable.length && <hr className="opacity-100" />}
            <div className='pb-3 versions'>
              {USER_COMMISSION_TYPES[index]}
            </div>
            <EditableDataTable
              readOnly={this.props.readOnly}
              resetData={
                // A condition for changing the Data in the component
                // In case of Reset same data for both editedConfiguration and configuration is used
                // So that is the condition which tells EditableDataTable to change Data
                this.state.configuration !==
                this.state.editedConfiguration
              }
              data={dataforSingleTable}
              onRowDataChange={(dataRow, indexRow) => {
                this.saveEditedDataInTheState(dataRow, indexRow);
              }}
              type={this.props.type}
              index={index}
            />
          </React.Fragment>
        );
      })
      : this.renderMultiValuedConfiguration(
        this.state.dataForTable,
        this.state.dataForTable2
      );
  };
  renderMultiValuedConfiguration = (dataForTable, dataForTable2) => {
    let tableForViewOnly = this.props.type === CONFIGURATION_TYPE.PROVISION;
    return (
      <div>
        {this.props.type === CONFIGURATION_TYPE.PROVISION && (
          <EditableDataTable
            readOnly={this.props.readOnly}
            resetData={
              // A condition for changing the Data in the component
              // In case of Reset same data for both editedConfiguration and configuration is used
              // So that is the condition which tells EditableDataTable to change Data
              this.state.configuration !==
              this.state.editedConfiguration
            }
            data={
              dataForTable2 ? dataForTable2 : this.state.dataForTable2
            }
            rowDelete={false}
            rowAdd={false}
            onRowDataChange={(dataRow) => {
              this.editedProvisionTableNumber(2, dataRow);
            }}
            type={this.props.type}
          />
        )}
        {tableForViewOnly && dataForTable.dataRows.length ? (
          <Row className="ps-3 pb-4">
            <Col md={12}>
              <span className="warningText d-flex justify-content-center">
                {Strings("disabledConfiguration")}
              </span>
            </Col>
          </Row>
        ) : null}
        {dataForTable.dataRows.length ? <EditableDataTable
          readOnly={this.props.readOnly}
          resetData={
            // A condition for changing the Data in the component
            // In case of Reset same data for both editedConfiguration and configuration is used
            // So that is the condition which tells EditableDataTable to change Data
            this.state.configuration !==
            this.state.editedConfiguration
          }
          data={dataForTable ? dataForTable : this.state.dataForTable}
          rowDelete={
            this.props.type === CONFIGURATION_TYPE.PROVISION
              || this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA
              ? true
              : false
          }
          rowAdd={
            this.props.type === CONFIGURATION_TYPE.PROVISION
              || this.props.type === CONFIGURATION_TYPE.ADMINISTRATIVE_EXPENSE_DATA
              ? true
              : false
          }
          onRowDataChange={(dataRow) => {
            this.editedProvisionTableNumber(1, dataRow);
          }}
          type={this.props.type}
          tableForViewOnly={tableForViewOnly}
        /> : null}
      </div>
    );
  };

  handleAddRowForLoanStatusConfiguration = () => {
    let dataForTable = this.state.dataForTable;
    let dataLength = dataForTable.length;

    let newRow = {
      DEFAULT_ROW: DEFAULT_LOAN_STATUS_ADD_ROW,
      columns: COLUMNS_FOR_CONFIGURATION[this.props.type],
      dataRows: {
        term: 0,
        states: [
          { status: "ACTIVE", daysArrears: { lower: 0, upper: 0 } },
          { status: "LEGAL", daysArrears: { lower: 0, upper: 0 } },
          { status: "W-OFF", daysArrears: { lower: 0, upper: 0 } },
        ],
      },
    };
    dataForTable.push(newRow);
    this.setState({
      dataForTable,
    });
    this.saveEditedDataInTheState(
      dataForTable[dataLength].dataRows,
      this.props.index !== undefined ? this.props.index : dataLength
    );
  };

  handleDeleteRowForLoanStatusConfiguration = (deleteRowIndex) => {
    let dataForTable = this.state.dataForTable;
    dataForTable.splice(deleteRowIndex, 1);
    this.setState({ dataForTable });
    this.saveEditedDataInTheState(
      dataForTable.dataRows,
      this.props.index !== undefined ? this.props.index : false
    );
  };

  handleTermChangeForLoanStatusConfiguration = (
    event,
    dataforSingleTable,
    index
  ) => {
    dataforSingleTable.dataRows.term = event.target.value;
    let dataForTable = this.state.dataForTable;
    dataForTable[index] = dataforSingleTable;
    this.setState({ dataForTable }, () => {
      this.saveEditedDataInTheState(
        dataForTable[index].dataRows,
        index
      );
    });
  };

  renderAccordionConfigurations = () => {
    return this.props.type === CONFIGURATION_TYPE.EMI ? (
      this.state.dataForTable.map((dataforSingleTable, index) => {
        return (
          <div
            className='pb-3'
            key={this.props.type + LOAN_TYPE_FOR_EMI[index] + index}
          >
            <Accordion
              key={this.props.type + index}
              defaultExpanded={index === 0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography component={"span"}>
                  {LOAN_TYPE_FOR_EMI[index]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"}>
                  <Row className={"d-flex justify-content-center"}>
                    {this.renderEMI(dataforSingleTable, index)}
                  </Row>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })
    ) : (
      <>
        {this.state.dataForTable.map((dataforSingleTable, index) => {
          return (
            <Row key={index}>
              <Col
                md={this.props.readOnly ? 12 : 11}
                className='pb-3'
                key={this.props.type + index}
              >
                <Accordion
                  key={this.props.type + index}
                  defaultExpanded={index === 0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography component={"span"}>
                      {dataforSingleTable.dataRows.term === -1
                        ? Strings("OtherTerms")
                        : `${dataforSingleTable.dataRows.term
                        } ${Strings("Term")}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!this.props.readOnly &&
                      dataforSingleTable.dataRows.term !== -1 && (
                        <Col md={12} className='pb-2'>
                          <TextField
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                            value={dataforSingleTable.dataRows.term}
                            onChange={(event) =>
                              this.handleTermChangeForLoanStatusConfiguration(
                                event,
                                dataforSingleTable,
                                index
                              )
                            }
                            variant='outlined'
                            label={Strings("Term")}
                          />
                        </Col>
                      )}
                    <Typography component={"span"}>
                      <Row
                        className={
                          "d-flex justify-content-center my-2"
                        }
                      >
                        {this.renderEMI(dataforSingleTable, index)}
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Col>
              <Col
                md={1}
                className={"d-flex align-items-start pt-1 ps-0"}
              >
                {!this.props.readOnly &&
                  dataforSingleTable.dataRows.term !== -1 && (
                    <i
                      className='ps-2 fa fa-trash'
                      aria-hidden='true'
                      title='Eliminar Subtipo'
                      onClick={() => {
                        this.handleDeleteRowForLoanStatusConfiguration(
                          index
                        );
                      }}
                    />
                  )}
              </Col>
            </Row>
          );
        })}
        {!this.props.readOnly && (
          <Row>
            <Col md={12} className='actionButtonPlaceHolder mt-2'>
              <span
                onClick={this.handleAddRowForLoanStatusConfiguration}
              >
                <i>
                  <img
                    className='actionIcon'
                    src={addSubType}
                    alt='add record'
                  />
                </i>
              </span>
            </Col>
          </Row>
        )}
      </>
    );
  };

  /* #endregion */

  render() {
    return (
      <div className='positionInnerPanel containerPadding'>
        {this.state.dataForTable && (
          <Row
            className={
              !this.props.readOnly &&
              this.props.type &&
              this.props.type === CONFIGURATION_TYPE.GRADING_SCALE &&
              "mt-3"
            }
          >
            <Col md={12} className={"pull-right"}>
              {/* render EMI configuration */}
              {this.props.type === CONFIGURATION_TYPE.EMI ||
                this.props.type === CONFIGURATION_TYPE.LOAN_STATUS
                ? this.renderAccordionConfigurations()
                : //render commission scheme or other mulltivalued configurations
                this.renderCommissionScheme()}
              {this.props.type ===
                CONFIGURATION_TYPE.COMMISSION_SCHEME &&
                this.renderNewCommissionScheme()}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default MultiValuedConfiguration;
