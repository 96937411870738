import React from "react";
// eslint-disable-next-line
import { Route, Switch, withRouter } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import _ from "lodash";

//Components
import TakeOffLateFees from "./TakeOffLateFees";
import AssignRepaymentPlan from "./AssignRepaymentPlan";

//Constants
import {
  APPROVAL_DASHBOARD_TABS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  DEFAULT_LOAN_TYPE,
  DEFAULT_CATEGORY_TYPE,
  DATE_TYPE,
  SEARCH_FILTER_PAGES,
} from "../../constants/appConstants";
import { ROUTES } from "../../constants/routeConstants";
import {
  DEFAULT_BRANCH_SELECTION,
  GET_DEFAULT_DROPDOWN_SELECTION,
  DEFAULT_USER_SELECTION,
} from "../UserList/UserListConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import { DEFAULT_ZONE } from "../BranchList/BranchListConstants";

// Constant
import { Strings } from "../../resources/i18n/i18n";
import { DEFAULT_MESSENGER_ZONE } from "../BranchList/BranchListConstants";
class ApprovalDashboard extends React.Component {
  /* #region  LifeCycle Methods */

  constructor(props) {
    super(props);
    this.state = {
      AssignRepaymentPagination: {
        recordsPerPage: props.searchFilter.limit,
        activePage: props.searchFilter.offset,
      },
      assignPlanLoans: props.assignPlanLoans,
      loans: props.loans,
      users: props.users,
      branches: props.branches,
      zones: props.zones,
      mZones: props.mZones,
      recordsPerPage: props.searchFilter.limit,
      activePage: props.searchFilter.offset,
      LateFeeDetails: false,
      AssignRepaymentDetails: false,
      LoanFiltersForLateFees: {
        loanId: props.searchFilter.lid,
        minimartName: props.searchFilter.mn,
        branchId: props.searchFilter.br,
        zone: props.searchFilter.zn,
        mZone: props.searchFilter.mzn,
        category: props.searchFilter.ct,
        loanType: props.searchFilter.ty,
        requester: props.searchFilter.un,
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
      },
      LoanFiltersForAssignRepayment: {
        loanId: props.searchFilter.lid,
        minimartName: props.searchFilter.mn,
        branchId: props.searchFilter.br,
        zone: props.searchFilter.zn,
        mZone: props.searchFilter.mzn,
        category: props.searchFilter.ct,
        loanType: props.searchFilter.ty,
        requester: props.searchFilter.un,
        fromDate: props.searchFilter.fd,
        toDate: props.searchFilter.td,
      },
      activeTab:
        APPROVAL_DASHBOARD_TABS[this.props.loggedInUser.role][0],
      Tabs: APPROVAL_DASHBOARD_TABS[this.props.loggedInUser.role],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = {};
    if (prevState.users) {
      let users = prevState.users;
      derivedState = { ...derivedState, users: users };
    }

    if (
      nextProps.history.location.pathname ===
      ROUTES.APPROVAL_DASHBOARD
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[0],
      };
    }
    if (
      nextProps.history.location.pathname === ROUTES.ASSIGN_REPAYMENT
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[1],
      };
    }
    if (
      nextProps.history.location.pathname ===
      ROUTES.ELIGIBILITY_CRITERIA
    ) {
      derivedState = {
        ...derivedState,
        activeTab: prevState.Tabs[2],
      };
    }
    return derivedState;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
    if (!_.isEqual(prevProps.searchFilter, this.props.searchFilter)) {
      if (this.state.activeTab.value === Strings("takeOffLateFees")) {
        this.setState(
          {
            LoanFiltersForLateFees: {
              loanId: this.props.searchFilter.lid,
              minimartName: this.props.searchFilter.mn,
              branchId: this.props.searchFilter.br,
              zone: this.props.searchFilter.zn,
              mZone: this.props.searchFilter.mzn,
              category: this.props.searchFilter.ct,
              loanType: this.props.searchFilter.ty,
              requester: this.props.searchFilter.un,
              fromDate: this.props.searchFilter.fd,
              toDate: this.props.searchFilter.td,
            },
            recordsPerPage: this.props.searchFilter.limit,
            activePage: this.props.searchFilter.offset,
          },
          () => this.handleTakeOffLateFeeFilter(false)
        );
      } else if (
        this.state.activeTab.value === Strings("RepaymentPlan")
      ) {
        this.setState(
          {
            LoanFiltersForAssignRepayment: {
              loanId: this.props.searchFilter.lid,
              minimartName: this.props.searchFilter.mn,
              branchId: this.props.searchFilter.br,
              zone: this.props.searchFilter.zn,
              mZone: this.props.searchFilter.mzn,
              category: this.props.searchFilter.ct,
              loanType: this.props.searchFilter.ty,
              requester: this.props.searchFilter.un,
              fromDate: this.props.searchFilter.fd,
              toDate: this.props.searchFilter.td,
            },
            AssignRepaymentPagination: {
              recordsPerPage: this.props.searchFilter.limit,
              activePage: this.props.searchFilter.offset,
            },
          },
          () => this.handleAssignRepaymentFilter(false)
        );
      }
    }
    if (!_.isEqual(prevProps.loans, this.props.loans)) {
      this.setState({ loans: this.props.loans });
    }
    if (
      !_.isEqual(
        prevProps.assignPlanLoans,
        this.props.assignPlanLoans
      )
    ) {
      this.setState({ assignPlanLoans: this.props.assignPlanLoans });
    }
  }

  /* #endregion */

  /* #region  Control Events */

  handleRequesterChange = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          requester: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          requester: event.target.value,
        },
      });
    }
  };

  handleBranchChange = (event) => {
    //Need to optimize this function code
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState(
        {
          LoanFiltersForLateFees: {
            ...this.state.LoanFiltersForLateFees,
            branchId: event.target.value,
            zone: "",
            mZone: "",
          },
        },
        () => {
          let branchId = this.state.LoanFiltersForLateFees.branchId;
          this.props.getZonesByBranch(
            branchId !== DEFAULT_BRANCH_SELECTION ? branchId : ""
          );
        }
      );
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState(
        {
          LoanFiltersForAssignRepayment: {
            ...this.state.LoanFiltersForAssignRepayment,
            branchId: event.target.value,
            zone: "",
            mZone: "",
          },
        },
        () => {
          let branchId =
            this.state.LoanFiltersForAssignRepayment.branchId;
          this.props.getZonesByBranch(
            branchId !== DEFAULT_BRANCH_SELECTION ? branchId : ""
          );
        }
      );
    }
  };

  handleZoneChange = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          [event.target.name]: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  handleTabDateChange = (key, dateMoment, stateValue) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state[stateValue].toDate)) {
          DateValue = this.state[stateValue].fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (dateMoment.isBefore(this.state[stateValue].fromDate)) {
          DateValue =
            moment(dateMoment).diff(
              this.state[stateValue].fromDate,
              "days"
            ) === 0
              ? this.state[stateValue].fromDate
              : this.state[stateValue].toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    this.setState({
      [stateValue]: {
        ...this.state[stateValue],
        [key]: DateValue,
      },
    });
  };

  handleDateChange = (key) => (dateMoment) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.handleTabDateChange(
        key,
        dateMoment,
        "LoanFiltersForLateFees"
      );
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.handleTabDateChange(
        key,
        dateMoment,
        "LoanFiltersForAssignRepayment"
      );
    }
  };

  handleSearchIdChange = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          loanId: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          loanId: event.target.value,
        },
      });
    }
  };

  handleSearchNameChange = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          minimartName: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          minimartName: event.target.value,
        },
      });
    }
  };

  handleChangeTypeFilter = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          loanType: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          loanType: event.target.value,
        },
      });
    }
  };

  handleChangeCategoryFilter = (event) => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState({
        LoanFiltersForLateFees: {
          ...this.state.LoanFiltersForLateFees,
          category: event.target.value,
        },
      });
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState({
        LoanFiltersForAssignRepayment: {
          ...this.state.LoanFiltersForAssignRepayment,
          category: event.target.value,
        },
      });
    }
  };

  /* #endregion */

  /* #region  Search Filter */

  handleOnClickResetFilter = () => {
    if (this.state.activeTab.value === Strings("takeOffLateFees")) {
      this.setState(
        {
          LoanFiltersForLateFees: {
            loanId: "",
            minimartName: "",
            loanType: DEFAULT_LOAN_TYPE,
            branchId: DEFAULT_BRANCH_SELECTION,
            zone: DEFAULT_ZONE,
            mZone: DEFAULT_MESSENGER_ZONE,
            requester: DEFAULT_USER_SELECTION,
            category: DEFAULT_CATEGORY_TYPE,
            fromDate: DEFAULT_DURATION.fromDate,
            toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          },
        },
        () => {
          this.handleTakeOffLateFeeFilter(true, true);
          this.props.getZonesByBranch();
        }
      );
    } else if (
      this.state.activeTab.value === Strings("RepaymentPlan")
    ) {
      this.setState(
        {
          LoanFiltersForAssignRepayment: {
            loanId: "",
            minimartName: "",
            branchId: DEFAULT_BRANCH_SELECTION,
            zone: DEFAULT_ZONE,
            mZone: DEFAULT_MESSENGER_ZONE,
            requester: DEFAULT_USER_SELECTION,
            loanType: DEFAULT_LOAN_TYPE,
            category: DEFAULT_CATEGORY_TYPE,
            fromDate: DEFAULT_DURATION.fromDate,
            toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          },
        },
        () => {
          this.handleAssignRepaymentFilter(true, true);
          this.props.getZonesByBranch();
        }
      );
    }
  };

  handleTakeOffLateFeeFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleTakeOffLateFeeFilter(
      {
        search: {
          branchId:
            this.state.LoanFiltersForLateFees.branchId !==
              DEFAULT_BRANCH_SELECTION
              ? this.state.LoanFiltersForLateFees.branchId
              : undefined,
          zone:
            this.state.LoanFiltersForLateFees.zone !== DEFAULT_ZONE
              ? this.state.LoanFiltersForLateFees.zone
              : undefined,
          mZone:
            this.state.LoanFiltersForLateFees.mZone !==
              DEFAULT_MESSENGER_ZONE
              ? this.state.LoanFiltersForLateFees.mZone
              : undefined,
          loanId:
            this.state.LoanFiltersForLateFees.loanId !== ""
              ? this.state.LoanFiltersForLateFees.loanId
              : undefined,
          minimartName:
            this.state.LoanFiltersForLateFees.minimartName !== ""
              ? this.state.LoanFiltersForLateFees.minimartName
              : undefined,
          loanType:
            this.state.LoanFiltersForLateFees.loanType !==
              DEFAULT_LOAN_TYPE && this.state.LoanFiltersForLateFees.loanType !== "Type of Loan"
              ? this.state.LoanFiltersForLateFees.loanType
              : undefined,
          category:
            this.state.LoanFiltersForLateFees.category !==
              DEFAULT_CATEGORY_TYPE && this.state.LoanFiltersForLateFees.category !== "Select Category"
              ? this.state.LoanFiltersForLateFees.category
              : undefined,
          requester:
            this.state.LoanFiltersForLateFees.requester !==
              DEFAULT_USER_SELECTION
              ? this.state.LoanFiltersForLateFees.requester
              : undefined,
          fromDate: this.state.LoanFiltersForLateFees.fromDate,
          toDate: this.state.LoanFiltersForLateFees.toDate,
        },
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      }
    );
  };

  handleAssignRepaymentFilter = (
    isDefaultOffset
  ) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleAssignRepaymentFilter(
      {
        search: {
          branchId:
            this.state.LoanFiltersForAssignRepayment.branchId !==
              DEFAULT_BRANCH_SELECTION
              ? this.state.LoanFiltersForAssignRepayment.branchId
              : undefined,
          zone:
            this.state.LoanFiltersForAssignRepayment.zone !==
              DEFAULT_ZONE
              ? this.state.LoanFiltersForAssignRepayment.zone
              : undefined,
          mZone:
            this.state.LoanFiltersForAssignRepayment.mZone !==
              DEFAULT_MESSENGER_ZONE
              ? this.state.LoanFiltersForAssignRepayment.mZone
              : undefined,
          loanId:
            this.state.LoanFiltersForAssignRepayment.loanId !== ""
              ? this.state.LoanFiltersForAssignRepayment.loanId
              : undefined,
          minimartName:
            this.state.LoanFiltersForAssignRepayment.minimartName !==
              ""
              ? this.state.LoanFiltersForAssignRepayment.minimartName
              : undefined,
          loanType:
            this.state.LoanFiltersForAssignRepayment.loanType !==
              DEFAULT_LOAN_TYPE
              ? this.state.LoanFiltersForAssignRepayment.loanType
              : undefined,
          category:
            this.state.LoanFiltersForAssignRepayment.category !==
              DEFAULT_CATEGORY_TYPE
              ? this.state.LoanFiltersForAssignRepayment.category
              : undefined,
          requester:
            this.state.LoanFiltersForAssignRepayment.requester !==
              DEFAULT_USER_SELECTION
              ? this.state.LoanFiltersForAssignRepayment.requester
              : undefined,
          fromDate: this.state.LoanFiltersForAssignRepayment.fromDate,
          toDate: this.state.LoanFiltersForAssignRepayment.toDate,
        },
        limit: this.state.AssignRepaymentPagination.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.AssignRepaymentPagination.activePage,
      }
    );
  };

  /* #endregion */

  /* #region  Pagination Methods */

  onPageChange = ({ page, pageLength }) => {
    if (this.state.activeTab.value === Strings("RepaymentPlan")) {
      this.setState(
        {
          AssignRepaymentPagination: {
            activePage: page,
            recordsPerPage: pageLength,
          },
        },
        () => this.handleAssignRepaymentFilter(false)
      );
    } else {
      this.setState(
        {
          activePage: page,
          recordsPerPage: pageLength,
        },
        () => this.handleTakeOffLateFeeFilter(false)
      );
    }
  };

  /* #endregion */

  /* #region  Tab Methods*/

  handleTabs = (index) => {
    const LoanFiltersForLateFees = {
      loanId: "",
      minimartName: "",
      loanType: DEFAULT_LOAN_TYPE,
      branchId: DEFAULT_BRANCH_SELECTION,
      zone: DEFAULT_ZONE,
      requester: DEFAULT_USER_SELECTION,
      category: DEFAULT_CATEGORY_TYPE,
      fromDate: DEFAULT_DURATION.fromDate,
      toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
    };

    const LoanFiltersForAssignRepayment = {
      loanId: "",
      minimartName: "",
      branchId: DEFAULT_BRANCH_SELECTION,
      zone: DEFAULT_ZONE,
      requester: DEFAULT_USER_SELECTION,
      loanType: DEFAULT_LOAN_TYPE,
      category: DEFAULT_CATEGORY_TYPE,
      fromDate: DEFAULT_DURATION.fromDate,
      toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
    };

    this.setState(
      {
        LateFeeDetails: false,
        AssignRepaymentDetails: false,
        activeTab: this.state.Tabs[index],
        LoanFiltersForLateFees: LoanFiltersForLateFees,
        LoanFiltersForAssignRepayment: LoanFiltersForAssignRepayment,
      },
      () => {
        this.props.setSearchFilterAction(
          SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD,
          {
            fd: DEFAULT_DURATION.fromDate,
            td: DEFAULT_DURATION.toDate,
            ty: DEFAULT_LOAN_TYPE,
            ct: DEFAULT_CATEGORY_TYPE,
            lid: "",
            un: DEFAULT_USER_SELECTION,
            br: DEFAULT_BRANCH_SELECTION,
            mn: "",
            limit: DEFAULT_PAGINATION_RECORDS_COUNT,
            offset: 1,
          },
          SEARCH_FILTER_PAGES.APPROVAL_DASHBOARD
        );
        if (index === 0) {
          this.props.history.push(ROUTES.APPROVAL_DASHBOARD);
        } else if (index === 1) {
          this.props.history.push(ROUTES.ASSIGN_REPAYMENT);
        } else {
          this.props.history.push(ROUTES.ELIGIBILITY_CRITERIA);
        }
      }
    );
  };

  /* #endregion */

  renderTakeOffLateFees = (
    collectionOfficerUsers,
    branches,
    zones
  ) => {
    return (
      <TabPanel>
        <Switch>
          <Route
            exact
            path={ROUTES.APPROVAL_DASHBOARD}
            render={() => (
              <TakeOffLateFees
                activeTab={this.state.activeTab}
                handleRequesterChange={this.handleRequesterChange}
                handleTakeOffLateFeeFilter={
                  this.handleTakeOffLateFeeFilter
                }
                handleChangeTypeFilter={this.handleChangeTypeFilter}
                handleChangeCategoryFilter={
                  this.handleChangeCategoryFilter
                }
                handleSearchIdChange={this.handleSearchIdChange}
                handleSearchMatricesIdChange={
                  this.handleSearchMatricesIdChange
                }
                handleSearchNameChange={this.handleSearchNameChange}
                handleBranchChange={this.handleBranchChange}
                handleZoneChange={this.handleZoneChange}
                handleDateChange={this.handleDateChange}
                handleOnClickResetFilter={
                  this.handleOnClickResetFilter
                }
                onPageChange={this.onPageChange}
                recordsPerPage={this.state.recordsPerPage}
                activePage={this.state.activePage}
                numberOfPages={this.props.numberOfPages}
                LoanFiltersForLateFees={
                  this.state.LoanFiltersForLateFees
                }
                loans={this.state.loans}
                users={collectionOfficerUsers}
                branches={branches}
                setLoanDetailsID={this.props.setLoanDetailsID}
                getUserList={this.props.getUserList}
                zones={zones}
                mZones={this.state.mZones}
              />
            )}
          />
        </Switch>
      </TabPanel>
    );
  };

  renderAssignRepaymentPlan = (
    collectionOfficerUsers,
    branches,
    zones
  ) => {
    return (
      <TabPanel>
        <Switch>
          <Route
            exact
            path={ROUTES.ASSIGN_REPAYMENT}
            render={() => (
              <AssignRepaymentPlan
                activeTab={this.state.activeTab}
                handleRequesterChange={this.handleRequesterChange}
                handleChangeTypeFilter={this.handleChangeTypeFilter}
                handleChangeCategoryFilter={
                  this.handleChangeCategoryFilter
                }
                handleBranchChange={this.handleBranchChange}
                handleDateChange={this.handleDateChange}
                handleSearchIdChange={this.handleSearchIdChange}
                handleSearchMatricesIdChange={
                  this.handleSearchMatricesIdChange
                }
                handleSearchNameChange={this.handleSearchNameChange}
                handleOnClickSearchFilter={
                  this.handleAssignRepaymentFilter
                }
                handleOnClickResetFilter={
                  this.handleOnClickResetFilter
                }
                onPageChange={this.onPageChange}
                recordsPerPage={
                  this.state.AssignRepaymentPagination.recordsPerPage
                }
                activePage={
                  this.state.AssignRepaymentPagination.activePage
                }
                numberOfPages={this.props.repaymentNumberOfPages}
                loans={this.state.assignPlanLoans}
                LoanFiltersForAssignRepayment={
                  this.state.LoanFiltersForAssignRepayment
                }
                users={collectionOfficerUsers}
                branches={branches}
                setLoanDetailsID={this.props.setLoanDetailsID}
                getUserList={this.props.getUserList}
                handleZoneChange={this.handleZoneChange}
                zones={zones}
                mZones={this.state.mZones}
              />
            )}
          />
        </Switch>
      </TabPanel>
    );
  };

  getBranches = () => {
    return (
      this.state?.branches &&
      this.state?.branches.map((singleBranch) => {
        return {
          label: singleBranch.name,
          value: singleBranch.id,
        };
      })
    );
  };

  getUsers = () => {
    const users = this.props.users;
    const collectionOfficerUsers = [];
    collectionOfficerUsers.push({
      label: GET_DEFAULT_DROPDOWN_SELECTION().role,
      value: GET_DEFAULT_DROPDOWN_SELECTION().id,
    });
    for (let i = 0; i < users?.length; i++) {
      if (users[i].active === true) {
        collectionOfficerUsers.push({
          label: users[i].firstName + " " + users[i].lastName,
          value: users[i].id,
        });
      }
    }

    return collectionOfficerUsers;
  };

  render() {
    const branches = this.getBranches();
    const collectionOfficerUsers = this.getUsers();
    return (
      <div className='dailyStatus'>
        <Tabs
          className='pt-3 '
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {this.state.Tabs.map((item) => (
              <Tab key={item.key}>{item.value}</Tab>
            ))}
          </TabList>
          {this.renderTakeOffLateFees(
            collectionOfficerUsers,
            branches,
            this.state.zones
          )}
          {this.renderAssignRepaymentPlan(
            collectionOfficerUsers,
            branches,
            this.state.zones
          )}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(ApprovalDashboard);
