import React from "react";
import { Button, Row, Col, Collapse } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import _ from "lodash";
//Component
import Loans from "../Loans/Loans";
import Invoices from "../Invoices/Invoices";
import CreditLimit from "./CreditLimit";
import NotesModal from "../CustomUIComponents/NotesModal/NotesModal";
import UserModal from "../CustomUIComponents/UserModal/UserModal";
import PaymentReceipts from "./PaymentReceipts";
import MinimartDetailsPopup from "./MinimartDetailsPopup";
// import PromotionsApprovalConatiner from "../MTPPromotionsApproval/PromotionsApprovalContainer";
// import RewardHistory from "./RewardHistory";
import TextFieldComponent from "../CustomUIComponents/TextFieldComponent/TextFieldComponent";
import EditableModal from "../LoanDetails/EditableModal";
import PaymentCollectionContainer from "./PaymentCollectionTab/PaymentCollectionContainer";

//Action

//Constants
import {
  MINIMART_TABS,
  INVOICE_STATUSES,
  DEFAULT_INVOICE_STATUS,
  DEFAULT_LOAN_STATUS_SELECTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DURATION,
  STATUS_FOR_LOANS,
  CATEGORY,
  // SPECIAL_MESSENGER_PRESENTATION,
  SPECIAL,
  SHORT_SPECIAL_MESSENGER_PRESENTATION,
  DISBURSED,
  DATE_TYPE,
  SEARCH_FILTER_TYPE,
  SEARCH_FILTER_FIELDS,
  CONFIGURATION_DATE_TIME_FORMAT,
  USER_ROLES,
  MINIMART_STATUS,
  APPROVED_MINIMART_ACTION,
  INVOICE_TYPES,
  CI_MINIMART_STATUS,
  MINIMART_LEVELS,
  VISITED_DAYS,
  DEFAULT_VALUES,
  INVOICE_DATE_FORMAT,
  NOTES_TYPE,
  NOTE_TYPE_CONTEXT,
} from "../../constants/appConstants";
import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";
import {
  ASSOCIATION_USERS,
  BRANCH,
  MINIMART_ASSOCIATION_DETAILS,
  MINIMART_DETAILS,
  MINIMART_DETAILS_EDIT_USERS,
  MINIMART_EDIT_ACCESS,
  NEW_MINIMART_EDIT_ACCESS,
  NEW_MINIMART_DETAILS_EDIT_USERS,
  NEW_MINIMART_ASSOCIATION_DETAILS,
  EXCEPTIONAL_MINIMART_USER_ACCESS,
  ACCESS_TO_BLOCK_MINIMARTS,
  ADD_FOLLOW_UP_NOTES_ACCESS,
} from "./Constants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

//Styles
import "./MinimartDetails.scss";
import "react-tabs/style/react-tabs.css";

//Assets
import Defaultminimart from "../../resources/images/ic_mini_market_new.svg";
import editNote from "../../resources/images/ic_edit.svg";
import pageDividerOpened from "../../resources/images/ic_page_divider_arrow.svg";
import pageDividerClosed from "../../resources/images/ic_page_divider_arrow_close.svg";
import addNotes from "../../resources/images/ic_add_note.svg";

class MinimartDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notesModal: false,
      noteType: "",
      InvoiceFilters: {
        status: DEFAULT_INVOICE_STATUS.value,
        fromDate: DEFAULT_DURATION.fromDate,
        toDate: DEFAULT_DURATION.toDate,
        invoiceType: "",
      },
      LoanFilters: {
        status:
          props.searchFilter?.st || DEFAULT_INVOICE_STATUS.value,
        fromDate: props.searchFilter?.fd || DEFAULT_DURATION.fromDate,
        category: props.searchFilter?.ct || "",
        toDate: props.searchFilter?.td || DEFAULT_DURATION.toDate,
      },
      activePage: props.searchFilter?.offset,
      recordsPerPage: props.searchFilter?.limit,
      Tabs: MINIMART_TABS[props.loggedInUser.role],
      activeTab: props.activeTab
        ? props.activeTab
        : props.singleMiniMart.ticketId // NOSONAR
          ? MINIMART_TABS[props.loggedInUser.role][2]
          : MINIMART_TABS[props.loggedInUser.role][0],
      disabled: props.searchFilter.st !== DISBURSED,
      fromDate: DEFAULT_DURATION.fromDate,
      toDate: DEFAULT_DURATION.toDate,
      minimartCode: props.singleMiniMart.code,
      matricesId: this.props.singleMiniMart.id,
      // minimartId: props.singleMiniMart.left.id,
      ticketId: props.singleMiniMart.ticketId,
      confirmationModalInformation: {
        show: false,
        type: "",
        text: "",
        text2: "",
        note: "",
      },
      openModal: false,
      branchList: this.props.branchList,
      zones: this.props.zones,
      mZones: this.props.mZones,
      isCollapseView: true,
      isMergeMinimart: false,
      megreMatricesId: "",
      minimart: this.props.minimart.minimartDetails,
      editClick: false,
      association: this.props.minimart.association,
      showModal: false,
      invoice: {
        invoiceNumber: "",
        supplierName: "",
        expiryDate: DEFAULT_DURATION.toDate,
        amount: 0,
        balance: 0,
        type: "FOOD",
        ciCode: this.props.minimart.minimartDetails.code,
        ciZone: "",
        visitDay: "",
      },
      supplierList: null,
      gradeObject: {
        gradeUpdate: "",
        resetGrade: this.props.minimart.minimartDetails.resetGrade,
      },
      paymentType: "",
      addNote: false,
    };
  }

  componentDidMount() {
    this.handleOnClickSearchFilter(false);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branchList, this.props.branchList)) {
      this.setState({ branchList: this.props.branchList });
    }
    if (!_.isEqual(prevProps.zones, this.props.zones)) {
      this.setState({ zones: this.props.zones });
    }
    if (!_.isEqual(prevProps.mZones, this.props.mZones)) {
      this.setState({ mZones: this.props.mZones });
    }
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
    if (
      !_.isEqual(
        prevProps.minimart.minimartDetails,
        this.props.minimart.minimartDetails
      )
    ) {
      this.setState({
        minimart: this.props.minimart.minimartDetails,
      });
    }
    if (
      !_.isEqual(
        prevProps.minimart.association,
        this.props.minimart.association
      )
    ) {
      this.setState({
        association: this.props.minimart.association,
      });
    }
  }

  setValue = (index) => {
    this.setState({ activeTab: this.state.Tabs[index] }, () => {
      this.handleOnClickSearchFilter(false);
    });
  };

  handleTabs = (index) => {
    this.setState(
      {
        LoanFilters: {
          ...this.state.LoanFilters,
          status: DEFAULT_INVOICE_STATUS.value,
          fromDate: DEFAULT_DURATION.fromDate,
          category: "",
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        },
        InvoiceFilters: {
          ...this.state.InvoiceFilters,
          status: DEFAULT_INVOICE_STATUS.value,
          fromDate: DEFAULT_DURATION.fromDate,
          toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
        },
        recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      },
      () => {
        this.setValue(index);
      }
    );
  };

  handleDateChange = (key) => (dateMoment) => {
    let DateValue = "";
    switch (key) {
      case DATE_TYPE.FROM_DATE:
        if (dateMoment.isAfter(this.state.toDate)) {
          DateValue = this.state.fromDate;
        } else {
          dateMoment.set({ hour: 0, minute: 0, second: 0 });
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      case DATE_TYPE.TO_DATE:
        if (
          dateMoment.isBefore(this.state.fromDate) ||
          dateMoment.isAfter(new Date())
        ) {
          DateValue =
            moment(dateMoment).diff(this.state.fromDate, "days") === 0
              ? this.state.fromDate
              : this.state.toDate;
        } else {
          DateValue = dateMoment.format(DEFAULT_DATE_FORMAT);
        }
        break;
      default:
    }
    if (this.state.activeTab.value === MINIMART_TABS.ALL[0].value) {
      this.setState({
        [key]: DateValue,
        InvoiceFilters: {
          ...this.state.InvoiceFilters,
          [key]: DateValue,
        },
      });
    } else if (
      this.state.activeTab.value === MINIMART_TABS.ALL[1].value
    ) {
      this.setState({
        [key]: DateValue,
        LoanFilters: { ...this.state.LoanFilters, [key]: DateValue },
      });
    }
  };

  handleChangeStatusFilter = (event) => {
    if (this.state.activeTab.value === MINIMART_TABS.ALL[0].value) {
      let status;
      if (
        event.target.value !== "selectInvoiceStatus"
      ) {
        status = event.target.value;
      } else {
        status = "";
      }
      this.setState({
        InvoiceFilters: {
          ...this.state.InvoiceFilters,
          status: status,
        },
      });
    } else if (
      this.state.activeTab.value === MINIMART_TABS.ALL[1].value
    ) {
      let status, category;
      if (
        event.target.value !== DEFAULT_LOAN_STATUS_SELECTION.value
      ) {
        status = event.target.value;
        category =
          event.target.value !== DISBURSED
            ? ""
            : this.state.LoanFilters.category;
      } else {
        status = "";
        category = "";
      }
      this.setState({
        LoanFilters: {
          ...this.state.LoanFilters,
          status: status,
          category: category,
        },
        disabled: event.target.value === DISBURSED ? false : true,
      });
    }
  };

  handleChangeInvoiceType = (event) => {
    this.setState({
      InvoiceFilters: {
        ...this.state.InvoiceFilters,
        invoiceType:
          event.target.value === Strings("InvoiceType")
            ? ""
            : event.target.value,
      },
    });
  };

  handleOnClickSearchFilter = (isDefaultOffset) => {
    isDefaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    if (this.state.activeTab.value === MINIMART_TABS.ALL[0].value) {
      this.props.handleInvoiceSearchFilter({
        search: {
          matricesId: this.props.singleMiniMart.id,
          status: this.state.InvoiceFilters.status,
          fromDate: this.state.InvoiceFilters.fromDate,
          toDate: this.state.InvoiceFilters.toDate,
          invoiceType:
            this.state.InvoiceFilters.invoiceType !== ""
              ? this.state.InvoiceFilters.invoiceType
              : undefined,
        },

        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      });
    } else if (
      this.state.activeTab.value === MINIMART_TABS.ALL[1].value
    ) {
      this.props.handleLoanSearchFilter({
        search: {
          matricesId: this.props.singleMiniMart.id,
          status: this.state.LoanFilters.status,
          fromDate: this.state.LoanFilters.fromDate,
          category:
            this.state.LoanFilters.category !== ""
              ? this.state.LoanFilters.category
              : undefined,
          toDate: this.state.LoanFilters.toDate,
        },
        limit: this.state.recordsPerPage,
        offset: isDefaultOffset
          ? DEFAULT_PAGINATION_ACTIVE_PAGE
          : this.state.activePage,
      });
    }
  };

  handleChangeCategoryFilter = (event) => {
    this.setState({
      LoanFilters: {
        ...this.state.LoanFilters,
        category:
          event.target.value === CATEGORY[0].label
            ? ""
            : event.target.value,
      },
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  resetSearchValues = () => {
    if (this.state.activeTab.value === MINIMART_TABS.ALL[0].value) {
      this.setState(
        {
          InvoiceFilters: {
            ...this.state.InvoiceFilters,
            status: DEFAULT_INVOICE_STATUS.value,
            fromDate: DEFAULT_DURATION.fromDate,
            toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          },
          activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
          recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        },
        () => {
          this.handleOnClickSearchFilter(true);
        }
      );
    } else if (
      this.state.activeTab.value === MINIMART_TABS.ALL[1].value
    ) {
      this.setState(
        {
          LoanFilters: {
            ...this.state.LoanFilters,
            status: DEFAULT_INVOICE_STATUS.value,
            fromDate: DEFAULT_DURATION.fromDate,
            category: "",
            toDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
          },
          activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
          recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
        },
        () => {
          this.handleOnClickSearchFilter(true);
        }
      );
    }
  };

  toggleModal = (type = "") => {
    this.setState({
      noteType: type,
      notesModal: !this.state.notesModal,
      addNote: false,
    });
  };

  changeMinimartStatus = (notes) => {
    const minimartObject = {
      id: this.props.minimart.minimartDetails.id,
      st:
        this.props.minimart.minimartDetails.status ===
          MINIMART_STATUS.ACTIVE
          ? MINIMART_STATUS.BLOCKED
          : MINIMART_STATUS.ACTIVE,
      nt: notes,
    };
    this.props.changeMinimartStatus(minimartObject);
    this.toggleModal();
  };

  addNote = (notes) => {
    const request = {
      refId: this.props.minimart.minimartDetails.id,
      noteTypeContext: NOTE_TYPE_CONTEXT.MINIMART,
      type: NOTES_TYPE.FOLLOW_UP,
      note: notes
    }
    this.props.addNote(request);
    this.toggleModal();
  }

  onChange = (event) => {
    const name = event.target.name;
    const value = DEFAULT_VALUES.includes(event.target.value)
      ? ""
      : event.target.value;
    let minimart = { ...this.state.minimart };
    if (name === BRANCH) {
      minimart.branch = value;
      minimart.zone = "";
      minimart.messengerZone = "";
    } else {
      minimart[name] = value;
    }

    this.setState(
      {
        minimart: { ...minimart },
        errorMessage: "",
      },
      () => {
        if (name === BRANCH) {
          this.props.getZonesByBranch(this.state.minimart.branch);
        } else if (
          !this.state.isMergeMinimart &&
          this.state.minimart.visitDay !== ""
        ) {
          if (
            this.state.minimart.branch === "" ||
            this.state.minimart.zone === "" ||
            this.state.minimart.messengerZone === "" ||
            this.state.minimart.visitDay === "" ||
            this.state.minimart.visitDay === undefined
          ) {
            this.setState({
              errorMessage: Strings("pleaseSelectRequiredFields"),
            });
          } else if (
            name === "branch" ||
            name === "zone" ||
            name === "visitDay" ||
            name === "messengerZone"
          ) {
            this.getAssignedMinimartUserList({
              br: this.state.minimart.branch,
              zn: this.state.minimart.zone,
              mzn: this.state.minimart.messengerZone,
              vd: this.state.minimart.visitDay,
            });
          }
        }
      }
    );
  };

  renderTextField = (prop) => {
    let onKeyDown;
    let disableTextField = false;
    let emprtyValue;
    emprtyValue = typeof prop.value === 'string' ? prop.value.trim().length === 0 : false;
    if (prop.isNumber) {
      onKeyDown = Utility.allowOnlyNumber;
    } else if (prop.isFloat) {
      onKeyDown = Utility.allowOnlyFloat;
    } else {
      onKeyDown = () => { };
    }
    let className = "";
    if (
      emprtyValue || !prop.value ||
      (prop.name === "telephone" &&
        prop.value?.length !== 10) ||
      (prop.name === "ownerNumber" &&
        prop.value?.length !== 10) ||
      (prop.name === "latitude" &&
        !Utility.checkIfValidLatitude(prop.value)) ||
      (prop.name === "longitude" &&
        !Utility.checkIfValidLongitude(prop.value))
    ) {
      className = "errorField";
    }
    if (prop.name !== "branch" && this.state.minimart.branch === null) {
      if (prop.name === "zone" || prop.name === "messengerZone") {
        disableTextField = true;
      } else if (this.state.minimart.branch === "" && prop.name !== "branch") {
        disableTextField = true;
      } else {
        disableTextField = false;
      }
    } else if ((prop.name === "zone" || prop.name === "messengerZone") && this.state.minimart.branch === "") {
      disableTextField = true;
    }
    return (
      <div>
        <TextFieldComponent
          id={prop.name}
          type={prop.type}
          className={`noMargin ${className}`}
          handleChange={this.onChange}
          value={prop.value}
          multiple={false}
          halfWidth={false}
          name={prop.name}
          onKeyDown={onKeyDown}
          disabled={disableTextField}
        >
          {prop.isSelection &&
            prop.dropDownList.map((version) => {
              return (
                <option
                  key={version.value || version.name}
                  value={version.value || version.id || version.name}
                >
                  {version.label || version.name}
                </option>
              );
            })}
        </TextFieldComponent>
      </div>
    );
  };

  handleCancelClick = (saveClick = false) => {
    this.setState(
      {
        editClick: false,
        minimart: this.props.minimart.minimartDetails,
        association: this.props.minimart.association,
      },
      () => {
        if (!saveClick) {
          this.getAssignedMinimartUserList({
            br: this.props.minimart.minimartDetails.branch,
            zn: this.props.minimart.minimartDetails.zone,
            mzn: this.prop.minimart.minimartDetails.messengerZone,
            vd: this.props.minimart.minimartDetails.visitDay,
          });
        }
      }
    );
  };

  renderMinimartDetailValues = (minimart) => {
    let values = this.state.minimart[minimart.key];
    if (!this.state.minimart[minimart.key]) {
      values = Strings("NA");
    } else if (minimart.key === "collectionDays") {
      values = Utility.convertCollectionDaysToSpanish(this.state.minimart[minimart.key].split(","));
    }
    return values;
  }

  renderMinimartDetails = () => {
    //NOSONAR
    let minimartDetails = this.props.isNewMinimart
      ? MINIMART_DETAILS.filter(
        (detail) => detail.key !== "eligibleLoanType"
      )
      : MINIMART_DETAILS;
    return (
      <>
        <Row className='underlineText pb-1'>
          {Strings("minimartDetailsText")}
          {this.props.minimart.minimartDetails.status === MINIMART_STATUS.BLOCKED && (
            <div className='warningText ps-2 w-auto'>
              {Strings("blockedMinimartWarning")}
            </div>
          )}
          {this.props.minimart.minimartDetails.status === MINIMART_STATUS.BLOCKED && !this.props.minimart.minimartDetails.iff && (
            <>
              {this.props.minimart.minimartDetails.registrationStatus === CI_MINIMART_STATUS.APPROVED &&
                !this.state.editClick &&
                MINIMART_EDIT_ACCESS.includes(this.props.loggedInUser.role) && (
                  <div className='pencil px-1 w-auto'>
                    <img
                      src={editNote}
                      className={"editIcon m-0"}
                      alt={"edit"}
                      title={Strings("EditMinimartDetails")}
                      onClick={this.handleEditClick}
                    />
                  </div>
                )}
            </>
          )}
          {this.props.minimart.minimartDetails.status !== MINIMART_STATUS.BLOCKED && (
            this.props.minimart.minimartDetails.registrationStatus === CI_MINIMART_STATUS.APPROVED &&
            !this.state.editClick &&
            MINIMART_EDIT_ACCESS.includes(this.props.loggedInUser.role) && (
              <div className='pencil mx-1 px-1 w-auto'>
                <img
                  src={editNote}
                  className={"editIcon m-0"}
                  alt={"edit"}
                  title={Strings("EditMinimartDetails")}
                  onClick={this.handleEditClick}
                />
              </div>
            )
          )}
        </Row>
        <Row>
          {minimartDetails.map((minimart) => {
            let list = null;
            let isEdit =
              this.props.loggedInUser.role &&
              this.state.editClick && this.props.minimart.minimartDetails.status !==
              MINIMART_STATUS.BLOCKED &&
              (this.props.isNewMinimart
                ? NEW_MINIMART_DETAILS_EDIT_USERS[
                  this.props.loggedInUser.role
                ].includes(minimart.key)
                : MINIMART_DETAILS_EDIT_USERS[
                  this.props.loggedInUser.role
                ].includes(minimart.key)) &&
              minimart.isEdit;
            if (this.state.editClick) {
              if (minimart.key === "eligibleLoanType") {
                list = MINIMART_LEVELS;
              }
            }
            return (
              <Col
                md={4}
                className='px-0 d-flex align-items-center pb-2'
                key={minimart.key}
              >
                <span
                  className={`titleText text-wrap ${isEdit && minimart.width
                    ? "w-" + minimart.width
                    : ""
                    }`}
                >
                  {Strings(minimart.label)}
                </span>
                {isEdit && <span className='mandatoryFields'>*</span>}
                &nbsp;:&nbsp;
                {isEdit ? (
                  this.renderTextField({
                    label: minimart.label,
                    name: minimart.key,
                    type: minimart.type,
                    value: this.state.minimart[minimart.key],
                    isSelection: minimart.isSelection,
                    isNumber: minimart.isNumber,
                    dropDownList: list,
                  })
                ) : (
                  <>
                    <span className='pe-0 detailText'>
                      {this.renderMinimartDetailValues(minimart)}
                    </span>{" "}
                  </>
                )}
              </Col>
            );
          })}
        </Row>
        <Row>
          {this.props.minimart.minimartDetails.forgetPassword && (
            <Col md={4} className='px-0 pb-1'>
              <div className='contentWidth'>
                <span className='titleText'>
                  {Strings("forgotPassword") + " : "}
                </span>
                <span className='pe-0 detailText'>
                  {this.props.minimart.minimartDetails
                    .forgetPassword || Strings("NA")}
                </span>
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  renderCreditLimit = () => {
    return (
      <>
        <Row className='underlineText pt-3 p-0'>
          {Strings("creditLimit")}
        </Row>
        <Row>
          <Col md={4} className='ps-0'>
            <div className='contentWidth'>
              <span className='titleText'>
                {Strings("creditLimitTooltip") + " ($)"}
              </span>
              &nbsp;:&nbsp;
              <label className='noMargin detailText'>
                {Utility.getCurrencyRepresentationOfAmount(
                  this.props.minimart.creditLimit.usedCreditLimit
                )}
                &nbsp;/&nbsp;
                {Utility.getCurrencyRepresentationOfAmount(
                  this.props.minimart.creditLimit.creditLimit
                )}
                {this.props.minimart.creditLimit.usedCreditLimit > this.props.minimart.creditLimit.creditLimit &&
                  <span className="errorMessage">
                    {` (${Utility.getCurrencyRepresentationOfAmount(
                      this.props.minimart.creditLimit.creditLimit - this.props.minimart.creditLimit.usedCreditLimit
                    )})`}
                  </span>
                }
              </label>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  handleEditClick = () => {
    let isEditAssociation =
      this.props.loggedInUser.role &&
      (this.props.isNewMinimart
        ? NEW_MINIMART_DETAILS_EDIT_USERS[
          this.props.loggedInUser.role
        ].includes("branch") ||
        NEW_MINIMART_DETAILS_EDIT_USERS[
          this.props.loggedInUser.role
        ].includes("visitDay")
        : MINIMART_DETAILS_EDIT_USERS[
          this.props.loggedInUser.role
        ].includes("branch") ||
        MINIMART_DETAILS_EDIT_USERS[
          this.props.loggedInUser.role
        ].includes("visitDay"));

    let branchValue = ""
    if (isEditAssociation && this.props.minimart.minimartDetails.branch) {
      this.props.getBranchList();
      branchValue = this.props.branchList.find(
        (branch) =>
          branch.name === this.props.minimart.minimartDetails.branch
      ).id;
    }
    this.props.getZonesByBranch(branchValue);
    this.setState({
      minimart: isEditAssociation && this.props.minimart.minimartDetails.branch
        ? { ...this.state.minimart, branch: branchValue ? branchValue : null }
        : { ...this.state.minimart },
      editClick: true,
    });
  };

  renderAssociation = () => {
    //NOSONAR
    let minimartAssociationDetails =
      this.props.isNewMinimart &&
        this.props.minimart.minimartDetails.registrationStatus !==
        CI_MINIMART_STATUS.APPROVED
        ? NEW_MINIMART_ASSOCIATION_DETAILS
        : MINIMART_ASSOCIATION_DETAILS;

    return (
      <>
        <Row className='underlineText pt-3'>
          {Strings("Association")}
        </Row>
        <Row>
          {minimartAssociationDetails.map((minimart) => {
            let list = null;
            let branch = null;
            if (this.state.editClick) {
              if (minimart.key === "branch") {
                list = this.state.branchList;
              } else if (minimart.key === "zone") {
                list = this.state.zones;
              } else if (minimart.key === "visitDay") {
                list = VISITED_DAYS;
              } else if (minimart.key === "messengerZone") {
                list = this.state.mZones;
              }
            }
            let isEdit =
              this.props.loggedInUser.role &&
              this.state.editClick &&
              (this.props.isNewMinimart
                ? NEW_MINIMART_DETAILS_EDIT_USERS[
                  this.props.loggedInUser.role
                ].includes(minimart.key)
                : MINIMART_DETAILS_EDIT_USERS[
                  this.props.loggedInUser.role
                ].includes(minimart.key)) &&
              minimart.isEdit;
            if (minimart.key === "branch") {
              branch = this.props.minimart.minimartDetails.branch
            }
            return (
              <Col
                md={4}
                className='px-0 d-flex align-items-center pb-2'
                key={minimart.key}
              >
                <span className='titleText text-break'>
                  {Strings(minimart.label)}
                </span>
                {isEdit && <span className='mandatoryFields'>*</span>}
                &nbsp;:&nbsp;
                {isEdit ? (
                  this.renderTextField({
                    label: minimart.label,
                    name: minimart.key,
                    type: minimart.type,
                    value: this.state.minimart[minimart.key],
                    isSelection: minimart.isSelection,
                    dropDownList: list,
                  })
                ) : (
                  <>
                    <span className='pe-0 detailText'>
                      {this.state.minimart[minimart.key]
                        ? minimart.label === "associatedBranch" ? branch : this.state.minimart[minimart.key]
                        : Strings("NA")}
                    </span>{" "}
                  </>
                )}
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  renderAssociationUser = () => {
    return (
      <Row>
        {ASSOCIATION_USERS.map((user) => {
          return (
            <Col
              md={4}
              className='px-0 d-flex align-items-center'
              key={user.key}
            >
              <span className='titleText'>{Strings(user.label)}</span>
              &nbsp;:&nbsp;
              <span className='colorText'>
                {user.key === "MESSENGER" &&
                  this.state.association.messengerType === SPECIAL
                  ? "\n" + SHORT_SPECIAL_MESSENGER_PRESENTATION + " "
                  : ""}
                {this.state.association[user.key]
                  ? this.state.association[user.key]
                  : Strings("NA")}
              </span>
            </Col>
          );
        })}
      </Row>
    );
  };

  onDaySelection = (dayObject) => {
    let date = Utility.daySelectionForSellerAssignment(dayObject.day)
    this.setState({ date });
  };

  renderConfirmationModal = () => {
    return (
      <UserModal
        open={this.state.confirmationModalInformation.show}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={this.state.confirmationModalInformation.type}
        modalText1={this.state.confirmationModalInformation.text}
        modalText2={this.state.confirmationModalInformation.text2}
        modalButtonOk={{
          text: Strings("Set"),
          onClick: this.handleYesClick,
        }}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.closeConfirmationModal,
        }}
        showHr={true}
        handleRadioChange={this.state.confirmationModalInformation.handleRadioChange}
        note={this.state.confirmationModalInformation.note}
        errorMessage={this.state.errorMessage}
        gradeObject={this.state.confirmationModalInformation.type === Strings("changeGrade")
          ? {
            ...this.state.gradeObject,
            currentGrade: this.props.minimart.minimartDetails.category,
          }
          : undefined}
      />
    );
  };

  errorCallback = (responseData) => {
    this.setState({
      errorMessage: responseData,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      confirmationModalInformation: {
        show: false,
        type: "",
        text: "",
        text2: "",
        note: "",
      },
      gradeObject: {
        ...this.state.gradeObject,
        gradeUpdate: "",
      },
      errorMessage: "",
    });
  };

  handleYesClick = (notesData) => {
    if (
      this.state.confirmationModalInformation.type ===
      Strings("changeGrade")
    ) {
      this.props.handleResetGrade(
        {
          gc: this.state.gradeObject.gradeUpdate || undefined,
          rg: this.state.gradeObject.resetGrade,
          mid: this.props.minimart.minimartDetails.id
        },
        this.closeConfirmationModal
      );
    } else if (
      this.state.confirmationModalInformation.type ===
      Strings("salesPersonAssignment")
    ) {
      this.props.assignSalesPerson(
        this.props.minimart.minimartDetails.id,
        this.state.date,
        this.closeConfirmationModal,
        this.errorCallback
      );
    } else if (
      this.state.confirmationModalInformation.type ===
      Strings("messengerAssignment")
    ) {
      this.props.assignMessenger(
        this.props.minimart.minimartDetails.id,
        this.closeConfirmationModal,
        this.errorCallback
      );
    } else if (
      this.state.confirmationModalInformation.type ===
      Strings("paymentReverse")
    ) {
      if (this.state.paymentType === "EMI_payment") {
        this.props.handlePaymentReverse(
          this.props.minimart.minimartDetails.id,
          this.closeConfirmationModal,
          this.errorCallback
        );
      }
      else if (this.state.paymentType === "DownPayment") {
        this.props.handleDownpaymentReverse(
          this.props.minimart.minimartDetails.id,
          notesData,
          this.closeConfirmationModal,
          this.errorCallback
        )
      }
    } else {
      this.props.setExceptionalMinimart(
        this.props.minimart.minimartDetails.id,
        this.closeConfirmationModal
      );
    }
  };

  onGradeChange = (gradeObject) => {
    this.setState({
      gradeObject: {
        ...this.state.gradeObject,
        gradeUpdate: gradeObject.gradeUpdate ? gradeObject.gradeUpdate : this.state.gradeObject.gradeUpdate,
        resetGrade: gradeObject.resetGrade ? gradeObject.resetGrade : this.state.gradeObject.resetGrade
      }
    })
  }
  onPaymentTypeChange = (paymentType) => {
    this.setState({
      paymentType: paymentType.revertPayment
    })
  }
  handleResetClick = () => {
    return this.setState({
      confirmationModalInformation: {
        type: Strings("changeGrade"),
        show: true,
        text: Strings("gradeUpdateConfirmation"),
        text2: Strings("gradeResetConfirmation"),
        note: this.props.minimart.minimartDetails.resetGrade
          ? Strings("systemWillUpdateNote")
          : Strings("systemWillNotUpdateNote"),
        handleRadioChange: this.onGradeChange,
      },
    });
  };

  handleAddInvoice = () => {
    !this.state.showModal
      ? this.props.getSupplierList((supplierList) => {
        this.setState({
          showModal: !this.state.showModal,
          invoice: {
            invoiceNumber: "",
            supplierName: supplierList ? supplierList[0] : "",
            expiryDate: DEFAULT_DURATION.toDate,
            amount: 0,
            balance: 0,
            type: INVOICE_TYPES[1].value,
            ciCode: this.props.minimart.minimartDetails.code,
            ciZone: "",
            visitDay: VISITED_DAYS[1].value,
          },
          supplierList: supplierList,
        });
      })
      : this.setState({
        showModal: !this.state.showModal,
      });
  };

  renderInvoiceTab = () => {
    let InvoiceFilterFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.InvoiceFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: INVOICE_STATUSES,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeInvoiceType,
        Value: this.state.InvoiceFilters.invoiceType,
        fieldname: SEARCH_FILTER_FIELDS.INVOICE_TYPE,
        filterDropdownList: INVOICE_TYPES,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.InvoiceFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.InvoiceFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <TabPanel>
        <Invoices
          InvoiceFilters={InvoiceFilterFilters}
          invoices={this.props.invoices}
          recordsPerPage={this.state.recordsPerPage}
          activePage={this.state.activePage}
          numberOfPages={this.props.numberOfPages}
          onPageChange={this.onPageChange}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.resetSearchValues}
          addInvoice={
            this.props.minimart.minimartDetails.registrationStatus === //NOSONAR
            CI_MINIMART_STATUS.APPROVED &&
            this.props.minimart.minimartDetails.status !==
            MINIMART_STATUS.BLOCKED &&
            this.state.minimart.code &&
            (this.props.loggedInUser.role ===
              USER_ROLES.operationsModerator ||
              this.props.loggedInUser.role ===
              USER_ROLES.operationsOfficer ||
              this.props.loggedInUser.role ===
              USER_ROLES.operationsManager)
          }
          handleAddInvoice={this.handleAddInvoice}
        />
      </TabPanel>
    );
  };
  renderLoanTab = () => {
    let LoanFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleChangeStatusFilter,
        Value: this.state.LoanFilters.status,
        fieldname: SEARCH_FILTER_FIELDS.STATUS,
        filterDropdownList: STATUS_FOR_LOANS,
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        disabled: this.state.disabled,
        handleOnChange: this.handleChangeCategoryFilter,
        Value: this.state.LoanFilters.category,
        fieldname: SEARCH_FILTER_FIELDS.CATEGORY,
        filterDropdownList: CATEGORY,
      },
      {
        type: SEARCH_FILTER_TYPE.DATE,
        handleOnChange: this.handleChangefromDate,
        fromDate: this.state.LoanFilters.fromDate,
        handleCalenderChange: this.handleDateChange,
        toDate: this.state.LoanFilters.toDate,
        fieldname: SEARCH_FILTER_FIELDS.DATE,
      },
    ];
    return (
      <TabPanel>
        <Loans
          loanFilters={LoanFilters}
          loans={this.props.loans}
          recordsPerPage={this.state.recordsPerPage}
          activePage={this.state.activePage}
          numberOfPages={this.props.numberOfPages}
          onPageChange={this.onPageChange}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.resetSearchValues}
          setLoanDetailsID={(loanDetails) => {
            this.props.setLoanDetailsID(
              loanDetails,
              this.state.activeTab
            );
          }}
        />
      </TabPanel>
    );
  };
  renderCreditLimitTab = () => {
    return (
      <TabPanel>
        <CreditLimit
          ticketId={this.state.ticketId}
          matricesId={this.props.matricesId}
          creditLimitData={this.props.creditRequestData}
          getPerticularCreditLimitRequest={
            this.props.getPerticularCreditLimitRequest
          }
          creditLimitRequest={this.props.creditLimitRequest}
          getCreditAgreementList={this.props.getCreditAgreementList}
          minimartCode={this.state.minimartCode}
          minimartId={this.state.minimartId}
          creditAgreementRecords={this.props.creditAgreementRecords}
          downloadCreditAgreement={this.props.downloadCreditAgreement}
          errorMessage={this.props.errorMessage}
        />
      </TabPanel>
    );
  };
  renderPaymentReceiptsTab = () => {
    return (
      <TabPanel>
        <PaymentReceipts
          getAllPaymentReceipts={this.props.getAllPaymentReceipts}
          paymentReceipts={this.props.paymentReceipts}
          paymentReceiptsPages={this.props.paymentReceiptsPages}
          paymentReceiptsPerPage={this.props.paymentReceiptsPerPage}
          minimartCode={this.state.minimartCode}
          downloadPaymentReceipt={this.props.downloadPaymentReceipt}
          downloadDownpaymentReceipt={
            this.props.downloadDownpaymentReceipt
          }
          matricesId={this.state.matricesId}
        />
      </TabPanel>
    );
  };

  renderPaymentCollectionTab = () => {
    return (
      <TabPanel>
        <PaymentCollectionContainer
          errorMessage={this.props.errorMessage}
          minimartId={this.state.matricesId}
          downloadPaymentReceipt={this.props.downloadPaymentReceipt}
          downloadDownpaymentReceipt={this.props.downloadDownpaymentReceipt}
          loadSpinner={this.props.loadSpinner}
          unloadSpinner={this.props.unloadSpinner}
          getSingleMinimartData={this.props.getSingleMinimartData}
        />
      </TabPanel>
    );
  };

  // renderRewardHistoryTab = () => {
  //   return (
  //     <TabPanel>
  //       <RewardHistory
  //         handleRewardHistorySearchFilter={
  //           this.props.handleRewardHistorySearchFilter
  //         }
  //         rewardHistory={this.props.rewardHistory}
  //         numberOfPages={this.props.numberOfPages}
  //         mid={this.props.singleMiniMart?.id}
  //         errorMessage={this.state.errorMessage}
  //       />
  //     </TabPanel>
  //   );
  // };

  // renderPromotionTab = () => {
  //   return (
  //     <TabPanel>
  //       <PromotionsApprovalConatiner
  //         loggedInUser={this.props.loggedInUser}
  //         minimartDetails={this.props.minimart.minimartDetails}
  //       />
  //     </TabPanel>
  //   );
  // };

  renderTabs = () => {
    let tabList = [...this.state.Tabs];
    this.props.isNewMinimart && tabList.splice(1);
    return (
      <>
        <Row>
          <div className='subHeading pb-2 ps-0'>
            {Strings("PortFolio")}
          </div>
        </Row>
        <Tabs
          selectedIndex={this.state.activeTab.key}
          onSelect={this.handleTabs}
        >
          <TabList>
            {tabList.map((item) => (
              <Tab
                key={item.key}>
                {item.value}
              </Tab>
            ))}
          </TabList>
          <div className='DetailsListContainer'>
            {tabList.length > 0 && (
              <>
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("Invoices")
                ) && this.renderInvoiceTab()}
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("Loans")
                ) && this.renderLoanTab()}
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("creditLimit")
                ) && this.renderCreditLimitTab()}
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("PaymentReceipts")
                ) && this.renderPaymentReceiptsTab()}
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("collectPayment")
                ) && this.renderPaymentCollectionTab()}
                {/* {Object.values(tabList).find(
                  (tab) => tab.value === Strings("rewardHistory")
                ) && this.renderRewardHistoryTab()}
                {Object.values(tabList).find(
                  (tab) => tab.value === Strings("Promotions")
                ) && this.renderPromotionTab()} */}
              </>
            )}
            <div className='pull-left pt-4 pb-1'>
              <Button
                className='buttonBorder'
                onClick={this.props.goBack}
              >
                {Strings("Back").toUpperCase()}
              </Button>
            </div>
          </div>
        </Tabs>
      </>
    );
  };

  renderMinimartNotes = () => {
    return (
      <>
        <hr className="opacity-100" />
        <div className='outerBorder p-3 my-2 notesHeader'>
          <div className='my-2'>
            {Strings("minimartRejectionNote")}
          </div>
          <div className='notes'>
            <div
              key={this.props.minimart.minimartDetails.note.id}
              className='innerBox p-3 my-2'
            >
              <span className='author'>
                {Strings("author")}:&nbsp;
                {this.props.minimart.minimartDetails.note.author}
              </span>
              <span className='date ps-3'>
                {moment(
                  this.props.minimart.minimartDetails.note.updatedAt
                ).format(CONFIGURATION_DATE_TIME_FORMAT)}
              </span>

              <div className='noteColor'>
                {this.props.minimart.minimartDetails.note.note}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderMinimartStatusChangeNotes = () => {
    return this.props.minimartNotes &&
      this.props.minimartNotes?.length > 0 && (
        <div className='outerBorder p-3 my-2 notesHeader'>
          <div className='my-2'>
            {Strings("Notes")}
          </div>
          <div className='notes'>
            {this.props?.minimartNotes.map((note) => {
              return (
                <div key={note.id} className='innerBox p-3 my-2'>
                  <span className='author'>
                    {Strings("author")}:&nbsp;{note.author}
                  </span>
                  <span className='date ps-3'>
                    {moment(note.ca).format(
                      CONFIGURATION_DATE_TIME_FORMAT
                    )}
                  </span>

                  <div className='noteColor'>{note.note}</div>
                </div>
              );
            })}
          </div>
        </div>
      )
  }

  renderBlockedMinimarts = () => {
    return (
      <>
        <hr className="opacity-100" />
        {(ACCESS_TO_BLOCK_MINIMARTS.includes(this.props.loggedInUser.role)) &&
          this.props.minimart.minimartDetails.status !==
          MINIMART_STATUS.BARRED && (
            <div className='blockHeader'>
              {this.props.minimart.minimartDetails.status ===
                MINIMART_STATUS.BLOCKED
                ? Strings("askToUnlockTheMinimart")
                : Strings("askToBlockTheMinimart")}
              <Button
                className='blockButton ms-2'
                onClick={() => { this.toggleNotesModal(false) }}
              >
                {this.props.minimart.minimartDetails.status ===
                  MINIMART_STATUS.BLOCKED
                  ? Strings("UNBLOCK").toUpperCase()
                  : Strings("BLOCK").toUpperCase()}
              </Button>
            </div>
          )}

        {this.renderMinimartStatusChangeNotes()}
      </>
    );
  };

  renderNotesModal = () => {
    return (
      <NotesModal
        open={this.state.notesModal}
        modalHeader={Strings("Notes")}
        toggleModal={this.toggleModal}
        noteType={this.state.noteType}
        addNote={this.state.addNote ? this.addNote : this.changeMinimartStatus}
        isNotesCompulsory={this.state.addNote ? true : this.props.minimart.minimartDetails.status !== MINIMART_STATUS.BLOCKED
        }
        maxLength={500}
      />
    );
  };

  toggleNotesModal = (isAddNote = false) => {
    if (isAddNote) {
      this.setState({
        notesModal: true,
        addNote: true,
      });
    }
    else {
      this.setState({
        notesModal: true,
      });
    }
  };

  assignSalesPerson = () => {
    return this.setState({
      confirmationModalInformation: {
        type: Strings("salesPersonAssignment"),
        show: true,
        text: Strings("assignSalesPerson"),
        note: Strings("visitNote"),
        handleRadioChange: this.onDaySelection,
      },
    });
  };

  assignMessenger = () => {
    return this.setState({
      confirmationModalInformation: {
        type: Strings("messengerAssignment"),
        show: true,
        text: Strings("assignMessenger"),
        note: "",
      },
    });
  };

  getAssignedMinimartUserList = (requestData) => {
    this.props.getAssignedMinimartUserList(
      requestData,
      (data) => {
        this.setState({
          association: {
            ...this.state.association,
            salesPerson: data.sp,
            messenger: data.ms,
          },
        });
      },
      (errorMessage) => {
        this.setState({ errorMessage: errorMessage || "" });
      }
    );
  };

  handleModalInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: "",
    });
  };

  isValidApproveData = () => {
    let isZonesAvailable = this.state.minimart.zone &&
      this.state.minimart.messengerZone;
    let minimartDetails = this.state.minimart.city &&
      this.state.minimart.ownerName?.trim().length !== 0 &&
      this.state.minimart.sector?.trim().length !== 0 &&
      this.state.minimart.street?.trim().length !== 0 &&
      this.state.minimart.number?.trim().length !== 0 &&
      this.state.minimart.reference.trim().length !== 0 &&
      this.state.minimart.visitDay &&
      this.state.minimart.city.trim().length !== 0 &&
      this.state.minimart.telephone &&
      this.state.minimart.telephone.length === 10 &&
      this.state.minimart.ownerNumber &&
      this.state.minimart.ownerNumber.length === 10 &&
      Utility.checkIfValidLatitude(this.state.minimart.latitude) &&
      Utility.checkIfValidLongitude(this.state.minimart.longitude)
    if (
      minimartDetails && isZonesAvailable
    ) {
      if (this.props.isNewMinimart) {
        if (
          NEW_MINIMART_EDIT_ACCESS.includes(
            this.props.loggedInUser.role
          )
        ) {
          return (
            this.state.minimart.branch &&
            this.state.minimart.zone !== null &&
            this.state.minimart.visitDay !== null &&
            this.state.minimart.messengerZone !== null
          );
        }
        return true;
      }
      return (
        this.state.minimart.branch &&
        this.state.minimart.zone !== null &&
        this.state.minimart.visitDay !== null &&
        this.state.minimart.messengerZone !== null &&
        this.state.minimart.eligibleLoanType
      );
    } else if (NEW_MINIMART_EDIT_ACCESS.includes(
      this.props.loggedInUser.role
    )) {
      return false;
    }
    else if (minimartDetails && !isZonesAvailable) {

      return true;
    }
    return false
  };

  editYesClick = () => {
    if (this.state.isMergeMinimart) {
      this.props.handleMergeMinimart(
        {
          dm: this.props.mergeMinimart.id,
          om: this.props.minimart.minimartDetails.id,
        },
        this.closeModal,
        (errorMessage) => {
          this.setState({ errorMessage });
        }
      );
    } else {
      if (this.isValidApproveData()) {
        this.props.sendUpdatedMinimartDetails(
          {
            mn: this.state.minimart.name,
            on: this.state.minimart.ownerName,
            mid: this.state.minimart.id,
            tp: this.state.minimart.telephone,
            otp: this.state.minimart.ownerNumber,
            bty: this.state.minimart.businessType,
            ct: this.state.minimart.city,
            scr: this.state.minimart.sector,
            srt: this.state.minimart.street,
            no: this.state.minimart.number,
            ref: this.state.minimart.reference,
            br: parseInt(this.state.minimart.branch),
            zn: this.state.minimart.zone,
            mzn: this.state.minimart.messengerZone,
            vd: this.state.minimart.visitDay,
            lvl: this.state.minimart.eligibleLoanType,
            lat: this.state.minimart.latitude,
            long: this.state.minimart.longitude,
          },
          this.handleCancelClick
        );
      }
    }
  };

  closeModal = () => {
    this.state.isMergeMinimart && this.props.clearMergeMinimart();
    this.setState({
      openModal: false,
      errorMessage: "",
      isMergeMinimart: false,
      megreMatricesId: "",
    });
  };

  renderMinimartDetailsPopup = () => {
    return (
      <MinimartDetailsPopup
        openModal={this.state.openModal}
        closeModal={this.closeModal}
        handleModalInputChange={this.handleModalInputChange}
        handleRouteChange={this.editYesClick}
        actionBtnDisabled={
          !(
            this.props.loggedInUser.role ===
            USER_ROLES.generalManager ||
            this.props.loggedInUser.role === USER_ROLES.admin ||
            this.props.loggedInUser.role ===
            USER_ROLES.operationsModerator ||
            this.props.loggedInUser.role ===
            USER_ROLES.operationsManager
          )
        }
        errorForPopup={this.state.errorMessage}
        isMergeMinimart={this.state.isMergeMinimart}
        megreMatricesId={this.state.megreMatricesId}
        yesBtnText={this.state.isMergeMinimart ? "merge" : "Approve"}
        cancelBtnText={"No"}
        mergeMinimart={this.props.mergeMinimart}
        modalHeaderText={
          this.state.isMergeMinimart
            ? "mergeMinimart"
            : "minimartDetailsText"
        }
        getMergeMinimartData={this.props.getMergeMinimartData}
        matricesId={this.props.minimart.minimartDetails.id}
        oldMinimartCollectionDays={this.props.minimart.minimartDetails.collectionDays}
      />
    );
  };

  renderSalesAssignment = () => {
    return (
      <Col md={2.5} className="ps-3 pe-3  w-auto">
        <Button
          className='addOrUpdateUserTopButtons alignText'
          onClick={this.assignSalesPerson}
          disabled={
            (this.props.minimart.minimartDetails.status !==
              MINIMART_STATUS.ACTIVE && this.props.minimart.minimartDetails.iff === true) ||
            this.props.minimart.minimartDetails.registrationStatus !==
            APPROVED_MINIMART_ACTION.APPROVED ||
            !this.state.association.salesPerson
          }
        >
          {Strings("salesPersonAssignment")}
        </Button>
      </Col>
    );
  };

  renderMergedMinimarts = () => {
    return (
      <Col md={2} className=" w-auto">
        <Button
          className='buttonBorder alignText w-100'
          onClick={this.props.onMergedMinimartClick}
        >
          {Strings("mergedHistory")}
        </Button>
      </Col>
    )
  }
  renderNotes = () => {
    return (
      <Col md={2} className="ms-auto">
        <Button
          className='buttonBorder alignText w-100'
          onClick={this.props.onNoteDetailsClick}
        >
          {Strings("Ver notas")}
        </Button>
      </Col>
    )
  }

  renderColumnsForGeoLocation = (data) => {
    return data.map((location) => {
      let isEdit = false;
      if (this.state.editClick
        && MINIMART_DETAILS_EDIT_USERS[this.props.loggedInUser.role]?.includes(location) && this.props.minimart.minimartDetails.status !==
        MINIMART_STATUS.BLOCKED) {
        isEdit = true;
      }

      return (
        <Col
          md={4}
          className='px-0 d-flex align-items-center pb-1'
          key={location}
        >
          <span className='titleText text-break'>
            {Strings(location)}
          </span>
          {isEdit && <span className='mandatoryFields'>*</span>}
          &nbsp;:&nbsp;
          {isEdit ? (
            this.renderTextField({
              label: location,
              name: location,
              type: "text",
              value: this.state.minimart[location],
              isFloat: true,
            })
          ) : (
            <>
              <span className='pe-0 detailText'>
                {this.state.minimart[location]
                  ? this.state.minimart[location]
                  : Strings("NA")}
              </span>{" "}
            </>
          )}
          {isEdit
            && (location === "latitude"
              || location === "longitude")
            && <i className="fa fa-info-circle px-2"
              aria-hidden="true"
              title={Strings(`${location}ValidRange`)}
            />}
        </Col>
      );
    })
  }

  renderGeoLocation = () => {
    let geoLocation = ["latitude", "longitude"];
    let metaGeoLoacation = ["metaLatitude", "metaLongitude"];
    return (
      <>
        <Row className='underlineText pt-3 pb-2'>
          {Strings("GeoLocation")}
        </Row>
        <Row>
          {this.renderColumnsForGeoLocation(geoLocation)}
        </Row>
        <Row>
          {this.renderColumnsForGeoLocation(metaGeoLoacation)}
        </Row>
      </>
    )
  }

  renderMinimartInfo = () => {
    return (
      <Collapse in={this.state.isCollapseView}>
        <Row className='primaryDetails'>
          <Col md={1} className='p-0'>
            <div className='imageplaceholder justify-center display-flex w-100'>
              <img
                src={
                  this.props.minimart.minimartDetails
                    .miniMartImage === "defaultImage" ||
                    this.props.minimart.minimartDetails
                      .miniMartImage === undefined
                    ? Defaultminimart
                    : this.props.minimart.minimartDetails
                      .miniMartImage
                }
                alt='minimart'
              />
            </div>

            {!this.props.isNewMinimart && (
              <div className='gradeText pt-2 justify-center display-flex'>
                <span className={"me-1"}>
                  {Strings("Grade")}{" "}
                  {this.props.minimart.minimartDetails.category}
                </span>

                {(this.props.loggedInUser.role ===
                  USER_ROLES.generalManager ||
                  this.props.loggedInUser.role ===
                  USER_ROLES.admin || this.props.loggedInUser.role ===
                  USER_ROLES.operationsManager) &&
                  this.props.minimart.minimartDetails
                    .registrationStatus !==
                  APPROVED_MINIMART_ACTION.REJECTED && (
                    <span
                      title={Strings("resetGrade")}
                      className='cursorPointer'
                    >
                      <img
                        src={editNote}
                        className={"editIcon m-0"}
                        title={Strings("changeGrade")}
                        alt={Strings("changeGrade")}
                        onClick={this.handleResetClick}
                      />
                    </span>
                  )}
              </div>
            )}
            {this.props.minimart.minimartDetails
              .registrationStatus !==
              APPROVED_MINIMART_ACTION.REJECTED && (
                <>
                  {!this.props.isNewMinimart && (
                    <div
                      className='gradeText justify-center display-flex'
                      title={Strings("MinimartStatus")}
                    >
                      <span title={Strings("MinimartStatus")}>
                        {Strings(
                          this.props.minimart.minimartDetails.status
                        )}
                      </span>
                    </div>
                  )}
                  <div
                    className='statusText justify-center display-flex'
                    title={Strings("MinimartType")}
                  >
                    <span title={Strings("MinimartType")}>
                      {Strings(
                        this.props.minimart.minimartDetails.type
                      )}
                    </span>
                  </div>
                  <div
                    className='statusText text-center mt-2 pe-2'
                    title={Strings("gradeResetConfirmation")}
                  >
                    <span>
                      {this.props.minimart.minimartDetails.resetGrade
                        ? Strings("gradeResetActive")
                        : Strings("gradeResetInactive")}
                    </span>
                  </div>
                  {ADD_FOLLOW_UP_NOTES_ACCESS.includes(this.props.loggedInUser.role) &&
                    <div
                      className='statusText text-center mt-2 pe-2'
                      title={Strings("Notes")}
                    >
                      <img
                        src={addNotes}
                        alt={"addNotes"}
                        className="addNotesIcon cursorPointer"
                        onClick={() => { this.toggleNotesModal(true) }}
                        style={{ marginBottom: 5 }}
                      />
                      <span style={{ marginLeft: 10 }}>{Strings("Notes")}</span>
                    </div>
                  }
                </>
              )}
          </Col>
          <Col
            md={
              this.props.loggedInUser.role ===
                USER_ROLES.salesModerator ||
                this.props.loggedInUser.role === USER_ROLES.admin ||
                this.props.loggedInUser.role ===
                USER_ROLES.generalManager
                ? 10
                : 11
            }
            className={
              this.props.loggedInUser.role ===
                USER_ROLES.salesModerator ||
                this.props.loggedInUser.role === USER_ROLES.admin ||
                this.props.loggedInUser.role ===
                USER_ROLES.generalManager
                ? "pe-0 ps-1"
                : ""
            }
          >
            {this.renderAppDataNotFilledNote()}
            {this.renderMinimartDetails()}
            {!this.props.isNewMinimart && this.renderCreditLimit()}
            {this.renderGeoLocation()}
            {this.renderAssociation()}
            {this.renderAssociationUser()}
          </Col>
          {this.state.editClick && (
            <>
              <Col md={{ offset: 8, span: 2 }} className='py-2'>
                <Button
                  className='buttonBackground primaryModalButton w-100'
                  onClick={this.editYesClick}
                  disabled={!this.isValidApproveData()}
                >
                  {Strings("Yes")}
                </Button>
              </Col>
              <Col md={2} className='py-2'>
                <Button
                  className='primaryModalButton buttonBorder  w-100'
                  onClick={this.handleCancelClick}
                >
                  {Strings("Cancel")}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Collapse>
    );
  };

  renderCollapseView = () => {
    return (
      <Row className='collapseContainer'>
        <img
          src={
            this.state.isCollapseView
              ? pageDividerOpened
              : pageDividerClosed
          }
          alt='img'
          className='collapseIcon pull-right p-0'
          onClick={this.toggleCollpasibleView}
        />
        <hr className='collapseHr opacity-100' />
      </Row>
    );
  };

  toggleCollpasibleView = () => {
    this.setState({
      isCollapseView: !this.state.isCollapseView,
    });
  };

  handlePaymentReverse = () => {
    return this.setState({
      confirmationModalInformation: {
        type: Strings("paymentReverse"),
        show: true,
        text: Strings("paymentReverseWarning"),
        note: "",
        handleRadioChange: this.onPaymentTypeChange,
      },
    });
  };

  setExceptionalMinimart = () => {
    return this.setState({
      confirmationModalInformation: {
        type: this.props.minimart.minimartDetails.iex
          ? Strings("markNormal")
          : Strings("markExceptional"),
        show: true,
        text: this.props.minimart.minimartDetails.iex
          ? Strings("markNormalWarn")
          : Strings("markExceptionalWarn"),
        note: "",
      },
    });
  };


  renderUnbarButton = () => {
    return (
      <Col md={2} className="w-auto">
        <Button
          className='addOrUpdateUserTopButtons w-100'
          onClick={() => { this.toggleNotesModal(false) }}
        >
          {Strings("unBarMinimart")}
        </Button>
      </Col>
    )
  }

  renderActionButtons = () => {
    return (
      <Row className="w-100">
        <Col md={12}>
          <Row>
            {(this.props.loggedInUser.role ===
              USER_ROLES.salesModerator ||
              this.props.loggedInUser.role === USER_ROLES.admin ||
              this.props.loggedInUser.role ===
              USER_ROLES.generalManager ||
              this.props.loggedInUser.role ===
              USER_ROLES.salesManager) &&
              this.renderSalesAssignment()}
            {(this.props.loggedInUser.role === USER_ROLES.generalManager
              || this.props.loggedInUser.role === USER_ROLES.operationsManager)
              && this.renderMergeMininartButton()}
            {
              !this.props.isNewMinimart &&
              (this.props.loggedInUser.role ===
                USER_ROLES.collectionsSupervisor ||
                this.props.loggedInUser.role ===
                USER_ROLES.accountingOfficer ||
                this.props.loggedInUser.role ===
                USER_ROLES.collectionsManager ||
                this.props.loggedInUser.role ===
                USER_ROLES.administrativeManager) && (
                <Col md={2} className="w-auto">
                  <Button
                    className='addOrUpdateUserTopButtons w-100'
                    onClick={this.handlePaymentReverse}
                  >
                    {Strings("paymentReverse")}
                  </Button>
                </Col>)}
            {!this.props.isNewMinimart &&
              (this.props.loggedInUser.role === USER_ROLES.collectionsManager
                || this.props.loggedInUser.role === USER_ROLES.collectionsSupervisor
                || this.props.loggedInUser.role === USER_ROLES.administrativeManager
                || this.props.loggedInUser.role === USER_ROLES.technology)
              && (
                <Col md={3} className="w-auto">
                  <Button
                    className='addOrUpdateUserTopButtons w-100'
                    onClick={this.assignMessenger}
                  >
                    {Strings("messengerAssignment")}
                  </Button>
                </Col>)}
            {!this.props.isNewMinimart &&
              EXCEPTIONAL_MINIMART_USER_ACCESS.includes(
                this.props.loggedInUser.role
              ) && (
                <Col md={2} className="ps-3 pe-3 w-auto">
                  <Button
                    className='addOrUpdateUserTopButtons w-100'
                    onClick={this.setExceptionalMinimart}
                    disabled={
                      this.props.minimart.minimartDetails.status ===
                      MINIMART_STATUS.BLOCKED
                    }
                  >
                    {Strings(
                      this.props.minimart.minimartDetails.iex
                        ? "markNormal"
                        : "markExceptional"
                    )}
                  </Button>
                </Col>
              )}
            {(this.props.loggedInUser.role === USER_ROLES.admin || this.props.loggedInUser.role === USER_ROLES.generalManager)
              && this.props.minimart.minimartDetails.status ===
              MINIMART_STATUS.BARRED && this.renderUnbarButton()}
            {this.renderNotes()}
            {this.renderMergedMinimarts()}
          </Row>
        </Col>



      </Row>
    );
  };

  onMergeMinimartClicked = () => {
    this.setState({ openModal: true, isMergeMinimart: true });
  };

  renderMergeMininartButton = () => {
    let isMergeMinimartButtonDisabled =
      this.props.minimart.minimartDetails.status !== MINIMART_STATUS.ACTIVE
      ||
      this.props.minimart.minimartDetails.registrationStatus !== APPROVED_MINIMART_ACTION.APPROVED
      ||
      this.state.association.messenger === null
      ||
      this.props.minimart?.minimartDetails?.iff !== true
    return (
      <Col md={2} className="w-auto">
        <Button
          className='addOrUpdateUserTopButtons alignText w-100'
          onClick={this.onMergeMinimartClicked}
          disabled={
            isMergeMinimartButtonDisabled
          }
          title={isMergeMinimartButtonDisabled ? Strings("DISABLED_MERGE_MINIMART_BUTTON") : null}
        >
          {Strings("mergeMinimart")}
        </Button>
      </Col>
    );
  };

  validInvoiceData = () => {
    if (
      this.state.invoice.invoiceNumber !== "" &&
      this.state.invoice.amount &&
      this.state.invoice.balance &&
      this.state.invoice.ciZone !== ""
    ) {
      if (
        parseInt(this.state.invoice.balance) >
        parseInt(this.state.invoice.amount)
      ) {
        this.setState({
          errorMessage: Strings("INVOICE_BALANCE_ERROR"),
        });
      } else return true;
    } else {
      this.setState({
        errorMessage: Strings("pleaseEnterRequiredFields"),
      });
    }
    return false;
  };

  handleAddInvoiceYesClick = () => {
    this.validInvoiceData() &&
      this.props.addInvoice(
        {
          ino: this.state.invoice.invoiceNumber,
          mc: this.props.minimart.minimartDetails.code,
          amt: this.state.invoice.amount,
          bal: this.state.invoice.balance,
          ed: Utility.convertDateIntoMiliSeconds(
            this.state.invoice.expiryDate
          ),
          it: this.state.invoice.type,
          vd: this.state.invoice.visitDay,
          cz: this.state.invoice.ciZone,
          sn: this.state.invoice.supplierName,
        },
        this.handleAddInvoice
      );
  };

  handleAddInvoiceInputChange = (event) => {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [event.target.name]: event.target.value,
      },
      errorMessage: "",
    });
  };

  handleInvoceDateChange = (value) => {
    this.setState({
      invoice: {
        ...this.state.invoice,
        expiryDate: value.format(DEFAULT_DATE_FORMAT),
      },
      errorMessage: "",
    });
  };

  renderAddInvoice = () => {
    let invoiceTypeList = [...INVOICE_TYPES];
    invoiceTypeList.shift();
    let dayOfVisitList = [...VISITED_DAYS];
    dayOfVisitList.shift();
    let editDetails = [
      {
        title: Strings("InvoiceNumber"),
        value: this.state.invoice.invoiceNumber,
        isMandatoryField: true,
        name: "invoiceNumber",
        type: "number",
      },
      {
        title: Strings("SupplierName"),
        value: this.state.invoice.supplierName,
        isMandatoryField: true,
        name: "supplierName",
        dropDownList: this.state.supplierList,
        type: "select",
        isSelection: true,
      },
      {
        title: Strings("ExpiryDate"),
        value: moment(this.state.invoice.expiryDate).format(
          INVOICE_DATE_FORMAT
        ),
        isMandatoryField: true,
        name: "expiryDate",
        isDate: "date",
        handleDateChange: this.handleInvoceDateChange,
        isBefore: true
      },
      {
        title: Strings("Amount"),
        value: this.state.invoice.amount,
        isMandatoryField: true,
        name: "amount",
        type: "number",
        thousandSeparator: true,
      },
      {
        title: Strings("balance"),
        value: this.state.invoice.balance,
        isMandatoryField: true,
        name: "balance",
        type: "number",
        thousandSeparator: true,
      },
      {
        title: Strings("InvoiceType"),
        value: this.state.invoice.type,
        isMandatoryField: true,
        name: "type",
        dropDownList: invoiceTypeList,
        type: "select",
        isSelection: true,
      },
      {
        title: Strings("code"),
        value: this.state.invoice.ciCode,
        isMandatoryField: true,
        name: "ciCode",
        type: "text",
        disabled: true,
      },
      {
        title: Strings("CIZone"),
        value: this.state.invoice.ciZone,
        isMandatoryField: true,
        name: "ciZone",
        type: "text",
      },
      {
        title: Strings("visitDay"),
        value: this.state.invoice.visitDay,
        isMandatoryField: true,
        name: "visitDay",
        dropDownList: dayOfVisitList,
        type: "select",
        isSelection: true,
      },
    ];

    return (
      <EditableModal
        open={this.state.showModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("AddInvoice")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.handleAddInvoice,
        }}
        modalButtonOk={{
          text: Strings("Submit"),
          onClick: this.handleAddInvoiceYesClick,
        }}
        onChange={this.handleAddInvoiceInputChange}
        SubmitMinimartDetails={this.handleAddInvoiceYesClick}
        errorMessage={this.state.errorMessage}
        editableData={editDetails}
      />
    );
  };
  renderAppDataNotFilledNote = () => {
    if (this.props.minimart?.minimartDetails?.iff === false) {
      return (
        <Row className=''>
          <span className='warningText pb-2 p-0'>
            {Strings("AppDataIsNotFilled")}
          </span>
        </Row>
      );
    }
    return null;
  }
  render() {
    return (
      <div className='minimartDetails container-fluid ListContainer'>
        {this.state.notesModal && this.renderNotesModal()}
        {this.state.showModal && this.renderAddInvoice()}
        {this.state.confirmationModalInformation.show &&
          this.renderConfirmationModal()}
        {this.state.openModal && this.renderMinimartDetailsPopup()}
        {this.renderMinimartInfo()}

        {this.renderCollapseView()}

        {this.renderActionButtons()}

        {this.props.minimart.minimartDetails.registrationStatus &&
          this.props.minimart.minimartDetails.registrationStatus ===
          APPROVED_MINIMART_ACTION.REJECTED &&
          this.props.minimart.minimartDetails.note &&
          this.renderMinimartNotes()}

        {this.props.minimart.minimartDetails.registrationStatus !==
          APPROVED_MINIMART_ACTION.REJECTED &&
          this.renderBlockedMinimarts()}
        {this.props.loggedInUser.role ===
          USER_ROLES.collectionsSupervisor && <hr className="opacity-100" />}
        {this.renderTabs()}
      </div>
    );
  }
}

export default MinimartDetails;
