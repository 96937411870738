import React from "react";
import NoteDetails from "./NoteDetails";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OPERATION_STATUS } from "../../config/axios.init";
import withHOC from "../../HOC/HOC";
import { getNotesAction, getNoteTypesAction,getUserRolesAction } from "./NoteDetailsAction";
import Utility from "../../utils/Utility";
import { DEFAULT_PAGINATION_ACTIVE_PAGE, DEFAULT_PAGINATION_RECORDS_COUNT } from "../CustomUIComponents/Pagination/PaginationConstants";
import LoadingModal from "../CustomUIComponents/LoadingModal/LoadingModal";
class NoteDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: {},
            showLoader: false,
            userRoles: [],
            noteTypes: ["noteType"],
            refId: props.location.state.refId,
            noteTypeContext: props.location.state.noteTypeContext,
            numberOfPages: DEFAULT_PAGINATION_RECORDS_COUNT,
            errorMessage: "",
            rows: [],
        };
    }

    componentDidMount() {
        this.getUserRoles();
        this.getNoteTypes(this.state.noteTypeContext);
    }
    handleNoteSearchFilter = (searchFilterObject) => {
        this.setState({
            showLoader: true,
            searchFilter: {
                rl: searchFilterObject.notesSearchCriteria.refId,
                ntc: searchFilterObject.notesSearchCriteria.noteTypeContext,
                ty: searchFilterObject.notesSearchCriteria.noteType,
                ur: searchFilterObject.notesSearchCriteria.userRole,
                fromDate: searchFilterObject.notesSearchCriteria.fromDate,
                toDate: searchFilterObject.notesSearchCriteria.toDate,
                offset: searchFilterObject.offset,
                limit: searchFilterObject.limit
            }
        })
        let filteredsearchFilterObject = {
            notesSearchCriteria: {
                refId: searchFilterObject.notesSearchCriteria.refId,
                noteTypeContext: searchFilterObject.notesSearchCriteria.noteTypeContext,
                noteType: searchFilterObject.notesSearchCriteria.noteType,
                userRole: searchFilterObject.notesSearchCriteria.userRole,
                fromDate: Utility.convertDateIntoMiliSeconds(searchFilterObject.notesSearchCriteria.fromDate) ,
                toDate: Utility.convertDateIntoMiliSeconds(searchFilterObject.notesSearchCriteria.toDate)
            },
            offset: searchFilterObject.offset ? searchFilterObject.offset - 1 : DEFAULT_PAGINATION_ACTIVE_PAGE - 1,
            limit: searchFilterObject.limit
        }
        getNotesAction(filteredsearchFilterObject, (responsedata) => {
            if (responsedata.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    numberOfPages: responsedata.data.data.numberOfPages,
                    showLoader: false,
                    errorMessage: "",
                    rows: responsedata.data.data.list
                });
            } else {
                this.setState({
                    errorMessage: responsedata.error.message,
                    showLoader: false,
                });

            }
        });

    }

    getUserRoles = (isAddUser = false) => {
        this.setState({ showLoader: true });
        getUserRolesAction(isAddUser, (response) => {
            this.setState({ showLoader: false });
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    userRoles: response.data.data.roles,
                });
            }
        });
    };
    getNoteTypes = (noteTypeContext) => {
        this.setState({ showLoader: true });
        getNoteTypesAction(noteTypeContext, (response) => {
            if (response.status === OPERATION_STATUS.SUCCESS) {
                this.setState({
                    showLoader: false,
                    noteTypes: [...this.state.noteTypes, ...response.data.data],
                })
            }
        })
    }
    render() {
        return (
            <div>
                <LoadingModal showLoadingModal={this.state.showLoader} />
                <NoteDetails
                    userRoles={this.state.userRoles}
                    searchFilter={this.props.searchFilter}
                    noteTypes={this.state.noteTypes}
                    handlNoteSearchFilter={this.handleNoteSearchFilter}
                    refId={this.state.refId}
                    noteTypeContext={this.state.noteTypeContext}
                    rows={this.state.rows}
                    numberOfPages={this.state.numberOfPages}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => (
    {
    searchFilter: state.searchFilterReducer.notesSearchFilter,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUserRolesAction,
    }, dispatch);
}

const NotesDetailsWrapper = withHOC(NoteDetailsContainer);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotesDetailsWrapper);